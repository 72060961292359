import React, { Component } from 'react';
import { connect } from 'react-redux';

import ResetPassword from './ResetPassword';
import LoginForm from './LoginForm';

class LoginModal extends Component {
    render() {

        const { forgotPassword } = this.props.user;

        return (
            
            forgotPassword ? <ResetPassword /> : <LoginForm />
            
        )
    }
}

const mapStateToProps = state => {
    return {
        user: state.user,
        commonFunctions: state.commonFunctions,       
    }
}

export default connect(mapStateToProps)(LoginModal);