import React, { Component } from "react"
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import { Provider } from "react-redux"

import store from "../store/Store"
import HomePage from "./pages/homepage/HomePage"
import Footer from "./pages/footer/Footer"
import CoursesPage from "./pages/courses/CoursesPage"
import QuestionPapers from "./pages/question-papers/QuestionPapers"
import Dashboard from "./pages/dashboard/Dashboard"
import Navbar from "./pages/navbar/Navbar"
import Messages from "./pages/messages/Messages"
import Conversation from "./pages/messages/Conversation"
import Compose from "./pages/messages/Compose"
import PersonalDetails from "./pages/application/personal-details/PersonalDetails"
import BasicDetails from "./pages/application/basic-details/BasicDetails"
import EduDetails from "./pages/application/edu-details/EduDetails"
import Preview from "./pages/application/preview/Preview"
import Declaration from "./pages/application/declaration/Declaration"
import AllNotices from "./pages/notices/AllNotices"
import Syllabus from "./pages/syllabus/Syllabus"
import Logout from "./pages/Logout"

import "../styles/tailwind.generated.css"
import "../styles/custom.css"
import Payment from "./pages/application/payment/Payment"
import PaymentSuccess from "./pages/application/payment/PaymentSuccess"
import PrintPreview from "./pages/application/preview/PrintPreview"
import PaymentReceipt from "./pages/application/admit-receipt/PaymentReceipt"
import AdmitCard from "./pages/application/admit-receipt/AdmitCard"
import MeritDocs from "./pages/application/uploads/MeritDocs"
import AdmissionPayment from "./pages/application/payment/AdmissionPayment"
import AdmissionReceipt from "./pages/application/admit-receipt/AdmissionReceipt"
import ContactUs from "./pages/contact-us/ContactUs"
import UploadDocs from "./pages/application/uploads/UploadDocs"
import PaymentFailed from "./pages/application/payment/PaymentFailed"
import CmsPage from "./pages/cms-page/CmsPage"

class App extends Component {
    render() {
        return (
            <Provider store={store}>
                <Router>
                    <Navbar />
                    <Switch>
                        <Route exact path="/">
                            <HomePage />
                        </Route>
                        <Route path="/course-details">
                            <CoursesPage />
                        </Route>
                        <Route path="/question-papers">
                            <QuestionPapers />
                        </Route>
                        <Route path="/dashboard">
                            <Dashboard />
                        </Route>
                        <Route path="/messages">
                            <Messages />
                        </Route>
                        <Route path="/conversation/:id">
                            <Conversation />
                        </Route>
                        <Route path="/compose">
                            <Compose />
                        </Route>
                        <Route path="/apply/personal-details/:id">
                            <PersonalDetails />
                        </Route>
                        <Route path="/apply/basic-details/:id">
                            <BasicDetails />
                        </Route>
                        <Route path="/apply/education-details/:id">
                            <EduDetails />
                        </Route>
                        <Route path="/apply/documents-upload/:id">
                            <UploadDocs />
                        </Route>
                        <Route path="/apply/preview/:id">
                            <Preview />
                        </Route>
                        <Route path="/apply/declaration/:id">
                            <Declaration />
                        </Route>
                        <Route path="/apply/make-payment/:id">
                            <Payment />
                        </Route>
                        <Route path="/all-notices">
                            <AllNotices />
                        </Route>
                        <Route path="/syllabuses">
                            <Syllabus />
                        </Route>
                        <Route path="/logout">
                            <Logout />
                        </Route>
                        <Route path="/quick-link/:slug">
                            <CmsPage />
                        </Route>
                        <Route path="/contact-us">
                            <ContactUs />
                        </Route>
                        <Route path="/apply/payment-successfull">
                            <PaymentSuccess />
                        </Route>
                        <Route path="/apply/payment-failed">
                            <PaymentFailed />
                        </Route>
                        <Route path="/view-application/:id">
                            <PrintPreview />
                        </Route>
                        <Route path="/payment-receipt/:id">
                            <PaymentReceipt />
                        </Route>
                        <Route path="/admit-card/:id">
                            <AdmitCard />
                        </Route>
                        <Route path="/merit-document-upload/:id">
                            <MeritDocs />
                        </Route>
                        <Route path="/make-admission-payment/:id">
                            <AdmissionPayment />
                        </Route>
                        <Route path="/admission-payment-receipt/:id">
                            <AdmissionReceipt />
                        </Route>
                    </Switch>
                    <Footer />
                </Router>
            </Provider>
        )
    }
}

export default App
