import React, { Component } from 'react';
import { connect } from 'react-redux';

import { getCourses, getDepartments, courseFilter, fetchCourseDetails } from '../../../../../actions';

class EligibilityCriteria extends Component {

    componentDidMount(){
        this.props.getCourses();
        this.props.getDepartments();
    }

    createMarkup = (body) => {
        return {__html: body};
    }

    searchCourses = () => {
        let department = document.querySelector("#department").value;
        let course = document.querySelector("#course").value;

        this.props.fetchCourseDetails(department, course);
    }

    filterCourses = () => {
        let department = document.querySelector("#department").value;
        this.filterValues(this.props.commonData.courses, department);
        this.searchCourses();
    }

    filterValues = (options, department)  => {
        let filteredCourses =  options.filter(function(course) {
            return course.department_id === department;
        });
        this.props.courseFilter(filteredCourses);
    }

    render() {

        const { departments, courses_copy } = this.props.commonData;
        const { isLoading } = this.props.commonFunctions;
        const { courseDetails } = this.props.appData;

        return (
            <div className="tabs-section-one" id="eligibility">
                <div className="bg-white p-8 mt-10">
                    <div className="w-3/4 mx-auto">
                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                            <div>
                                <label>Select Department: </label>
                                <div className="w-full">
                                    <div className="select-input">
                                        <select name="department" onChange={this.filterCourses} id="department" className={`block w-full py-2 px-3 border border-gray-300 rounded`}>
                                            <option value="">Please Select</option>
                                            {
                                            
                                            departments && departments.length > 0 ? departments.map(option => {
                                                
                                                return(<option value={option.id} key={option.id}>{option.title}</option>)
                                                
                                            }) : null

                                            }
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <label>Select Course: </label>
                                <div className="w-full">
                                    <div className="select-input">
                                        <select name="course" onChange={this.searchCourses} id="course" className={`block w-full py-2 px-3 border border-gray-300 rounded`}>
                                            <option value="">Please Select</option>
                                            {

                                                courses_copy && courses_copy.length > 0 ? courses_copy.map(option => {
                                                                                                    
                                                    return(<option value={option.id} key={option.id}>{option.title}</option>)
                                                    
                                                }) : null

                                            }
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <React.Fragment>
                        {
                            courseDetails && courseDetails.title ? (
                                <div className="eligibity-section mt-4">
                                    <div className="px-4 py-2 text-center font-bold text-xl">
                                        Eligibility Criteria
                                    </div>
                                    <div className="mt-4 px-8" dangerouslySetInnerHTML={this.createMarkup(courseDetails.eligibility)}>
                                    </div>
                                </div>
                            ) : isLoading ? (

                                <div className="text-center">
                                    <img src="/images/spinner.gif" alt="Loading" style={{ width: '50px', margin: '0 auto' }} />
                                    <div className="mt-4">
                                        <h3 className="font-bold tex-xl">Fetching Details</h3>
                                    </div>
                                </div>

                            ) : null
                        }
                    </React.Fragment>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        commonData: state.commonData,
        commonFunctions: state.commonFunctions,
        appData: state.appData,
    }
}

export default connect( mapStateToProps, { getCourses, getDepartments, courseFilter, fetchCourseDetails })(EligibilityCriteria);
