import React, { Component } from 'react';
import { connect } from 'react-redux';

import ForgotPassword from './ForgotPassword';
import ResetOtp from './ResetOtp';
import NewPassword from './NewPassword';

class ResetPassword extends Component {
    render() {

        const { resetOTP, resetVerify } = this.props.user;

        return (
            resetOTP && !resetVerify ? <ResetOtp /> : resetOTP && resetVerify ? <NewPassword /> : <ForgotPassword />
        )
    }
}

const mapStateToProps = state => {
    return {
        user: state.user,
    }
}

export default connect(mapStateToProps)(ResetPassword);
