import React, { Component } from 'react';
import { connect } from 'react-redux';
import {Field, Form, Formik, FormikProps, ErrorMessage} from 'formik';

import ErrorMsg from '../../../includes/form-inputs/ErrorMsg';
import { validateFname, validateLname } from './validate';
import { regsiterStepOne } from '../../../../../actions';
import LoadingBtn from '../../../includes/btn/LoadingBtn';
import ProgressBar from './ProgressBar';

class RegisterFrom extends Component {

    regusterUser = (values, actions) => {
        this.props.regsiterStepOne(values, actions);
    }

    myTextInput = ({ name, field, placeholder, type, border }) => {
        const borderStyle = border ? `border border-gray-300 rounded` : null;
        return (
            <div className="w-full">
                <div className="text-input">
                    <input placeholder={placeholder} type={type} name={name} className={`appearance-none block w-full py-2 px-3 ${borderStyle}`} {...field} />
                </div>
            </div>
        )
    }

    mySelectInput = ({ field, name, options }) => {

        const selectOptions = options && options.length > 0 ? options.map(option => {
            return (
                <option key={option.id} value={option.id}>{option.title}</option>
            )
        }) : null;

        return (
            <div className="w-full">
                <div className="select-input">
                    <select name={name} className={`block w-full py-2 px-3`} {...field}>
                        <option value="">Select Course</option>
                        { selectOptions }
                    </select>
                </div>
            </div>
        )
    }

    render() {

        const { mobile } = this.props.bannerForm;
        const { courses } = this.props.commonData;
        const { isSubmitting } = this.props.commonFunctions;

        return (
            <React.Fragment>
                <ProgressBar width="75%" />
                <div className="form-head text-center">
                    <h1 className="uppercase font-bold text-dark-blue text-xl md:text-2xl lg:text-2xl xl:text-2xl">Genral Information</h1>
                    <p className="text-gray-600 text-lg mt-4">
                        Please enter your general information
                    </p>
                </div>
                <div className="form-body mt-4 mb-4">
                        
                    <Formik
                        initialValues={{fname: '', mname: '', lname: '', mobile, course: ''}}
                        onSubmit={(values, actions) => {
                            this.regusterUser(values, actions);
                        }}
                    >{(props: FormikProps<any>) => (
                        <Form>
                            <div>
                                <div className="form-input border border-gray-300 rounded">
                                    <Field component={this.mySelectInput} name="course" options={courses} ></Field>
                                </div>
                                <ErrorMessage name="course" render={msg => <ErrorMsg msg={msg} />} />
                            </div>
                            <div className="w-full mt-4">
                                <Field component={this.myTextInput} name="fname" validate={validateFname} placeholder="Enter First Name" type="text" border={true}></Field>
                                <ErrorMessage name="fname" render={msg => <ErrorMsg msg={msg} />} />
                            </div>
                            <div className="w-full mt-4">
                                <Field component={this.myTextInput} name="mname" placeholder="Enter Middle Name" type="text" border={true}></Field>
                                <ErrorMessage name="mname" render={msg => <ErrorMsg msg={msg} />} />
                            </div>
                            <div className="w-full mt-4">
                                <Field component={this.myTextInput} name="lname" validate={validateLname} placeholder="Enter Last Name" type="text" border={true}></Field>
                                <ErrorMessage name="lname" render={msg => <ErrorMsg msg={msg} />} />
                            </div>

                            <div className={`form-submit text-center mt-4`}>
                                <button className="rgu-btn-light">
                                    {
                                        isSubmitting ? <LoadingBtn /> : `Continue`
                                    }
                                </button>
                            </div>
                        </Form>
                    )}
                    </Formik>

                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    return{
        bannerForm: state.bannerForm,
        commonData: state.commonData,
        commonFunctions: state.commonFunctions,
    }
}

export default connect( mapStateToProps, {regsiterStepOne} )(RegisterFrom);