import React, { Component } from "react"
import { connect } from "react-redux"
import { Field, Form, Formik } from "formik"

import ErrorMsg from "../includes/form-inputs/ErrorMsg"
import LoadingBtn from "../includes/btn/LoadingBtn"
import { sendResetOTP } from "../../../actions"

class ForgotPassword extends Component {
    submitResetFrom = (values, actions) => {
        this.props.sendResetOTP(values, actions)
    }

    myTextInput = ({ name, field, placeholder, type }) => {
        return (
            <div className="w-full">
                <div className="text-input">
                    <input
                        placeholder={placeholder}
                        type={type}
                        name={name}
                        className={`appearance-none block w-full py-2 px-3`}
                        {...field}
                    />
                </div>
            </div>
        )
    }

    validate = (values) => {
        let errors = {}
        if (!values.mobile) {
            errors.mobile = "Please enter your registered mobile number"
        } else if (values.mobile.toString().length !== 10) {
            errors.mobile = "Invalid mobile number"
        }

        return errors
    }

    render() {
        const { isSubmitting } = this.props.commonFunctions

        return (
            <div className="bulletin-form">
                <div className="form-head text-center mb-8">
                    <h1 className="uppercase font-bold text-dark-blue text-xl md:text-2xl lg:text-2xl xl:text-2xl">
                        FORGOT PASSWORD
                    </h1>
                    <p className="text-gray-600 text-lg mt-4">
                        Please enter your registered mobile number
                    </p>
                </div>
                <div className="form-body">
                    <Formik
                        initialValues={{ mobile: "" }}
                        onSubmit={(values, actions) => {
                            this.submitResetFrom(values, actions)
                        }}
                        validate={this.validate}
                    >
                        {(props: FormikProps<any>) => (
                            <Form>
                                <div className="form-input border border-gray-300 rounded">
                                    <Field
                                        component={this.myTextInput}
                                        name="mobile"
                                        placeholder="Enter your mobile number"
                                        type="number"
                                    ></Field>
                                </div>
                                <div className="mb-4">
                                    {props.touched.mobile &&
                                        props.errors.mobile && (
                                            <ErrorMsg
                                                msg={props.errors.mobile}
                                            />
                                        )}
                                </div>
                                <div className={`form-submit text-center mt-8`}>
                                    <button
                                        type="submit"
                                        className="rgu-btn-light"
                                    >
                                        {isSubmitting ? (
                                            <LoadingBtn />
                                        ) : (
                                            `Submit`
                                        )}
                                    </button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        commonFunctions: state.commonFunctions,
    }
}

export default connect(mapStateToProps, { sendResetOTP })(ForgotPassword)
