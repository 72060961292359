import React from 'react';

import SectionHead from '../../includes/SectionHead';
import './how-to-apply.css';

const HowToApply = () => {
    return (
        <section className="how-to-apply-section py-16 bg-light-blue">
            <div className="container mx-auto px-4">
                <SectionHead title="How to Apply" />
                <div className="hta-wrapper">
                    <div className="grid grid-cols-10 gap-4 how-to-apply-body mt-10">
                        <div className="col-span-1">
                            <img src="/icon/electronics.png" alt="register" />
                        </div>
                        <div className="col-span-2 self-center">
                            <img src="/icon/arrow-r.png" alt="arrow-right" />
                        </div>
                        <div className="col-span-1">
                            <img src="/icon/edit-tools.png" alt="register" />
                        </div>
                        <div className="col-span-2 self-center">
                            <img src="/icon/arrow-r.png" alt="arrow-right" />
                        </div>
                        <div className="col-span-1">
                            <img src="/icon/commerce.png" alt="register" />
                        </div>
                        <div className="col-span-2 self-center">
                            <img src="/icon/arrow-r.png" alt="arrow-right" />
                        </div>
                        <div className="col-span-1">
                            <img src="/icon/download.png" alt="register" />
                        </div>
                    </div>
                    <div className="grid grid-cols-11 gap-4 text-center mt-4 text-sm md:text-lg font-medium">
                        <div className="col-span-1">
                            Register
                        </div>
                        <div className="col-span-1"></div>
                        <div className="col-span-3">
                            Fill up the Application Form
                        </div>
                        <div className="col-span-1"></div>
                        <div className="col-span-2">
                            Make Payment
                        </div>
                        <div className="col-span-1"></div>
                        <div className="col-span-2">
                            Download Application
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default HowToApply;
