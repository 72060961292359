import React, { Component } from 'react';
import { connect } from 'react-redux';

import './faq.css';
import { fetchFaq } from '../../../../../actions';

class Faq extends Component {

    componentDidMount(){
        this.props.fetchFaq();
    }

    render() {

        const { faq } = this.props.appData;

        const faq_items = faq && faq.length > 0 ? faq.map(item => {
            return(
                <div className="faq-tab w-full overflow-hidden border-t" key={item.id}>
                    <input className="absolute opacity-0" id={`tab-single-${item.id}`} type="radio" name="tabs2" />
                    <label className="block p-5 leading-normal cursor-pointer" htmlFor={`tab-single-${item.id}`}>{item.question}</label>
                    <div className="faq-content overflow-hidden bg-gray-100 leading-normal">
                        <p className="p-5">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Tenetur, architecto, explicabo perferendis nostrum, maxime impedit atque odit sunt pariatur illo obcaecati soluta molestias iure facere dolorum adipisci eum? Saepe, itaque.</p>
                    </div>
                </div>
            )
        }) : null;

        /* Optional Javascript to close the radio button version by clicking it again */
        var myRadios = document.getElementsByName('tabs2');
        var setCheck;
        var x = 0;
        for(x = 0; x < myRadios.length; x++){
            myRadios[x].onclick = function(){
                if(setCheck != this){
                    setCheck = this;
                }else{
                    this.checked = false;
                    setCheck = null;
                }
            };
        }
 
        return (
            <div className="tabs-section-one" id="faqs">
                <div className="w-full md:w-4/5 mx-auto p-8">
                    <div className="shadow-md">
                        {faq_items}
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        appData: state.appData,
    }
}

export default connect(mapStateToProps, {fetchFaq})(Faq);
