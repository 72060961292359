import React from 'react'

const ApplyBanner = ({title}) => {
    return (
        <div className="flex justify-center bg-dark-blue text-white py-12 px-4">
            <span className="xl:text-2xl lg:text-2xl md:text-xl sm:text-lg text-lg">{title}</span>
        </div>
    )
}

export default ApplyBanner;
