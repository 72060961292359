import React, { Component } from "react"
import { connect } from "react-redux"
import { withRouter, Link } from "react-router-dom"

import { getPaymentReceipt } from "../../../../actions"
import { baseURL, baseURL2 } from "../../includes/jsFunctions"
import "./receipt.css"

class PaymentReceipt extends Component {
    componentDidMount() {
        this.props.getPaymentReceipt(this.props.match.params.id)
    }

    PrintDiv = () => {
        let divContents = document.getElementById("printdivcontent").innerHTML
        // let printWindow = window.open('', '', 'height=700,width=700');
        // printWindow.document.write('<html><head><title>Payment Receipt - Rajiv Gandhi University Admissions 2020</title>');
        // printWindow.document.write('</head><body >');
        // printWindow.document.write(divContents);
        // printWindow.document.write('</body></html>');
        // printWindow.document.close();
        // printWindow.print();

        var originalContents = document.body.innerHTML
        document.body.innerHTML = divContents
        window.print()
        document.body.innerHTML = originalContents
    }

    render() {
        const { transaction, numtoword, payment } =
            this.props.apply.payment_receipt
        const { settings } = this.props.commonData

        return (
            <React.Fragment>
                {/* <ApplyBanner title="Payment Receipt" /> */}

                <section
                    className="personal-details-page py-16"
                    style={{ backgroundColor: "#F5FBFF" }}
                >
                    {/* <ProgressBar step="4" /> */}
                    <div className="container mx-auto px-8">
                        <div
                            className="form-wrap py-8 bg-white px-4 md:px-8 mt-8 shadow-md rounded-md"
                            id="printdivcontent"
                        >
                            <div className="flex justify-center space-x-6 items-center mb-4">
                                <div className="">
                                    <img
                                        src={`${baseURL}/settings/${settings.logo}`}
                                        alt="logo"
                                        className="w-24"
                                    />
                                </div>
                                {/* <div>
                                    <img
                                        alt="G20"
                                        src="/images/g20-lg.png"
                                        className=" w-32"
                                    />
                                </div> */}
                            </div>

                            <div className="mb-4 text-center">
                                <h1 className="font-medium text-xl">
                                    {settings.institute_name}
                                </h1>
                                <h3>{settings.address}</h3>

                                <h2 className="font-bold mt-6">
                                    Payment Receipt
                                </h2>
                            </div>

                            <div
                                className="grid grid-cols-1 sm:grid-cols-3"
                                id="grid-view"
                            >
                                <div
                                    className={`border border-gray-300 px-4 py-2`}
                                    style={{
                                        display: "flex",
                                        justifyContent: "left",
                                        alignItems: "center",
                                    }}
                                >
                                    Application ID:{" "}
                                    {transaction
                                        ? transaction.application_id
                                        : null}
                                </div>
                                <div
                                    className={`border border-gray-300 px-4 py-2`}
                                    style={{
                                        display: "flex",
                                        justifyContent: "left",
                                        alignItems: "center",
                                    }}
                                >
                                    Name:{" "}
                                    {transaction ? transaction.name : null}
                                </div>
                                <div
                                    className={`border border-gray-300 px-4 py-2`}
                                    style={{
                                        display: "flex",
                                        justifyContent: "left",
                                        alignItems: "center",
                                    }}
                                >
                                    Course:{" "}
                                    {transaction && transaction.application
                                        ? transaction.application.course.title
                                        : null}
                                </div>
                                <div
                                    className={`border border-gray-300 px-4 py-2`}
                                    style={{
                                        display: "flex",
                                        justifyContent: "left",
                                        alignItems: "center",
                                    }}
                                >
                                    Department:{" "}
                                    {transaction && transaction.application
                                        ? transaction.application.department
                                              .name
                                        : null}
                                </div>
                                <div
                                    className={`border border-gray-300 px-4 py-2`}
                                    style={{
                                        display: "flex",
                                        justifyContent: "left",
                                        alignItems: "center",
                                    }}
                                >
                                    Email:{" "}
                                    {transaction ? transaction.email : null}
                                </div>
                                <div
                                    className={`border border-gray-300 px-4 py-2`}
                                    style={{
                                        display: "flex",
                                        justifyContent: "left",
                                        alignItems: "center",
                                    }}
                                >
                                    Mobile:{" "}
                                    {transaction ? transaction.phone : null}
                                </div>
                                <div
                                    className={`border border-gray-300 px-4 py-2`}
                                    style={{
                                        display: "flex",
                                        justifyContent: "left",
                                        alignItems: "center",
                                    }}
                                >
                                    Transaction ID:{" "}
                                    {transaction
                                        ? transaction.transaction_id
                                        : null}
                                </div>
                                <div
                                    className={`border border-gray-300 px-4 py-2`}
                                    style={{
                                        display: "flex",
                                        justifyContent: "left",
                                        alignItems: "center",
                                    }}
                                >
                                    Amount: Rs.
                                    {transaction ? transaction.amount : null}
                                </div>
                                <div
                                    className={`border border-gray-300 px-4 py-2`}
                                    style={{
                                        display: "flex",
                                        justifyContent: "left",
                                        alignItems: "center",
                                    }}
                                >
                                    Amount In Words: {numtoword}
                                </div>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "right",
                                        alignItems: "center",
                                    }}
                                >
                                    Mode of Payment: {payment}
                                </div>
                                <div
                                    className="mt-6"
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    <img
                                        src={`${baseURL2}/qrcodes/${
                                            transaction
                                                ? transaction.qr_code
                                                : null
                                        }`}
                                        alt="qrcode"
                                    />
                                </div>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "right",
                                        alignItems: "center",
                                    }}
                                >
                                    Date:{" "}
                                    {transaction ? transaction.paid_at : null}
                                </div>
                            </div>

                            {settings.govt_logos === 1 && (
                                <div className="flex justify-center flex-wrap mt-6">
                                    {settings.form_logos &&
                                    settings.form_logos.length > 1
                                        ? settings.form_logos.map((logo) => (
                                              <img
                                                  src={`${baseURL}/govt-logos/${logo.logo}`}
                                                  alt="Fit India"
                                                  key={logo.id}
                                                  style={{
                                                      width: "100px",
                                                      marginRight: "1rem",
                                                  }}
                                              />
                                          ))
                                        : null}
                                </div>
                            )}
                        </div>

                        <div className="text-center mt-3">
                            <button
                                type="button"
                                className="rgu-btn-light"
                                onClick={this.PrintDiv}
                            >
                                Print
                            </button>
                            <Link
                                to="/dashboard"
                                className="rgu-btn-light ml-2"
                            >
                                Dashboard
                            </Link>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        apply: state.apply,
        commonData: state.commonData,
    }
}

export default withRouter(
    connect(mapStateToProps, { getPaymentReceipt })(PaymentReceipt)
)
