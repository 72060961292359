import React, { Component } from "react"
import { staticURI } from "../../includes/jsFunctions"

class PaymentFailed extends Component {
    componentDidMount() {
        this.redirect()
    }

    redirect = () => {
        setTimeout(function () {
            window.location.href = `${staticURI}/dashboard`
        }, 3000)
    }

    render() {
        return (
            <div className="success-reset-password text-center py-16 px-4 md:px-8">
                <img
                    src="/icon/failed.png"
                    style={{ margin: "0 auto", width: "200px" }}
                />
                <div className="mt-3">
                    <h1 className="uppercase font-bold text-dark-blue text-xl md:text-2xl lg:text-2xl xl:text-2xl">
                        Payment failed. Please try again.
                    </h1>
                    <p className="text-gray-900 font-bold text-lg mt-4">
                        Do not close your browser. Please wait while we redirect
                        you...
                    </p>
                </div>
            </div>
        )
    }
}

export default PaymentFailed
