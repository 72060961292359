import React, { Component } from "react"
import { connect } from "react-redux"

import {
    removeEducationQualification,
    editQualification,
} from "../../../../actions"

class AddedQualifications extends Component {
    removeRecord = (id, application_id) => {
        this.props.removeEducationQualification(id, application_id)
    }

    editRow = (id) => {
        this.props.editQualification(id)
    }

    render() {
        const { qualifications, stepThree } = this.props.apply

        const table_rows =
            qualifications && qualifications.length > 0
                ? qualifications.map((edu) => {
                      let exam_name, exam_status

                      if (edu.exam === "1") {
                          exam_name = "+2"
                      } else if (edu.exam === "2") {
                          exam_name = "+3"
                      } else if (edu.exam === "3") {
                          exam_name = "PG"
                      } else if (edu.exam === "4") {
                          exam_name = "M.Phil"
                      } else if (edu.exam === "5") {
                          exam_name = "Any Other"
                      }

                      if (edu.completed === "1") {
                          exam_status = "Completed"
                      } else if (edu.completed === "2") {
                          exam_status = "Appearing"
                      }

                      const honours =
                          edu.subjects && edu.subjects.length > 0
                              ? edu.subjects.map((subject) => {
                                    return `${subject.subject}, `
                                })
                              : null

                      return (
                          <tr key={edu.id}>
                              <td>{exam_name}</td>
                              <td>{edu.exam_name}</td>
                              <td>{exam_status}</td>
                              <td>{edu.board_details.name}</td>
                              <td>{edu.roll_no}</td>
                              <td>{edu.registration_no}</td>
                              <td>{edu.passing_year}</td>
                              <td>{edu.marks}</td>
                              <td>{edu.marks_secured}</td>
                              <td>{edu.percentage}</td>
                              <td>{honours}</td>
                              <td>
                                  <button className="mr-2">
                                      <img
                                          src="/icon/edit-tools.png"
                                          onClick={() => this.editRow(edu.id)}
                                          style={{ width: "25px" }}
                                          alt="edit"
                                      />
                                  </button>
                                  <button>
                                      <img
                                          onClick={() =>
                                              this.removeRecord(
                                                  edu.id,
                                                  stepThree.application_id
                                              )
                                          }
                                          src="/icon/delete-button.png"
                                          style={{ width: "30px" }}
                                          alt="remove"
                                      />
                                  </button>
                              </td>
                          </tr>
                      )
                  })
                : null

        return (
            <div
                className="mb-4 mt-4 bg-white shadow-md rounded-md"
                style={{ width: "100%", overflow: "auto" }}
            >
                <table>
                    <thead>
                        <tr>
                            <th>Examination Passed</th>
                            <th>Examination Name</th>
                            <th>Status</th>
                            <th>Board/University</th>
                            <th>Roll No</th>
                            <th>Registration No</th>
                            <th>Year Of Passing</th>
                            <th>Total Marks</th>
                            <th>Marks Secured</th>
                            <th>Percentage Of Marks</th>
                            <th>Honours/Major Subjects</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>{table_rows}</tbody>
                </table>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        apply: state.apply,
    }
}

export default connect(mapStateToProps, {
    removeEducationQualification,
    editQualification,
})(AddedQualifications)
