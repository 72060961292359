import React, { Component } from 'react';
import {Field} from 'formik';
import { getIn } from 'formik';
import { connect } from 'react-redux';

import ErrorMsg from '../../includes/form-inputs/ErrorMsg';
import { validateEmail } from '../../homepage/banner/form/validate';

class Preference extends Component {

    mySelectInput = ({ name, options, field, label }) => {
    
        const selectOptions = options.map(option => {
            return (
                <option value={option.id}>{option.name}</option>
            )
        });
        
        return(
            <div className="select-input">
                <label>{label}</label>
                <select className={`w-full bg-white focus:outline-none py-2 px-4 border border-gray-300 rounded mt-2`} name={name} {...field}>
                    <option value="">Please Select</option>
                    {selectOptions}
                </select>
            </div>
        )
    }

    render() {

        const { props } = this.props;
        let preferenceTwo;

        const selectData = [
            { id: 1, name: 'MA (Mass Communication)'},
            { id: 2, name: 'PGDMC'}, 
        ];

        if(props.values.course.one){

            // preferenceTwo = selectData.splice( selectData.findIndex(item => item.id === parseInt(props.values.preference.one)) , 1);
            // console.log(preferenceTwo);\
            //const index = selectData.findIndex(item => item.id === parseInt(props.values.preference.one));

            preferenceTwo = selectData.filter(item => item.id !== parseInt(props.values.course.one)); // ['a', 'b', 'c', 'd']

            //preferenceTwo = [];

        }else{
            preferenceTwo = [];
        }

        return (
            <React.Fragment>
                <div className="px-8 py-4">
                    <label>Your Preference for MA (Mass Communication) and PGDMC :</label>
                </div>
                <div className="section-three grid grid-cols-2 gap-4 px-8 border-b-gray-300">
                    <div className="mb-4">
                        <div>
                            <div className="mt-2">
                            <Field component={this.mySelectInput} name="course.one" label="Course One" options={selectData}></Field>
                            </div>
                            {getIn(props.errors, 'course.one') && getIn(props.errors, 'course.one') && <ErrorMsg msg={getIn(props.errors, 'course.one')}/>}
                        </div>
                    </div>

                    <div className="mb-4">
                        <div>
                            <div className="mt-2">
                            <Field component={this.mySelectInput} name="course.two" label="Course Two" options={preferenceTwo}></Field>
                            </div>
                            {getIn(props.errors, 'course.two') && getIn(props.touched, 'course.two') && <ErrorMsg msg={getIn(props.errors, 'course.two')}/>}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        commonData: state.commonData,
    }
}

export default connect(mapStateToProps)(Preference);