import React, { Component } from 'react';
import { connect } from 'react-redux';

import ApplyBanner from '../includes/apply-banner/ApplyBanner';
import NoticeItem from '../homepage/notice-links/notices/NoticeItem';
import { fetchNotices, getCourses, getDepartments, courseFilter, findNotices } from '../../../actions';
import { scrollToTop } from '../includes/jsFunctions';

class AllNotices extends Component {

    componentDidMount(){
        this.props.fetchNotices(20);
        this.props.getCourses();
        this.props.getDepartments();
        scrollToTop();
    }

    searchCourses = () => {
        let department = document.querySelector("#department").value;
        let course = document.querySelector("#course").value;

        this.props.findNotices(department, course, 20);
    }

    filterCourses = () => {
        let department = document.querySelector("#department").value;
        this.filterValues(this.props.commonData.courses, department);
        this.searchCourses();
    }

    filterValues = (options, department)  => {
        let filteredCourses =  options.filter(function(course) {
            return course.department_id === department;
        });
        this.props.courseFilter(filteredCourses);
    }

    render() {

        const { notices } = this.props.appData;
        const { departments, courses_copy } = this.props.commonData;
        const { isLoading } = this.props.commonFunctions;

        const notice_items = notices && notices.length > 0 ? notices.map( notice => { 
            return (
                <NoticeItem title={notice.title} key={notice.id} file={notice.filename} course={ notice.course ? notice.course.title : null} />
            )
        }) : null;

        return (
            <React.Fragment>
                <ApplyBanner title="All Notices" />
                <section className="personal-details-page py-16">
                    <div className="container mx-auto px-16">

                        <div className="bg-white p-8 mt-10 mb-8">
                            <div className="w-3/4 mx-auto">
                                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                                    <div>
                                        <label>Select Department: </label>
                                        <div className="w-full">
                                            <div className="select-input">
                                                <select name="department" onChange={this.filterCourses} id="department" className={`block w-full py-2 px-3 border border-gray-300 rounded`}>
                                                    <option value="">Please Select</option>
                                                    {
                                                    
                                                    departments && departments.length > 0 ? departments.map(option => {
                                                        
                                                        return(<option value={option.id} key={option.id}>{option.title}</option>)
                                                        
                                                    }) : null

                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <label>Select Course: </label>
                                        <div className="w-full">
                                            <div className="select-input">
                                                <select name="course" onChange={this.searchCourses} id="course" className={`block w-full py-2 px-3 border border-gray-300 rounded`}>
                                                    <option value="">Please Select</option>
                                                    {

                                                        courses_copy && courses_copy.length > 0 ? courses_copy.map(option => {
                                                                                                            
                                                            return(<option value={option.id} key={option.id}>{option.title}</option>)
                                                            
                                                        }) : null

                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {
                            notices && notices.length > 0 ? 

                            notice_items : isLoading ? (

                                <div className="text-center">
                                    <img src="/images/spinner.gif" alt="Loading" style={{ width: '50px', margin: '0 auto' }} />
                                    <div className="mt-4">
                                        <h3 className="font-bold tex-xl">Fetching Notices</h3>
                                    </div>
                                </div>

                            ) : null
                        }

                    </div>
                </section>
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        commonData: state.commonData,
        commonFunctions: state.commonFunctions,
        appData: state.appData,
    }
}

export default connect( mapStateToProps, { fetchNotices, getCourses, getDepartments, courseFilter, findNotices  } )(AllNotices);
