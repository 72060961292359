import React, { Component } from "react"
import { Field, Form, Formik } from "formik"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

import ErrorMsg from "../../includes/form-inputs/ErrorMsg"
import ApplyBanner from "../../includes/apply-banner/ApplyBanner"
import PermanentAdr from "./PermanentAdr"
import PresentAdr from "./PresentAdr"
import Contact from "./Contact"
import validate from "./validationYup"
import {
    getStates,
    getPersonalDetails,
    applyPersonalDetails,
} from "../../../../actions"
//import { redirect } from '../../includes/jsFunctions';
import ProgressBar from "../../dashboard/progressbar/ProgressBar"
import LoadingBtn from "../../includes/btn/LoadingBtn"
import { staticURI } from "../../includes/jsFunctions"

class PersonalDetails extends Component {
    componentDidMount() {
        this.props.getStates()
        this.props.getPersonalDetails(this.props.match.params.id)
    }

    submitFrom = (values, actions) => {
        this.props.applyPersonalDetails(values, actions)
    }

    myTextInput = ({ name, field, placeholder, type, readonly }) => {
        const disabled = readonly ? true : false

        return (
            <div className="w-full">
                <label>{placeholder}</label>
                <div className="text-input mt-2">
                    <input
                        placeholder={placeholder}
                        type={type}
                        name={name}
                        className={`appearance-none block w-full py-2 px-3 border border-gray-300 rounded`}
                        {...field}
                        readOnly={disabled}
                    />
                </div>
            </div>
        )
    }

    myRadioInput = ({ text, field, name, checked, value, selected }) => {
        const border = checked
            ? `border border-blue-200`
            : `border border-gray-200`

        return (
            <div className={`px-1 sm:px-4 custom-radio-btn ${border}`}>
                <label className="container">
                    {text}
                    <input
                        type="radio"
                        name={name}
                        {...field}
                        value={value}
                        checked={value === selected}
                    />
                    <span className="checkmark"></span>
                </label>
            </div>
        )
    }

    mySelectInput = ({ name, options, field, label }) => {
        const selectOptions = options.map((option) => {
            return (
                <option key={option.name} value={option.id}>
                    {option.name}
                </option>
            )
        })

        return (
            <div className="select-input">
                <label>{label}</label>
                <select
                    className={`w-full bg-white focus:outline-none py-2 px-4 border border-gray-300 rounded mt-2`}
                    name={name}
                    {...field}
                >
                    <option value="">Please Select</option>
                    {selectOptions}
                </select>
            </div>
        )
    }

    render() {
        const religionData = [
            { id: 1, name: "Hinduism" },
            { id: 2, name: "Islam" },
            { id: 3, name: "Christianity" },
            { id: 4, name: "Sikhism" },
            { id: 5, name: "Jainism" },
            { id: 6, name: "Buddhism" },
            { id: 7, name: "Others" },
        ]
        const bloodData = [
            { id: 1, name: "A" },
            { id: 2, name: "B" },
            { id: 3, name: "AB" },
            { id: 4, name: "O" },
            { id: 5, name: "A+" },
            { id: 6, name: "B+" },
            { id: 7, name: "AB+" },
            { id: 8, name: "O+" },
            { id: 9, name: "A-" },
            { id: 10, name: "B-" },
            { id: 11, name: "AB-" },
            { id: 12, name: "O-" },
        ]

        const { loginLoading, login, user } = this.props.user
        const { stepOne, status } = this.props.apply
        const { isSubmitting } = this.props.commonFunctions

        let permanent_address = {},
            current_address = {}

        if (stepOne && stepOne.addresses && stepOne.addresses.length > 0) {
            for (let i = 0; i < stepOne.addresses.length; i++) {
                if (stepOne.addresses[i].type === "P") {
                    permanent_address = stepOne.addresses[i]
                } else if (stepOne.addresses[i].type === "C") {
                    current_address = stepOne.addresses[i]
                }
            }
        } else if (
            stepOne &&
            stepOne.saved_addresses &&
            stepOne.saved_addresses.length > 0
        ) {
            for (let i = 0; i < stepOne.saved_addresses.length; i++) {
                if (stepOne.saved_addresses[i].type === "P") {
                    permanent_address = stepOne.saved_addresses[i]
                } else if (stepOne.saved_addresses[i].type === "C") {
                    current_address = stepOne.saved_addresses[i]
                }
            }
        }

        // if(stepOne.steps && stepOne.steps !== '1'){
        //     redirect(stepOne.steps, stepOne.application_id);
        // }

        if (!loginLoading && !login) {
            window.location.href = `${staticURI}/`
        }

        if (stepOne.status === "Y" && stepOne.enabled_edit === 0) {
            window.location.href = `${staticURI}/dashboard`
        }

        if (status) {
            window.location.href = `${staticURI}/apply/basic-details/${stepOne.application_id}`
        }

        let birthday =
            stepOne && stepOne.common_details
                ? new Date(stepOne.common_details.dob)
                : stepOne.dob
                ? new Date(stepOne.dob)
                : null

        return (
            <React.Fragment>
                <ApplyBanner title="Personal Details" />

                <section
                    className="personal-details-page py-16"
                    style={{ backgroundColor: "#F5FBFF" }}
                >
                    <ProgressBar step="1" />
                    <div className="container mx-auto px-8 mt-32">
                        <div className="form-wrap shadow-md bg-white pb-4  rounded-md">
                            <Formik
                                initialValues={{
                                    fname: user.fname,
                                    mname: user.mname,
                                    lname: user.lname,
                                    father: stepOne.father
                                        ? stepOne.father
                                        : stepOne.common_details
                                        ? stepOne.common_details.father
                                        : "",
                                    mother: stepOne.mother
                                        ? stepOne.mother
                                        : stepOne.common_details
                                        ? stepOne.common_details.mother
                                        : "",
                                    gender: stepOne.gender
                                        ? stepOne.gender
                                        : stepOne.common_details
                                        ? stepOne.common_details.gender
                                        : "",
                                    dob: birthday,
                                    aadhaar_no: stepOne.aadhaar_no
                                        ? stepOne.aadhaar_no
                                        : stepOne.common_details
                                        ? stepOne.common_details.aadhaar_no
                                        : "",
                                    image: stepOne.image,
                                    signature: stepOne.signature,
                                    aadhaar_front: stepOne.aadhaar_front,
                                    aadhaar_back: stepOne.aadhaar_back,
                                    religion: stepOne.religion
                                        ? stepOne.religion
                                        : stepOne.common_details
                                        ? stepOne.common_details.religion
                                        : "",
                                    other_religion: stepOne.other_religion
                                        ? stepOne.other_religion
                                        : "",
                                    blood_group: stepOne.blood_group
                                        ? stepOne.blood_group
                                        : stepOne.common_details
                                        ? stepOne.common_details.blood_group
                                        : "",
                                    permanent_adr: {
                                        adr_line_1: permanent_address.address,
                                        adr_line_2:
                                            permanent_address.adr_line_2,
                                        country: permanent_address.country,
                                        state: permanent_address.state,
                                        city: permanent_address.city,
                                        pincode: permanent_address.pin,
                                    },
                                    present_adr: {
                                        adr_line_1: current_address.address,
                                        adr_line_2: current_address.adr_line_2,
                                        country: current_address.country,
                                        state: current_address.state,
                                        city: current_address.city,
                                        pincode: current_address.pin,
                                    },
                                    email: user.email,
                                    mobile: user.mobile,
                                    status: stepOne.status,
                                    steps: stepOne.steps,
                                    application_id: stepOne.application_id,
                                }}
                                onSubmit={(values, actions) => {
                                    this.submitFrom(values, actions)
                                }}
                                enableReinitialize={true}
                                //validate = {validate}
                                validationSchema={validate}
                            >
                                {(props: FormikProps<any>) => (
                                    <Form>
                                        <div className="section-one px-8 py-8">
                                            <div className="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-3 gap-4 mb-4">
                                                <div className="form-input">
                                                    <Field
                                                        component={
                                                            this.myTextInput
                                                        }
                                                        name="fname"
                                                        placeholder="First Name"
                                                        type="text"
                                                        readonly={true}
                                                    ></Field>
                                                </div>
                                                <div className="form-input">
                                                    <Field
                                                        component={
                                                            this.myTextInput
                                                        }
                                                        name="mname"
                                                        placeholder="Middle Name"
                                                        type="text"
                                                        readonly={true}
                                                    ></Field>
                                                </div>
                                                <div className="form-input">
                                                    <Field
                                                        component={
                                                            this.myTextInput
                                                        }
                                                        name="lname"
                                                        placeholder="Last Name"
                                                        type="text"
                                                        readonly={true}
                                                    ></Field>
                                                </div>
                                            </div>

                                            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 mb-3">
                                                <div className="form-input">
                                                    <Field
                                                        component={
                                                            this.myTextInput
                                                        }
                                                        name="father"
                                                        placeholder="Father's Name"
                                                        type="text"
                                                        readonly={false}
                                                    ></Field>
                                                    {props.touched.father &&
                                                        props.errors.father && (
                                                            <ErrorMsg
                                                                msg={
                                                                    props.errors
                                                                        .father
                                                                }
                                                            />
                                                        )}
                                                </div>
                                                <div className="form-input">
                                                    <Field
                                                        component={
                                                            this.myTextInput
                                                        }
                                                        name="mother"
                                                        placeholder="Mother's Name"
                                                        type="text"
                                                        readonly={false}
                                                    ></Field>
                                                    {props.touched.mother &&
                                                        props.errors.mother && (
                                                            <ErrorMsg
                                                                msg={
                                                                    props.errors
                                                                        .mother
                                                                }
                                                            />
                                                        )}
                                                </div>
                                            </div>
                                            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-3">
                                                <div>
                                                    <label>Gender</label>
                                                    <div className="grid grid-cols-3 mb-3 gap-4 mt-2">
                                                        <Field
                                                            component={
                                                                this
                                                                    .myRadioInput
                                                            }
                                                            name="gender"
                                                            text="Male"
                                                            value="M"
                                                            selected={
                                                                props.values
                                                                    .gender
                                                            }
                                                        ></Field>
                                                        <Field
                                                            component={
                                                                this
                                                                    .myRadioInput
                                                            }
                                                            name="gender"
                                                            text="Female"
                                                            value="F"
                                                            selected={
                                                                props.values
                                                                    .gender
                                                            }
                                                        ></Field>
                                                        <Field
                                                            component={
                                                                this
                                                                    .myRadioInput
                                                            }
                                                            name="gender"
                                                            text="Other"
                                                            value="O"
                                                            selected={
                                                                props.values
                                                                    .gender
                                                            }
                                                        ></Field>
                                                    </div>
                                                    {props.touched.gender &&
                                                        props.errors.gender && (
                                                            <ErrorMsg
                                                                msg={
                                                                    props.errors
                                                                        .gender
                                                                }
                                                            />
                                                        )}
                                                </div>
                                                <div className="form-input">
                                                    <div className="w-full">
                                                        <label>
                                                            Date Of Birth
                                                        </label>
                                                        <div className="text-input mt-2">
                                                            <DatePicker
                                                                selected={
                                                                    props.values
                                                                        .dob
                                                                }
                                                                onChange={(
                                                                    date
                                                                ) =>
                                                                    props.setFieldValue(
                                                                        "dob",
                                                                        date
                                                                    )
                                                                }
                                                                maxDate={
                                                                    new Date()
                                                                }
                                                                showMonthDropdown
                                                                showYearDropdown
                                                                dropdownMode="select"
                                                                dateFormat="dd-MM-yyyy"
                                                                className="appearance-none block w-full py-2 px-3 border border-gray-300 rounded"
                                                                placeholderText="Click to select a date"
                                                            />
                                                            {props.touched
                                                                .dob &&
                                                                props.errors
                                                                    .dob && (
                                                                    <ErrorMsg
                                                                        msg={
                                                                            props
                                                                                .errors
                                                                                .dob
                                                                        }
                                                                    />
                                                                )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="grid grid-cols-1 sm:grid-cols-3 mb-4 gap-4">
                                                <div>
                                                    <Field
                                                        component={
                                                            this.mySelectInput
                                                        }
                                                        name="religion"
                                                        label="Religion"
                                                        options={religionData}
                                                    ></Field>
                                                    {props.touched.religion &&
                                                        props.errors
                                                            .religion && (
                                                            <ErrorMsg
                                                                msg={
                                                                    props.errors
                                                                        .religion
                                                                }
                                                            />
                                                        )}
                                                </div>

                                                {parseInt(
                                                    props.values.religion
                                                ) === 7 ? (
                                                    <div>
                                                        <Field
                                                            component={
                                                                this.myTextInput
                                                            }
                                                            name="other_religion"
                                                            placeholder="Religion Name"
                                                            type="text"
                                                            readonly={false}
                                                        ></Field>
                                                        {props.touched
                                                            .other_religion &&
                                                            props.errors
                                                                .other_religion && (
                                                                <ErrorMsg
                                                                    msg={
                                                                        props
                                                                            .errors
                                                                            .other_religion
                                                                    }
                                                                />
                                                            )}
                                                    </div>
                                                ) : null}

                                                <div>
                                                    <Field
                                                        component={
                                                            this.mySelectInput
                                                        }
                                                        name="blood_group"
                                                        label="Blood Group"
                                                        options={bloodData}
                                                    ></Field>
                                                    {props.touched
                                                        .blood_group &&
                                                        props.errors
                                                            .blood_group && (
                                                            <ErrorMsg
                                                                msg={
                                                                    props.errors
                                                                        .blood_group
                                                                }
                                                            />
                                                        )}
                                                </div>

                                                <div>
                                                    <Field
                                                        component={
                                                            this.myTextInput
                                                        }
                                                        name="aadhaar_no"
                                                        placeholder="Aadhaar Number"
                                                        type="number"
                                                        readonly={false}
                                                    ></Field>
                                                    {props.touched.aadhaar_no &&
                                                        props.errors
                                                            .aadhaar_no && (
                                                            <ErrorMsg
                                                                msg={
                                                                    props.errors
                                                                        .aadhaar_no
                                                                }
                                                            />
                                                        )}
                                                </div>
                                            </div>
                                        </div>

                                        <PresentAdr props={props} />

                                        <PermanentAdr props={props} />

                                        <Contact
                                            props={props}
                                            image={props.values.image}
                                            sign={props.values.signature}
                                        />

                                        <div className="text-center mt-4 mb-4">
                                            {isSubmitting ? (
                                                <button
                                                    className="rgu-btn-light"
                                                    type="button"
                                                    disabled
                                                >
                                                    <LoadingBtn />
                                                </button>
                                            ) : (
                                                <button
                                                    className="rgu-btn-light"
                                                    type="submit"
                                                >
                                                    Save &amp; Next
                                                </button>
                                            )}
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        apply: state.apply,
        commonFunctions: state.commonFunctions,
    }
}

export default withRouter(
    connect(mapStateToProps, {
        getStates,
        getPersonalDetails,
        applyPersonalDetails,
    })(PersonalDetails)
)
