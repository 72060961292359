import React, { Component } from 'react'

class Reply extends Component {
    render() {
        return (
            <section className="reply-form-section ">
                <h1 className="mb-3 text-xl font-bold">Reply to this conversation:</h1>
                <div className="grid grid-cols-3 gap-4">
                    <div className="col-span-2">
                        <textarea rows="3" placeholder="Enter your message..." className="border border-gray-300 rounded py-2 px-4 w-full mb-1"></textarea>
                        <label>Attachemnts: (optional)</label>
                        <input type="file" className="border border-gray-300 rounded mt-1 py-2 px-4 w-full" />
                    </div>
                    <div className="col-span-1">
                        <button className="rgu-btn-dark">
                            Send Message
                        </button>
                    </div>
                </div>
            </section>
        )
    }
}

export default Reply;