import React from 'react';

const ProgressBar = ({ width }) => {
    return (
        <div className="progressbar-bg" style={{ backgroundColor: '#ccc', height: '10px', width: '100%', position: 'absolute', left: 0, top: 0 }}>
            <div className="form-progressbar" style={{ backgroundColor: '#38b6ff', width: width, height: '10px' }}></div>
        </div>
    )
}

export default ProgressBar;
