import React, { Component } from 'react';
import { connect } from 'react-redux';
import {Field, Form, Formik} from 'formik';

import ErrorMsg from '../includes/form-inputs/ErrorMsg';
import { submitBulletinForm, resetBulletin } from '../../../actions';
import { baseURL } from '../includes/jsFunctions';

class BulletinForm extends Component {

    submitBulletinFrom = (values, actions) => {
        this.props.submitBulletinForm(values, actions);
    }

    myTextInput = ({ name, field, placeholder, type }) => {
        return (
            <div className="w-full">
                <div className="text-input">
                    <input placeholder={placeholder} type={type} name={name} className={`appearance-none block w-full py-2 px-3`} {...field} />
                </div>
            </div>
        )
    }

    mySelectInput = ({ field, name, options }) => {

        const selectOptions = options && options.length > 0 ? options.map(option => {
            return (
                <option value={option.id}>{option.name}</option>
            )
        }) : null;

        return (
            <div className="w-full">
                <div className="select-input">
                    <select name={name} className={`block w-full py-2 px-3`} {...field}>
                        <option value="">Select State</option>
                        { selectOptions }
                    </select>
                </div>
            </div>
        )
    }

    downloadURI = (uri) => {
        var link = document.createElement("a");
        // If you don't know the name or want to use
        // the webserver default set name = ''
        // link.setAttribute('download', '');
        // link.setAttribute('target', '_blank');
        link.href = uri;
        link.download = 'download';
        document.body.appendChild(link);
        link.click();
        link.remove();

        this.props.resetBulletin();
    } 
    
    SaveToDisk = (fileURL, fileName) => {
        // for non-IE
        if (!window.ActiveXObject) {
            var save = document.createElement('a');
            save.href = fileURL;
            save.target = '_blank';
            save.download = fileName || 'unknown';
    
            var evt = new MouseEvent('click', {
                'view': window,
                'bubbles': true,
                'cancelable': false
            });
            save.dispatchEvent(evt);
    
            (window.URL || window.webkitURL).revokeObjectURL(save.href);
        }
    
        // for IE < 11
        else if ( !! window.ActiveXObject && document.execCommand)     {
            var _window = window.open(fileURL, '_blank');
            _window.document.close();
            _window.document.execCommand('SaveAs', true, fileName || fileURL)
            _window.close();
        }
    }

    validate = values => {
        let errors = {};
        if (!values.email) {
          errors.email = "Please enter your email";
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
          errors.email = "Invalid email address format";
        }

        if (!values.name) {
          errors.name = "Please enter your name";
        }

        if (!values.mobile) {
            errors.mobile = "Please enter your mobile number";
        }else if(values.mobile.toString().length !== 10){
            errors.mobile = "Invalid mobile number";
        }

        if (!values.state) {
            errors.state = "Please select your state";
        }

        return errors;
    }

    render() {

        const { states, bulletinFile } = this.props.commonData;

        if(bulletinFile){
            const url = `${baseURL}/bulletin/${bulletinFile}`;
            this.SaveToDisk(url, 'RGU_Admission_Bulletin.pdf');
        }

        return (
            <div className="bulletin-form">
                <div className="form-head text-center mb-8">
                    <h1 className="uppercase font-bold text-dark-blue text-xl md:text-2xl lg:text-2xl xl:text-2xl">Admission Bulletin</h1>
                    <p className="text-gray-600 text-lg mt-4">
                        Please fill in the form below
                    </p>
                </div>
                <div className="form-body">
                    <Formik
                        initialValues={{name: '', mobile: '', email: '', state: ''}}
                        onSubmit={(values, actions) => {
                            this.submitBulletinFrom(values, actions);
                        }}
                        validate = {this.validate}
                    >{(props: FormikProps<any>) => (
                        
                        <Form>
                            <div className="form-input border border-gray-300 rounded">
                                <Field component={this.myTextInput} name="name" placeholder="Enter your name" type="text" border={true}></Field>
                            </div>
                            <div className="mb-4">
                                {props.touched.name && props.errors.name && <ErrorMsg msg={props.errors.name} />}
                            </div>
                            <div className="form-input flex border border-gray-300 rounded">
                                <select>
                                    <option>+91</option>
                                </select>
                                <Field component={this.myTextInput} name="mobile" placeholder="Enter mobile number" type="number"></Field>
                            </div>
                            <div className="mb-4">
                                {props.touched.mobile && props.errors.mobile && <ErrorMsg msg={props.errors.mobile} />}
                            </div>
                            <div className="form-input border border-gray-300 rounded">
                                <Field component={this.myTextInput} name="email" placeholder="Enter your email" type="email"></Field>
                            </div>
                            <div className="mb-4">
                                {props.touched.email && props.errors.email && <ErrorMsg msg={props.errors.email} />}
                            </div>
                            <div className="form-input border border-gray-300 rounded">
                                <Field component={this.mySelectInput} name="state" options={states} ></Field>
                            </div>
                            <div className="mb-4">
                                {props.touched.state && props.errors.state && <ErrorMsg msg={props.errors.state} />}
                            </div>
                            <div className={`form-submit text-center mt-8`}>
                                <button type="submit" className="rgu-btn-light">Submit</button>
                            </div>
                        </Form>
                    )}
                    </Formik>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        commonData: state.commonData
    }
}

export default connect(mapStateToProps, { submitBulletinForm, resetBulletin })(BulletinForm);