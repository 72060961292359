import React, { Component } from 'react';
import { connect } from 'react-redux';

import RegisterStepOne from './RegisterStepOne';
import RegisterStepTwo from './RegisterStepTwo';

class RegisterFrom extends Component {

    render() {

        const { step } = this.props.bannerForm;

        return (
            step === 1 ? <RegisterStepOne /> : <RegisterStepTwo />
        )
    }
}

const mapStateToProps = state => {
    return{
        bannerForm: state.bannerForm
    }
}

export default connect( mapStateToProps )(RegisterFrom);