import { api } from "../api"
import { baseURL2 } from "../components/pages/includes/jsFunctions"

export const sendOTP = (formValues, actions) => {
    return async (dispatch) => {
        dispatch(startSubmitting())
        const url = `/send-otp`
        var formdata = new FormData()

        formdata.append("mobile", formValues.mobile)
        const response = await api
            .post(url, formdata)
            .then((res) => {
                return res
            })
            .catch((error) => {
                actions.setErrors(error.response.data.errors)
                return error.response
            })

        dispatch({
            type: "SEND_OTP",
            payload: response,
        })
        dispatch(stopSubmitting())
    }
}

export const sendEmailOTP = (formValues, actions) => {
    return async (dispatch) => {
        dispatch(startSubmitting())
        const url = `/send-email-otp`
        var formdata = new FormData()

        formdata.append("mobile", formValues.mobile)
        formdata.append("email", formValues.email)
        const response = await api
            .post(url, formdata)
            .then((res) => {
                return res
            })
            .catch((error) => {
                actions.setErrors(error.response.data.errors)
                return error.response
            })

        dispatch({
            type: "SEND_EMAIL_OTP",
            payload: response,
        })
        dispatch(stopSubmitting())
    }
}

export const verifyOTP = (formValues, actions) => {
    return async (dispatch) => {
        dispatch(startSubmitting())
        const url = `/verify-otp`
        var formdata = new FormData()

        formdata.append("mobile", formValues.mobile)
        formdata.append("otp", formValues.otp)
        const response = await api
            .post(url, formdata)
            .then((res) => {
                return res
            })
            .catch((error) => {
                actions.setErrors(error.response.data.errors)
                return error.response
            })

        dispatch({
            type: "VERIFY_OTP",
            payload: response,
        })
        dispatch(stopSubmitting())
    }
}

export const verifyEmailOTP = (formValues, actions) => {
    return async (dispatch) => {
        dispatch(startSubmitting())
        const url = `/verify-email-otp`
        var formdata = new FormData()

        formdata.append("email", formValues.email)
        formdata.append("otp", formValues.otp)

        const response = await api
            .post(url, formdata)
            .then((res) => {
                return res
            })
            .catch((error) => {
                actions.setErrors(error.response.data.errors)
                return error.response
            })

        dispatch({
            type: "VERIFY_EMAIL_OTP",
            payload: response,
        })
        dispatch(stopSubmitting())
    }
}

export const regsiterStepOne = (formValues, actions) => {
    return async (dispatch) => {
        dispatch(startSubmitting())
        const url = `/auth/register-step-one`
        var formdata = new FormData()

        formdata.append("mobile", formValues.mobile)
        formdata.append("course", formValues.course)
        formdata.append("fname", formValues.fname)
        formdata.append("mname", formValues.mname)
        formdata.append("lname", formValues.lname)

        const response = await api
            .post(url, formdata)
            .then((res) => {
                return res
            })
            .catch((error) => {
                actions.setErrors(error.response.data.errors)
                return error.response
            })

        dispatch({
            type: "REGISTER_STEP_ONE",
            payload: response,
        })
        dispatch(stopSubmitting())
    }
}

/* Set Cookie */
const setCookie = (cname, cvalue, exdays) => {
    var d = new Date()
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000)
    var expires = "expires=" + d.toUTCString()
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/"
}

/* Check if cookie is present */
const getCookie = (cname) => {
    var name = cname + "="
    var decodedCookie = decodeURIComponent(document.cookie)
    var ca = decodedCookie.split(";")
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i]
        while (c.charAt(0) === " ") {
            c = c.substring(1)
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length)
        }
    }
    return ""
}

/* Delete Cookie */
const delete_cookie = (name) => {
    document.cookie = name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;"
}

export const regsiterStepTwo = (formValues, actions) => {
    return async (dispatch) => {
        dispatch(startSubmitting())
        const url = `/auth/register-step-two`
        var formdata = new FormData()

        formdata.append("mobile", formValues.mobile)
        formdata.append("email", formValues.email)
        formdata.append("password", formValues.password)
        formdata.append(
            "password_confirmation",
            formValues.password_confirmation
        )

        const response = await api
            .post(url, formdata)
            .then((res) => {
                return res
            })
            .catch((error) => {
                actions.setErrors(error.response.data.errors)
                return error.response
            })

        dispatch({
            type: "REGISTER_STEP_TWO",
            payload: response,
        })

        dispatch(stopSubmitting())
        setCookie("userToken", response.data.session.original.access_token, 2)
    }
}

//Show Modal Status
export const showModal = (modal) => {
    return {
        type: "OPEN_MODAL",
        payload: modal,
    }
}

//Hide Modal Status
export const closeModal = (modal) => {
    return async (dispatch) => {
        dispatch(resetForgotPassword())
        dispatch({
            type: "CLOSE_MODAL",
            payload: modal,
        })
    }
}

export const checkLastDate = (course) => {
    return {
        type: "COURSE_LAST_DATE",
        payload: course.last_date_js,
    }
}

export const setPresentStates = (data) => {
    return {
        type: "PRESENT_STATES",
        payload: data,
    }
}

export const setPresentCities = (data) => {
    return {
        type: "PRESENT_CITIES",
        payload: data,
    }
}

export const setPermanentStates = (data) => {
    return {
        type: "PERMANENT_STATES",
        payload: data,
    }
}

export const setPermanentCities = (data) => {
    return {
        type: "PERMANENT_CITIES",
        payload: data,
    }
}

//Get States data
export const getPresentCities = (state) => {
    return async (dispatch) => {
        const url = `/cities/${state}`
        const response = await api
            .get(url)
            .then((res) => {
                return res
            })
            .catch((error) => {
                return error.response
            })

        dispatch({
            type: "GET_PRESENT_CITIES",
            payload: response,
        })
    }
}

//Get Campus Facilities
export const getCampusFacilities = () => {
    return async (dispatch) => {
        const url = `/campus-facilities`
        const response = await api
            .get(url)
            .then((res) => {
                return res
            })
            .catch((error) => {
                return error.response
            })

        dispatch({
            type: "GET_CAMPUS_FACILITIES",
            payload: response,
        })
    }
}

//Get States data
export const getPermanentCities = (state) => {
    return async (dispatch) => {
        const url = `/cities/${state}`
        const response = await api
            .get(url)
            .then((res) => {
                return res
            })
            .catch((error) => {
                return error.response
            })

        dispatch({
            type: "GET_PERMANENT_CITIES",
            payload: response,
        })
    }
}

//Get States data
export const getStates = () => {
    return async (dispatch) => {
        const url = `/countries-states-cities`
        const response = await api
            .get(url)
            .then((res) => {
                return res
            })
            .catch((error) => {
                return error.response
            })

        dispatch({
            type: "GET_STATES",
            payload: response,
        })
    }
}

export const getOnlyStates = () => {
    return async (dispatch) => {
        const url = `/indian-states`
        const response = await api
            .get(url)
            .then((res) => {
                return res
            })
            .catch((error) => {
                return error.response
            })

        dispatch({
            type: "GET_INDIAN_STATES",
            payload: response,
        })
    }
}

//Get Issue Categories
export const getIssueCategories = () => {
    return async (dispatch) => {
        const url = `/issues/categories`
        const response = await api
            .get(url)
            .then((res) => {
                return res
            })
            .catch((error) => {
                return error.response
            })

        dispatch({
            type: "GET_ISSUE_CATEGORIES",
            payload: response,
        })
    }
}

export const issueSubCategories = (values) => {
    return {
        type: "SET_ISSUE_SUB_CATEGORIES",
        payload: values,
    }
}

export const setEWS = (values) => {
    return {
        type: "SET_EWS",
        payload: values,
    }
}

export const setOBC = (values) => {
    return {
        type: "SET_OBC",
        payload: values,
    }
}

export const submitIssue = (formValues, actions) => {
    return async (dispatch) => {
        const url = `/auth/register-step-two`
        var formdata = new FormData()

        formdata.append("category", formValues.category)
        formdata.append("sub_category", formValues.sub_category)
        formdata.append("message", formValues.message)
        formdata.append("file", formValues.file)

        const response = await api
            .post(url, formdata)
            .then((res) => {
                return res
            })
            .catch((error) => {
                actions.setErrors(error.response.data.errors)
                return error.response
            })

        dispatch({
            type: "NEW_ISSUE",
            payload: response,
        })
    }
}

//Submit Bulletitn Form
export const submitBulletinForm = (formValues, actions) => {
    return async (dispatch) => {
        dispatch(startSubmitting())
        const url = `/bulletin-form`
        var formdata = new FormData()

        formdata.append("name", formValues.name)
        formdata.append("mobile", formValues.mobile)
        formdata.append("email", formValues.email)
        formdata.append("state", formValues.state)

        const response = await api
            .post(url, formdata)
            .then((res) => {
                return res
            })
            .catch((error) => {
                actions.setErrors(error.response.data.errors)
                return error.response
            })

        dispatch({
            type: "SUBMIT_BULLETIN_FORM",
            payload: response,
        })
        dispatch(stopSubmitting())
    }
}

export const resetBulletin = () => {
    return {
        type: "RESET_BULLETIN",
    }
}

export const resetForgotPassword = () => {
    return {
        type: "FORGOT_PASSWORD",
        payload: false,
    }
}

export const forgotPassword = () => {
    return {
        type: "FORGOT_PASSWORD",
        payload: true,
    }
}

//Login
export const userLogin = (formValues, actions) => {
    return async (dispatch) => {
        dispatch(startSubmitting())
        const url = `/auth/login`
        var formdata = new FormData()

        formdata.append("email", formValues.email)
        formdata.append("password", formValues.password)

        const response = await api
            .post(url, formdata)
            .then((res) => {
                return res
            })
            .catch((error) => {
                actions.setErrors(error.response.data.errors)
                return error.response
            })

        dispatch({
            type: "USER_LOGIN",
            payload: response,
        })
        dispatch(stopSubmitting())
        setCookie("userToken", response.data.access_token, 2)
    }
}

const startSubmitting = () => {
    return {
        type: "START_SUBMITTING",
    }
}

const stopSubmitting = () => {
    return {
        type: "STOP_SUBMITTING",
    }
}

const startLoading = () => {
    return {
        type: "START_LOADING",
    }
}

const stopLoading = () => {
    return {
        type: "STOP_LOADING",
    }
}

/* Get Logged In User */
export const checkUser = () => {
    return async (dispatch) => {
        const token = getCookie("userToken")
        const url = `/auth/me`
        const response = await api
            .post(url, { token })
            .then((res) => {
                return res
            })
            .catch((error) => {
                return error.response
            })

        dispatch({
            type: "CHECK_USER",
            payload: response,
        })
    }
}

/* Logout User */
export const logout = () => {
    return async (dispatch) => {
        const token = getCookie("userToken")
        const url = `/auth/logout`
        const response = await api
            .get(url, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                return res
            })
            .catch((error) => {
                return error.response
            })

        dispatch({
            type: "LOGOUT_USER",
            payload: response,
        })
        delete_cookie("userToken")
    }
}

//Get Courses
export const getCourses = () => {
    return async (dispatch) => {
        const url = `/course-list`
        const response = await api
            .get(url)
            .then((res) => {
                return res
            })
            .catch((error) => {
                return error.response
            })

        dispatch({
            type: "GET_COURSES",
            payload: response,
        })
    }
}

//Fetch Settings
export const fetchSettings = () => {
    return async (dispatch) => {
        const url = `/site-settings`
        const response = await api
            .get(url)
            .then((res) => {
                return res
            })
            .catch((error) => {
                return error.response
            })

        dispatch({
            type: "FETCH_SETTINGS",
            payload: response,
        })
    }
}

export const courseFilter = (values) => {
    return {
        type: "FILTER_COURSES",
        payload: values,
    }
}

//Get Departments
export const getDepartments = () => {
    return async (dispatch) => {
        const url = `/department-list`
        const response = await api
            .get(url)
            .then((res) => {
                return res
            })
            .catch((error) => {
                return error.response
            })

        dispatch({
            type: "GET_DEPARTMENTS",
            payload: response,
        })
    }
}

//Start Apply
export const startApply = (formValues, actions) => {
    return async (dispatch) => {
        dispatch(startSubmitting())
        const url = `/auth/start-apply`
        var formdata = new FormData()
        const token = getCookie("userToken")

        formdata.append("department", formValues.department)
        formdata.append("course", formValues.course)

        const response = await api
            .post(url, formdata, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                return res
            })
            .catch((error) => {
                actions.setErrors(error.response.data.errors)
                return error.response
            })

        dispatch({
            type: "START_APPLY",
            payload: response,
        })
        dispatch(stopSubmitting())
    }
}

export const getPaymentReceipt = (id) => {
    return async (dispatch) => {
        const url = `/auth/payment-receipt/${id}`
        const response = await api
            .get(url)
            .then((res) => {
                return res
            })
            .catch((error) => {
                return error.response
            })

        dispatch({
            type: "GET_PAYMENT_RECEIPT",
            payload: response,
        })
    }
}

export const getAdmissionPaymentReceipt = (id) => {
    return async (dispatch) => {
        const url = `/auth/admission-payment-receipt/${id}`
        const response = await api
            .get(url)
            .then((res) => {
                return res
            })
            .catch((error) => {
                return error.response
            })

        dispatch({
            type: "GET_ADMISSION_PAYMENT_RECEIPT",
            payload: response,
        })
    }
}

export const getAdmitCard = (id) => {
    return async (dispatch) => {
        const url = `/auth/admit-card/${id}`
        const response = await api
            .get(url)
            .then((res) => {
                return res
            })
            .catch((error) => {
                return error.response
            })

        dispatch({
            type: "GET_ADMIT_CARD",
            payload: response,
        })
    }
}

export const getPersonalDetails = (id) => {
    return async (dispatch) => {
        const url = `/auth/personal-details/${id}`
        const response = await api
            .get(url)
            .then((res) => {
                return res
            })
            .catch((error) => {
                return error.response
            })

        dispatch({
            type: "GET_PERSONAL_DETAILS",
            payload: response,
        })
    }
}

export const getBasicDetails = (id) => {
    return async (dispatch) => {
        const url = `/auth/basic-details/${id}`
        const response = await api
            .get(url)
            .then((res) => {
                return res
            })
            .catch((error) => {
                return error.response
            })

        dispatch({
            type: "GET_BASIC_DETAILS",
            payload: response,
        })
    }
}

export const getPaymentDetails = (id) => {
    return async (dispatch) => {
        const url = `/auth/payment-details/${id}`
        const response = await api
            .get(url)
            .then((res) => {
                return res
            })
            .catch((error) => {
                return error.response
            })

        dispatch({
            type: "GET_PAYMENT_DETAILS",
            payload: response,
        })
    }
}

export const getAdmissionPaymentDetails = (id) => {
    return async (dispatch) => {
        const url = `/auth/admission-payment-details/${id}`
        const response = await api
            .get(url)
            .then((res) => {
                return res
            })
            .catch((error) => {
                return error.response
            })

        dispatch({
            type: "GET_ADMISSION_PAYMENT_DETAILS",
            payload: response,
        })
    }
}

export const noFeeApplication = (id) => {
    return async (dispatch) => {
        const url = `/auth/no-fee-application/${id}`
        const token = getCookie("userToken")
        const response = await api
            .get(url, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                return res
            })
            .catch((error) => {
                return error.response
            })

        dispatch({
            type: "NO_FEE_COMPLETE_APPLICATION",
            payload: response,
        })
    }
}

const initiatePaymentInterface = (response) => {
    //console.log(response);
    openRazorpayUi(response)
}

const initiateAdmissionPaymentInterface = (response) => {
    //console.log(response);
    openAdmissionRazorpayUi(response)
}

const razorpaySuccess = (values) => {
    return async (dispatch) => {
        const url = `/auth/payment-success`
        var formdata = new FormData()
        const token = getCookie("userToken")

        formdata.append("payment_id", values.razorpay_payment_id)
        formdata.append("order_id", values.razorpay_order_id)
        formdata.append("razorpay_signature", values.razorpay_signature)

        const response = await api
            .post(url, formdata, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                return res
            })
            .catch((error) => {
                return error.response
            })

        dispatch({
            type: "RAZORPAY_PAYMENT_SUCCESSFULL",
            payload: response,
        })
    }
}

const paymentSuccess = () => {
    return {
        type: "RAZORPAY_PAYMENT_SUCCESSFULL",
    }
}

const paymentFailed = () => {
    return {
        type: "RAZORPAY_PAYMENT_FAILED",
    }
}

const openRazorpayUi = (values) => {
    var options = {
        key: values.key,
        amount: values.amount,
        currency: "INR",
        name: values.institute_name,
        description: "Online Fee Payment",
        image: values.logo,
        order_id: values.order_id,
        handler: async (values) => {
            // alert(response.razorpay_payment_id);
            // alert(response.razorpay_order_id);
            // alert(response.razorpay_signature);

            //razorpaySuccess(response);

            //dispatch(paymentSuccess());

            // return {
            //     type: "RAZORPAY_PAYMENT_SUCCESSFULL"
            // };

            document.querySelector("#payment-status").classList.remove("hidden")
            document.querySelector("#payment-status").classList.add("visible")

            const url = `/auth/payment-success`
            var formdata = new FormData()
            const token = getCookie("userToken")

            formdata.append("payment_id", values.razorpay_payment_id)
            formdata.append("order_id", values.razorpay_order_id)
            formdata.append("razorpay_signature", values.razorpay_signature)

            const response = await api
                .post(url, formdata, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                })
                .then((res) => {
                    window.location.href = "/apply/payment-successfull"

                    return res
                })
                .catch((error) => {
                    //window.location.href = "/apply/payment-failed";
                    return error.response
                })

            // return dispatch => {

            //     dispatch({
            //         type: "RAZORPAY_PAYMENT_SUCCESSFULL",
            //     });

            // }
        },
        prefill: {
            name: values.name,
            email: values.email,
            contact: values.mobile,
        },
        notes: {
            Course: values.course_name,
            Department: values.department_name,
            Caste: values.caste,
            Name: values.name,
            "Application ID": values.application_id,
        },
        theme: {
            color: "#F37254",
        },
    }
    var rzp1 = new window.Razorpay(options)
    rzp1.on("payment.failed", function (response) {
        // alert(response.error.code);
        alert(response.error.description)
        // alert(response.error.source);
        // alert(response.error.step);
        // alert(response.error.reason);
        // alert(response.error.metadata);

        //dispatch(paymentFailed());

        // return dispatch => {
        //     dispatch({
        //         type: "RAZORPAY_PAYMENT_FAILED"
        //     });
        // }

        //window.location.href = "/apply/payment-failed";
    })

    rzp1.open()
}

const openAdmissionRazorpayUi = (values) => {
    var options = {
        key: values.key,
        amount: values.amount,
        currency: "INR",
        name: values.institute_name,
        description: "Online Fee Payment",
        image: values.logo,
        order_id: values.order_id,
        handler: async (values) => {
            // alert(response.razorpay_payment_id);
            // alert(response.razorpay_order_id);
            // alert(response.razorpay_signature);

            //razorpaySuccess(response);

            //dispatch(paymentSuccess());

            // return {
            //     type: "RAZORPAY_PAYMENT_SUCCESSFULL"
            // };

            document.querySelector("#payment-status").classList.remove("hidden")
            document.querySelector("#payment-status").classList.add("visible")

            const url = `/auth/admissionfee-payment-success`
            var formdata = new FormData()
            const token = getCookie("userToken")

            formdata.append("payment_id", values.razorpay_payment_id)
            formdata.append("order_id", values.razorpay_order_id)
            formdata.append("razorpay_signature", values.razorpay_signature)

            const response = await api
                .post(url, formdata, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                })
                .then((res) => {
                    window.location.href = "/apply/payment-successfull"

                    return res
                })
                .catch((error) => {
                    //window.location.href = "/apply/payment-failed";
                    return error.response
                })

            // return dispatch => {

            //     dispatch({
            //         type: "RAZORPAY_PAYMENT_SUCCESSFULL",
            //     });

            // }
        },
        prefill: {
            name: values.name,
            email: values.email,
            contact: values.mobile,
        },
        notes: {
            Course: values.course_name,
            Department: values.department_name,
            Name: values.name,
            "Application ID": values.application_id,
        },
        theme: {
            color: "#F37254",
        },
    }
    var rzp1 = new window.Razorpay(options)
    rzp1.on("payment.failed", function (response) {
        // alert(response.error.code);
        alert(response.error.description)
        // alert(response.error.source);
        // alert(response.error.step);
        // alert(response.error.reason);
        // alert(response.error.metadata);

        //dispatch(paymentFailed());

        // return dispatch => {
        //     dispatch({
        //         type: "RAZORPAY_PAYMENT_FAILED"
        //     });
        // }

        //window.location.href = "/apply/payment-failed";
    })

    rzp1.open()
}

const failedPaymentInitiate = (response) => {
    console.log(response)
}

export const createPaymentOrder = (id) => {
    return async (dispatch) => {
        dispatch(startSubmitting())
        const url = `/auth/create-payment-order/${id}`
        const token = getCookie("userToken")
        const response = await api
            .get(url, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                if (res.data.pg == "razorpay") {
                    initiatePaymentInterface(res.data)
                } else if (res.data.pg == "ccavenue") {
                    window.location.href = `${baseURL2}/payment/summary/${res.data.transaction_id}`
                }

                return res
            })
            .catch((error) => {
                failedPaymentInitiate(error.response)
                alert(error.response)
                return error.response
            })

        dispatch({
            type: "CREATE_PAYMENT_ORDER",
            payload: response,
        })
        dispatch(stopSubmitting())
    }
}

export const createAdmissionPaymentOrder = (id) => {
    return async (dispatch) => {
        dispatch(startSubmitting())
        const url = `/auth/create-admission-payment-order/${id}`
        const token = getCookie("userToken")
        const response = await api
            .get(url, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                if (res.data.pg == "razorpay") {
                    initiateAdmissionPaymentInterface(res.data)
                } else if (res.data.pg == "ccavenue") {
                    window.location.href = `${baseURL2}/payment/summary/${res.data.transaction_id}`
                }
                return res
            })
            .catch((error) => {
                failedPaymentInitiate(error.response)
                return error.response
            })

        dispatch({
            type: "CREATE_PAYMENT_ORDER",
            payload: response,
        })
        dispatch(stopSubmitting())
    }
}

const formatDate = (date) => {
    let year = date.getFullYear()
    let month = date.getMonth() + 1
    var months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ]
    let day = date.getDate()
    let monthName = months[date.getMonth()]

    return { day, month, year, monthName }
}

export const applyPersonalDetails = (formValues, actions) => {
    return async (dispatch) => {
        dispatch(startSubmitting())
        const url = `/auth/apply/personal-details`
        var formdata = new FormData()
        const token = getCookie("userToken")

        const birthDate = formatDate(formValues.dob)

        formdata.append("father", formValues.father)
        formdata.append("mother", formValues.mother)
        formdata.append("gender", formValues.gender)
        formdata.append(
            "dob",
            `${birthDate.year}-${birthDate.month}-${birthDate.day}`
        )
        formdata.append("religion", formValues.religion)
        formdata.append("other_religion", formValues.other_religion)
        formdata.append("blood_group", formValues.blood_group)
        formdata.append(
            "permanent_adr_line_1",
            formValues.permanent_adr.adr_line_1
        )
        formdata.append(
            "permanent_adr_line_2",
            formValues.permanent_adr.adr_line_2
        )
        formdata.append("permanent_country", formValues.permanent_adr.country)
        formdata.append("permanent_state", formValues.permanent_adr.state)
        formdata.append("permanent_city", formValues.permanent_adr.city)
        formdata.append("permanent_pincode", formValues.permanent_adr.pincode)
        formdata.append("present_adr_line_1", formValues.present_adr.adr_line_1)
        formdata.append("present_adr_line_2", formValues.present_adr.adr_line_2)
        formdata.append("present_country", formValues.present_adr.country)
        formdata.append("present_state", formValues.present_adr.state)
        formdata.append("present_city", formValues.present_adr.city)
        formdata.append("present_pincode", formValues.present_adr.pincode)
        formdata.append("steps", formValues.steps)
        formdata.append("status", formValues.status)
        formdata.append("application_id", formValues.application_id)
        formdata.append("image", formValues.image)
        formdata.append("signature", formValues.signature)
        formdata.append("aadhaar_no", formValues.aadhaar_no)
        formdata.append("aadhaar_front", formValues.aadhaar_front)
        formdata.append("aadhaar_back", formValues.aadhaar_back)

        const response = await api
            .post(url, formdata, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                return res
            })
            .catch((error) => {
                actions.setErrors(error.response.data.errors)
                return error.response
            })

        dispatch({
            type: "SUBMIT_PERSONAL_DETAILS",
            payload: response,
        })
        dispatch(stopSubmitting())
    }
}

export const applyBasicDetails = (formValues, actions) => {
    return async (dispatch) => {
        dispatch(startSubmitting())
        const url = `/auth/apply/basic-details`
        var formdata = new FormData()
        const token = getCookie("userToken")

        //const birthDate = formatDate(formValues.dob);

        let community_issue_date
        let community_ncl_date

        if (parseInt(formValues.community.name) === 2) {
            const community_ncl_date_of_issue = formatDate(
                formValues.community.ncl_date_of_issue
            )
            community_ncl_date = `${community_ncl_date_of_issue.year}-${community_ncl_date_of_issue.month}-${community_ncl_date_of_issue.day}`
        } else {
            community_ncl_date = null
        }

        if (formValues.community.name > 1) {
            const community_date_of_issue = formatDate(
                formValues.community.date_of_issue
            )
            community_issue_date = `${community_date_of_issue.year}-${community_date_of_issue.month}-${community_date_of_issue.day}`
        } else {
            community_issue_date = null
        }

        formdata.append("community_name", formValues.community.name)
        formdata.append("application_id", formValues.application_id)
        formdata.append("community_caste", isEmpty(formValues.community.caste))
        formdata.append(
            "community_certificate",
            isEmpty(formValues.community.certificate)
        )
        formdata.append(
            "community_date_of_issue",
            isEmpty(community_issue_date)
        )
        formdata.append(
            "community_authority",
            isEmpty(formValues.community.authority)
        )
        formdata.append(
            "community_ncl_certificate_no",
            isEmpty(formValues.community.ncl_certificate_no)
        )
        formdata.append(
            "community_ncl_date_of_issue",
            isEmpty(community_ncl_date)
        )
        formdata.append(
            "community_ncl_authority",
            isEmpty(formValues.community.ncl_authority)
        )

        let ews_issue_date
        if (formValues.community.ews === "Y") {
            const ews_date_of_issue = formatDate(
                formValues.community.ews_date_of_issue
            )
            ews_issue_date = `${ews_date_of_issue.year}-${ews_date_of_issue.month}-${ews_date_of_issue.day}`
        } else {
            ews_issue_date = null
        }

        formdata.append("community_ews", formValues.community.ews)
        formdata.append("ews_certificate", formValues.community.ews_certificate)
        formdata.append("ews_date_of_issue", ews_issue_date)
        formdata.append("ews_authority", formValues.community.ews_authority)

        let serviceman_issue_date
        if (formValues.serviceman.serviceman === "Y") {
            const serviceman_date_of_issue = formatDate(
                formValues.serviceman.date_of_issue
            )
            serviceman_issue_date = `${serviceman_date_of_issue.year}-${serviceman_date_of_issue.month}-${serviceman_date_of_issue.day}`
        } else {
            serviceman_issue_date = null
        }

        formdata.append("serviceman", formValues.serviceman.serviceman)
        formdata.append(
            "serviceman_certificate",
            formValues.serviceman.certificate
        )
        formdata.append("serviceman_date_of_issue", serviceman_issue_date)
        formdata.append("serviceman_authority", formValues.serviceman.authority)
        formdata.append(
            "serviceman_designation",
            formValues.serviceman.designation
        )

        let disability_issue_date
        if (formValues.disability.disable === "Y") {
            const disability_date_of_issue = formatDate(
                formValues.disability.date_of_issue
            )
            disability_issue_date = `${disability_date_of_issue.year}-${disability_date_of_issue.month}-${disability_date_of_issue.day}`
        } else {
            disability_issue_date = null
        }

        formdata.append("disability", formValues.disability.disable)
        formdata.append(
            "disability_certificate",
            formValues.disability.certificate
        )
        formdata.append("disability_date_of_issue", disability_issue_date)
        formdata.append(
            "disability_percentage",
            formValues.disability.percentage
        )
        formdata.append(
            "disability_description",
            formValues.disability.description
        )

        let supernumerary_issue_date
        if (formValues.supernumerary.supernumerary === "Y") {
            const supernumerary_date_of_issue = formatDate(
                formValues.supernumerary.date_of_issue
            )
            supernumerary_issue_date = `${supernumerary_date_of_issue.year}-${supernumerary_date_of_issue.month}-${supernumerary_date_of_issue.day}`
        } else {
            supernumerary_issue_date = null
        }

        formdata.append("supernumerary", formValues.supernumerary.supernumerary)
        formdata.append(
            "supernumerary_certificate",
            formValues.supernumerary.certificate
        )
        formdata.append("supernumerary_date_of_issue", supernumerary_issue_date)
        formdata.append(
            "supernumerary_authority",
            formValues.supernumerary.authority
        )
        formdata.append(
            "supernumerary_designation",
            formValues.supernumerary.designation
        )
        formdata.append(
            "supernumerary_department",
            formValues.supernumerary.department
        )
        formdata.append(
            "supernumerary_relation",
            formValues.supernumerary.relation
        )

        let sports_issue_date
        if (formValues.sports.quota === "Y") {
            const sports_date_of_issue = formatDate(
                formValues.sports.date_of_issue
            )
            sports_issue_date = `${sports_date_of_issue.year}-${sports_date_of_issue.month}-${sports_date_of_issue.day}`
        } else {
            sports_issue_date = null
        }

        const sportsData = {
            quota: formValues.sports.quota,
            name: formValues.sports.name,
            level: formValues.sports.level,
            certificate: formValues.sports.certificate,
            date_of_issue: sports_issue_date,
            authority: formValues.sports.authority,
        }

        formdata.append("sports", JSON.stringify(sportsData))

        let kashmiri_issue_date
        if (formValues.kashmiri.kashmiri === "Y") {
            const kashmiri_date_of_issue = formatDate(
                formValues.kashmiri.date_of_issue
            )
            kashmiri_issue_date = `${kashmiri_date_of_issue.year}-${kashmiri_date_of_issue.month}-${kashmiri_date_of_issue.day}`
        } else {
            kashmiri_issue_date = null
        }

        const kashmiriData = {
            kashmiri: formValues.kashmiri.kashmiri,
            name: formValues.kashmiri.name,
            relationship: formValues.kashmiri.relationship,
            certificate: formValues.kashmiri.certificate,
            date_of_issue: kashmiri_issue_date,
            authority: formValues.kashmiri.authority,
        }

        formdata.append("kashmiri", JSON.stringify(kashmiriData))

        let ncc_issue_date
        if (formValues.ncc.ncc === "Y") {
            const ncc_date_of_issue = formatDate(formValues.ncc.date_of_issue)
            ncc_issue_date = `${ncc_date_of_issue.year}-${ncc_date_of_issue.month}-${ncc_date_of_issue.day}`
        } else {
            ncc_issue_date = null
        }

        const nccData = {
            ncc: formValues.ncc.ncc,
            category: formValues.ncc.category,
            certificate: formValues.ncc.certificate,
            date_of_issue: ncc_issue_date,
            authority: formValues.ncc.authority,
        }

        formdata.append("ncc", JSON.stringify(nccData))

        let nss_issue_date
        if (formValues.nss.nss === "Y") {
            const nss_date_of_issue = formatDate(formValues.nss.date_of_issue)
            nss_issue_date = `${nss_date_of_issue.year}-${nss_date_of_issue.month}-${nss_date_of_issue.day}`
        } else {
            nss_issue_date = null
        }

        let nic_issue_date
        if (formValues.nss.nic === "Y") {
            const nic_date_of_issue = formatDate(
                formValues.nss.nic_date_of_issue
            )
            nic_issue_date = `${nic_date_of_issue.year}-${nic_date_of_issue.month}-${nic_date_of_issue.day}`
        } else {
            nic_issue_date = null
        }

        let rdc_issue_date
        if (formValues.nss.rdc === "Y") {
            const rdc_date_of_issue = formatDate(
                formValues.nss.rdc_date_of_issue
            )
            rdc_issue_date = `${rdc_date_of_issue.year}-${rdc_date_of_issue.month}-${rdc_date_of_issue.day}`
        } else {
            rdc_issue_date = null
        }

        let camp_issue_date
        if (formValues.nss.camp === "Y") {
            const camp_date_of_issue = formatDate(
                formValues.nss.camp_date_of_issue
            )
            camp_issue_date = `${camp_date_of_issue.year}-${camp_date_of_issue.month}-${camp_date_of_issue.day}`
        } else {
            camp_issue_date = null
        }

        const nssData = {
            nss: formValues.nss.nss,
            category: formValues.nss.category,
            certificate: formValues.nss.certificate,
            date_of_issue: nss_issue_date,
            authority: formValues.nss.authority,
            nic: formValues.nss.nic,
            nic_certificate: formValues.nss.nic_certificate,
            nic_date_of_issue: nic_issue_date,
            nic_authority: formValues.nss.nic_authority,
            rdc: formValues.nss.rdc,
            rdc_certificate: formValues.nss.rdc_certificate,
            rdc_date_of_issue: rdc_issue_date,
            rdc_authority: formValues.nss.rdc_authority,
            camp: formValues.nss.camp,
            camp_certificate: formValues.nss.camp_certificate,
            camp_date_of_issue: camp_issue_date,
            camp_authority: formValues.nss.camp_authority,
        }

        formdata.append("nss", JSON.stringify(nssData))

        const response = await api
            .post(url, formdata, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                return res
            })
            .catch((error) => {
                actions.setErrors(error.response.data.errors)
                return error.response
            })

        dispatch({
            type: "SUBMIT_BASIC_DETAILS",
            payload: response,
        })
        dispatch(stopSubmitting())
    }
}

// check value is null or undefined
function isEmpty(value) {
    if (value == undefined || value == null) {
        return ""
    } else {
        return value
    }
}

//Save Education
export const submitEducationQualification = (formValues, actions) => {
    return async (dispatch) => {
        dispatch(startSubmitting())
        const url = `/auth/apply/education-qualification`
        var formdata = new FormData()
        const token = getCookie("userToken")

        formdata.append("edu", JSON.stringify(formValues.edu))
        formdata.append("application_id", formValues.application_id)

        const response = await api
            .post(url, formdata, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                actions.resetForm({})
                return res
            })
            .catch((error) => {
                actions.setErrors(error.response.data.errors)
                return error.response
            })

        dispatch({
            type: "SUBMIT_EDUCATION_QUALIFICATION",
            payload: response,
        })
        dispatch(stopSubmitting())
    }
}

export const getEducationDetails = (id) => {
    return async (dispatch) => {
        const url = `/auth/education-details/${id}`
        const response = await api
            .get(url)
            .then((res) => {
                return res
            })
            .catch((error) => {
                return error.response
            })

        dispatch({
            type: "GET_EDUCATION_DETAILS",
            payload: response,
        })
    }
}

export const getCmsPage = (slug) => {
    return async (dispatch) => {
        const url = `/cms-page/${slug}`
        const response = await api
            .get(url)
            .then((res) => {
                return res
            })
            .catch((error) => {
                return error.response
            })

        dispatch({
            type: "GET_CMS_PAGE_DETAILS",
            payload: response,
        })
    }
}

//Remove Education Qualification
export const removeEducationQualification = (id, application_id) => {
    return async (dispatch) => {
        dispatch(startSubmitting())
        var formdata = new FormData()
        const token = getCookie("userToken")
        const url = `/auth/remove-qualification`

        formdata.append("id", id)
        formdata.append("application_id", application_id)

        const response = await api
            .post(url, formdata, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                return res
            })
            .catch((error) => {
                return error.response
            })

        dispatch({
            type: "REMOVE_EDUCATION_QUALIFICATION",
            payload: response,
        })
        dispatch(stopSubmitting())
    }
}

export const editQualification = (id) => {
    return {
        type: "EDIT_QUALIFICATION",
        payload: id,
    }
}

//Save Education
export const editEducationQualification = (formValues, actions, id) => {
    return async (dispatch) => {
        dispatch(startSubmitting())
        const url = `/auth/apply/edit-education-qualification/${id}`
        var formdata = new FormData()
        const token = getCookie("userToken")

        formdata.append("edu", JSON.stringify(formValues.edu))
        formdata.append("application_id", formValues.application_id)

        const response = await api
            .post(url, formdata, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                actions.resetForm({})
                return res
            })
            .catch((error) => {
                actions.setErrors(error.response.data.errors)
                return error.response
            })

        dispatch({
            type: "EDIT_EDUCATION_QUALIFICATION",
            payload: response,
        })
        dispatch(stopSubmitting())
    }
}

export const submitEduPageDetails = (formValues, actions) => {
    return async (dispatch) => {
        dispatch(startSubmitting())
        const url = `/auth/apply/education-details`
        var formdata = new FormData()
        const token = getCookie("userToken")

        //let net_array = [];

        const set_array =
            formValues.course.net === "Y" &&
            formValues.course.net_qualifications &&
            formValues.course.net_qualifications.length > 0
                ? formValues.course.net_qualifications.map((row) => {
                      let selected_date = row.date_of_issue

                      let formattedDate = formatDate(selected_date)

                      //net_array.push({ date_of_issue: `${formattedDate.year}-${formattedDate.month}-${formattedDate.day}`, qualification: row.qualification, certificate: row.certificate, authority: row.authority });
                      formdata.append(
                          "net_date[]",
                          `${formattedDate.year}-${formattedDate.month}-${formattedDate.day}`
                      )
                      formdata.append("net_qualification[]", row.qualification)
                      formdata.append("net_certificate[]", row.certificate)
                      formdata.append("net_authority[]", row.authority)
                  })
                : null

        let employment_noc_date

        if (formValues.employment.noc === "Y") {
            let nov_Date = formatDate(formValues.employment.noc_date_of_issue)
            employment_noc_date = `${nov_Date.year}-${nov_Date.month}-${nov_Date.day}`
        } else {
            employment_noc_date = null
        }

        const nocData = {
            employed: formValues.employment.employed,
            employer: formValues.employment.employer,
            designation: formValues.employment.designation,
            status: formValues.employment.status,
            noc: formValues.employment.noc,
            noc_certificate: formValues.employment.noc_certificate
                ? formValues.employment.noc_certificate
                : "",
            noc_date_of_issue: employment_noc_date,
            noc_authority: formValues.employment.noc_authority
                ? formValues.employment.noc_authority
                : "",
        }

        formdata.append("student", JSON.stringify(formValues.student))
        formdata.append("employment", JSON.stringify(nocData))
        formdata.append("gate", JSON.stringify(formValues.course.gate))
        formdata.append("application_id", formValues.application_id)
        formdata.append("exam_center", formValues.course.exam_center)
        formdata.append("net", formValues.course.net)
        formdata.append("bed_discipline", formValues.course.bed_discipline)
        formdata.append("preference_one", formValues.course.one)
        formdata.append("preference_two", formValues.course.two)
        formdata.append("major", formValues.course.major)
        formdata.append("ba_major", formValues.course.ba_major)
        //formdata.append('net_qualifications', JSON.stringify(net_array));

        const response = await api
            .post(url, formdata, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                return res
            })
            .catch((error) => {
                actions.setErrors(error.response.data.errors)
                return error.response
            })

        dispatch({
            type: "SUBMIT_EDU_PAGE_DETAILS",
            payload: response,
        })
        dispatch(stopSubmitting())
    }
}

export const finalApplySubmit = (formValues, actions) => {
    return async (dispatch) => {
        dispatch(startSubmitting())
        const url = `/auth/apply/final-submit`
        var formdata = new FormData()
        const token = getCookie("userToken")

        formdata.append("application_id", formValues.application_id)

        const response = await api
            .post(url, formdata, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                return res
            })
            .catch((error) => {
                actions.setErrors(error.response.data.errors)
                return error.response
            })

        dispatch({
            type: "FINAL_SUBMIT_APPLY",
            payload: response,
        })
        dispatch(stopSubmitting())
    }
}

export const fetchMyApplications = () => {
    return async (dispatch) => {
        const url = `/auth/my-applications`
        var formdata = new FormData()
        const token = getCookie("userToken")

        const response = await api
            .get(url, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                return res
            })
            .catch((error) => {
                return error.response
            })

        dispatch({
            type: "FETCH_MY_APLICATIONS",
            payload: response,
        })
    }
}

export const getMeritMarksheet = (id) => {
    return async (dispatch) => {
        const url = `/auth/get-merit-docs/${id}`
        const token = getCookie("userToken")

        const response = await api
            .get(url, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                return res
            })
            .catch((error) => {
                return error.response
            })

        dispatch({
            type: "GET_MERIT_DOCS",
            payload: response,
        })
    }
}

export const submitMeritDocs = (formValues, actions) => {
    return async (dispatch) => {
        dispatch(startSubmitting())
        const url = `/auth/submit-merit-docs`
        var formdata = new FormData()
        const token = getCookie("userToken")

        formdata.append("caste", isEmpty(formValues.caste))
        formdata.append("marksheet", isEmpty(formValues.marksheet))
        formdata.append("kashmiri", isEmpty(formValues.kashmiri))
        formdata.append("ncc_certificate", isEmpty(formValues.ncc_certificate))
        formdata.append("nss_certificate", isEmpty(formValues.nss_certificate))
        formdata.append("serviceman", isEmpty(formValues.serviceman))
        formdata.append("supernumerary", isEmpty(formValues.supernumerary))
        formdata.append("tenth", isEmpty(formValues.tenth))
        formdata.append("twelfth", isEmpty(formValues.twelfth))
        formdata.append("application_id", formValues.application_id)

        const response = await api
            .post(url, formdata, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                actions.resetForm({})
                return res
            })
            .catch((error) => {
                actions.setErrors(error.response.data.errors)
                return error.response
            })

        dispatch({
            type: "UPLOAD_MERIT_DOCS",
            payload: response,
        })
        dispatch(stopSubmitting())
    }
}

export const fetchNotices = (limit) => {
    return async (dispatch) => {
        const url = `/notices-links/${limit}`
        const response = await api
            .get(url)
            .then((res) => {
                return res
            })
            .catch((error) => {
                return error.response
            })

        dispatch({
            type: "FETCH_NOTICES_LINKS",
            payload: response,
        })
    }
}

export const fetchCourseDetails = (department, course) => {
    return async (dispatch) => {
        dispatch(startLoading())
        if (course === null || course === undefined || course == "") {
            course = "undefined"
        }
        const url = `/course-details/${department}/${course}`
        const response = await api
            .get(url)
            .then((res) => {
                return res
            })
            .catch((error) => {
                return error.response
            })

        dispatch({
            type: "FETCH_COURSE_DETAILS",
            payload: response,
        })
        dispatch(stopLoading())
    }
}

export const findNotices = (department, course, limit) => {
    return async (dispatch) => {
        dispatch(startLoading())
        if (course === null || course === undefined || course == "") {
            course = "undefined"
        }
        const url = `/notices/${department}/${course}/${limit}`
        const response = await api
            .get(url)
            .then((res) => {
                return res
            })
            .catch((error) => {
                return error.response
            })

        dispatch({
            type: "FIND_NOTICES",
            payload: response,
        })
        dispatch(stopLoading())
    }
}

export const fetchFaq = () => {
    return async (dispatch) => {
        const url = `/faq`
        const response = await api
            .get(url)
            .then((res) => {
                return res
            })
            .catch((error) => {
                return error.response
            })

        dispatch({
            type: "FETCH_FAQ",
            payload: response,
        })
    }
}

export const fetchQuestionPapers = () => {
    return async (dispatch) => {
        const url = `/question-papers`
        const response = await api
            .get(url)
            .then((res) => {
                return res
            })
            .catch((error) => {
                return error.response
            })

        dispatch({
            type: "FETCH_QUESTION_PAPERS",
            payload: response,
        })
    }
}

export const findQuestionPapers = (department, course) => {
    return async (dispatch) => {
        dispatch(startLoading())
        if (course === null || course === undefined || course == "") {
            course = "undefined"
        }
        const url = `/filter-question-papers/${department}/${course}`
        const response = await api
            .get(url)
            .then((res) => {
                return res
            })
            .catch((error) => {
                return error.response
            })

        dispatch({
            type: "FIND_QUESTION_PAPERS",
            payload: response,
        })
        dispatch(stopLoading())
    }
}

export const fetchSyllabuses = () => {
    return async (dispatch) => {
        const url = `/syllabuses`
        const response = await api
            .get(url)
            .then((res) => {
                return res
            })
            .catch((error) => {
                return error.response
            })

        dispatch({
            type: "FETCH_SYLLABUSES",
            payload: response,
        })
    }
}

export const filterSyllabuses = (department, course) => {
    return async (dispatch) => {
        dispatch(startLoading())
        if (course === null || course === undefined || course == "") {
            course = "undefined"
        }
        if (
            department === null ||
            department === undefined ||
            department == ""
        ) {
            department = "undefined"
        }
        const url = `/filter-syllabuses/${department}/${course}`
        const response = await api
            .get(url)
            .then((res) => {
                return res
            })
            .catch((error) => {
                return error.response
            })

        dispatch({
            type: "FIND_SYLLABUSES",
            payload: response,
        })
        dispatch(stopLoading())
    }
}

export const fetchSingleLinks = () => {
    return async (dispatch) => {
        const url = `/single-links`
        const response = await api
            .get(url)
            .then((res) => {
                return res
            })
            .catch((error) => {
                return error.response
            })

        dispatch({
            type: "FETCH_SINGLE_LINKS",
            payload: response,
        })
    }
}

export const fetchExamCenters = () => {
    return async (dispatch) => {
        const url = `/exam-centers`
        const response = await api
            .get(url)
            .then((res) => {
                return res
            })
            .catch((error) => {
                return error.response
            })

        dispatch({
            type: "FETCH_EXAM_CENTERS",
            payload: response,
        })
    }
}

export const sendResetOTP = (formValues, actions) => {
    return async (dispatch) => {
        dispatch(startSubmitting())
        const url = `/reset-otp`
        var formdata = new FormData()

        formdata.append("mobile", formValues.mobile)

        const response = await api
            .post(url, formdata)
            .then((res) => {
                return res
            })
            .catch((error) => {
                actions.setErrors(error.response.data.errors)
                return error.response
            })

        dispatch({
            type: "SEND_RESET_OTP",
            payload: response,
        })
        dispatch(stopSubmitting())
    }
}

export const verifyResetOTP = (formValues, actions) => {
    return async (dispatch) => {
        dispatch(startSubmitting())
        const url = `/verify-reset-otp`
        var formdata = new FormData()

        formdata.append("mobile", formValues.mobile)
        formdata.append("otp", formValues.otp)

        const response = await api
            .post(url, formdata)
            .then((res) => {
                return res
            })
            .catch((error) => {
                actions.setErrors(error.response.data.errors)
                return error.response
            })

        dispatch({
            type: "VERIFY_RESET_OTP",
            payload: response,
        })
        dispatch(stopSubmitting())
    }
}

export const changePassword = (formValues, actions) => {
    return async (dispatch) => {
        dispatch(startSubmitting())
        const url = `/change-password`
        var formdata = new FormData()

        formdata.append("mobile", formValues.mobile)
        formdata.append("password", formValues.password)
        formdata.append(
            "password_confirmation",
            formValues.password_confirmation
        )

        const response = await api
            .post(url, formdata)
            .then((res) => {
                return res
            })
            .catch((error) => {
                actions.setErrors(error.response.data.errors)
                return error.response
            })

        dispatch({
            type: "CHANGE_PASSWORD",
            payload: response,
        })
        dispatch(stopSubmitting())
    }
}

export const sendInternalMessage = (formValues, actions) => {
    return async (dispatch) => {
        dispatch(startSubmitting())
        const url = `/auth/send-internal-message`
        var formdata = new FormData()
        const token = getCookie("userToken")

        formdata.append("department", formValues.department)
        formdata.append("course", formValues.course)
        formdata.append("category", formValues.category)
        formdata.append("sub_category", formValues.sub_category)
        formdata.append("message", formValues.message)
        formdata.append("file", formValues.file)

        const response = await api
            .post(url, formdata, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                actions.resetForm({})
                return res
            })
            .catch((error) => {
                actions.setErrors(error.response.data.errors)
                return error.response
            })

        dispatch({
            type: "SEND_INTERNAL_MESSAGE",
            payload: response,
        })
        dispatch(stopSubmitting())
    }
}

export const getMyIssues = () => {
    return async (dispatch) => {
        const url = `/auth/get-my-issues`
        const token = getCookie("userToken")

        const response = await api
            .get(url, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                return res
            })
            .catch((error) => {
                return error.response
            })

        dispatch({
            type: "GET_MY_ISSUES",
            payload: response,
        })
    }
}

export const boardNames = () => {
    return async (dispatch) => {
        const url = `/board-names`
        const response = await api
            .get(url)
            .then((res) => {
                return res
            })
            .catch((error) => {
                return error.response
            })

        dispatch({
            type: "FETCH_BOARD_NAMES",
            payload: response,
        })
    }
}

export const resendSMS = (mobile) => {
    return async (dispatch) => {
        dispatch(startSubmitting())
        const url = `/resend-sms`
        var formdata = new FormData()

        formdata.append("mobile", mobile)
        const response = await api
            .post(url, formdata)
            .then((res) => {
                return res
            })
            .catch((error) => {
                return error.response
            })

        dispatch({
            type: "RESEND_OTP",
            payload: response,
        })
        dispatch(stopSubmitting())
    }
}

export const resendEmail = (email, mobile) => {
    return async (dispatch) => {
        dispatch(startSubmitting())
        const url = `/resend-email-otp`
        var formdata = new FormData()

        formdata.append("mobile", mobile)
        formdata.append("email", email)
        const response = await api
            .post(url, formdata)
            .then((res) => {
                return res
            })
            .catch((error) => {
                //actions.setErrors(error.response.data.errors);
                return error.response
            })

        dispatch({
            type: "RESEND_EMAIL_OTP",
            payload: response,
        })
        dispatch(stopSubmitting())
    }
}

export const resendResetOTP = (email) => {
    return async (dispatch) => {
        dispatch(startSubmitting())
        const url = `/resend-reset-otp`
        var formdata = new FormData()

        formdata.append("email", email)

        const response = await api
            .post(url, formdata)
            .then((res) => {
                return res
            })
            .catch((error) => {
                //actions.setErrors(error.response.data.errors);
                return error.response
            })

        dispatch({
            type: "RESEND_RESET_OTP",
            payload: response,
        })
        dispatch(stopSubmitting())
    }
}
