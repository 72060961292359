import React, { Component } from "react"
import { connect } from "react-redux"
import BannerText from "./text/BannerText"
import BannerForm from "./form/BannerForm"

import "./banner.css"
import { baseURL } from "../../includes/jsFunctions"

class Banner extends Component {
    render() {
        const { current_year, institute_name, background } = this.props.settings

        return (
            <section className="banner-section relative z-0">
                <div
                    className="site-banner"
                    style={{
                        backgroundImage: `url('${baseURL}/settings/${background}')`,
                    }}
                >
                    <div className="image-overlay">
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 gap-4 banner-grid">
                            <div className="col-span-1 hidden md:block lg:block xl:block">
                                <div className="banner-text">
                                    <BannerText
                                        year={current_year}
                                        institute={institute_name}
                                    />
                                </div>
                            </div>
                            <div className="col-span-1">
                                <div className="banner-form">
                                    <BannerForm />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        settings: state.commonData.settings,
    }
}

export default connect(mapStateToProps)(Banner)
