import React, { Component } from "react"
import { connect } from "react-redux"
import ImportantLinkItem from "./ImportantLinkItem"

import { fetchSingleLinks, showModal } from "../../../../../actions"
import { baseURL } from "../../../includes/jsFunctions"
import PageModal from "../../../includes/modal/PageModal"
import BulletinForm from "../../../navbar/BulletinForm"

class ImportantLinks extends Component {
    componentDidMount() {
        this.props.fetchSingleLinks()
    }

    showModal = (name) => {
        this.props.showModal(name)
    }

    render() {
        const links = this.props.appData.singleLinks

        return (
            <React.Fragment>
                <div className="important-links-items">
                    {links &&
                        links.length > 0 &&
                        links.map((link, index) => (
                            <ImportantLinkItem
                                key={`${link.title}-${index}`}
                                image={`${baseURL}/important-links/${link.icon}`}
                                type={link.type}
                                link={link.link}
                                file={`${baseURL}/important-links/files/${link.file}`}
                                title={link.title}
                            />
                        ))}
                </div>

                {this.props.modal.modalOpen &&
                this.props.modal.modalName === "bulletinModal2" ? (
                    <PageModal>
                        <BulletinForm />
                    </PageModal>
                ) : null}
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        appData: state.appData,
        modal: state.modal,
    }
}

export default connect(mapStateToProps, { showModal, fetchSingleLinks })(
    ImportantLinks
)
