import React from "react"

import "./facility-item.css"
import { baseURL } from "../../../includes/jsFunctions"

const FacilityItem = ({ image, name }) => {
    return (
        <div className="facility-item mb-4">
            <div className="facility-image mb-4">
                <img src={`${baseURL}/facilities/${image}`} alt={name} />
            </div>
            <div className="facility-name text-center text-white text-lg">
                {name}
            </div>
        </div>
    )
}

export default FacilityItem
