import React, { Component } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

import ApplyBanner from "../../includes/apply-banner/ApplyBanner"
import {
    getPaymentDetails,
    noFeeApplication,
    createPaymentOrder,
} from "../../../../actions"
//import { baseURL, formatDate } from '../../includes/jsFunctions';
import ProgressBar from "../../dashboard/progressbar/ProgressBar"
import LoadingBtn from "../../includes/btn/LoadingBtn"
import { baseURL, staticURI } from "../../includes/jsFunctions"

class Payment extends Component {
    componentDidMount() {
        this.props.getPaymentDetails(this.props.match.params.id)
    }

    noFee = (id) => {
        this.props.noFeeApplication(id)
    }

    withFee = (id) => {
        this.props.createPaymentOrder(id)
    }

    successModal = () => {
        console.log("success")
    }

    render() {
        const { loginLoading, login } = this.props.user
        const { isSubmitting } = this.props.commonFunctions
        const { paymentData, payment } = this.props.apply
        const {
            community_details,
            course,
            fname,
            mname,
            lname,
            mobile,
            email,
            application_id,
            transaction,
        } = paymentData

        const { settings } = this.props.commonData

        if (!loginLoading && !login) {
            window.location.href = `${staticURI}/`
        }

        if (
            paymentData.status === "Y" &&
            transaction &&
            transaction.status === "Y"
        ) {
            window.location.href = `${staticURI}/dashboard`
        }

        if (payment || paymentData.payment === "Y") {
            window.location.href = `${staticURI}/dashboard`
        }

        let community_name, ews, application_fee
        if (paymentData && paymentData.community_details) {
            if (community_details.community === "1") {
                community_name = "General"
                const is_ews = paymentData.ews === "Y" ? `Yes` : `No`
                ews = `, EWS: ${is_ews}`

                if (paymentData.ews === "Y") {
                    application_fee =
                        community_details.community_info.community_fee.find(
                            (fee) => fee.is_ews === "Yes"
                        )
                } else {
                    application_fee =
                        community_details.community_info.community_fee.find(
                            (fee) => fee.is_ews === "No"
                        )
                }
            } else if (community_details.community === "2") {
                community_name = "OBC-NCL"
                application_fee =
                    community_details.community_info.community_fee[0]
            } else if (community_details.community === "3") {
                community_name = "SC"
                application_fee =
                    community_details.community_info.community_fee[0]
            } else if (community_details.community === "4") {
                community_name = "ST"
                application_fee =
                    community_details.community_info.community_fee[0]
            } else if (community_details.community === "5") {
                community_name = "APST"
                application_fee =
                    community_details.community_info.community_fee[0]
            }
        }

        return (
            <React.Fragment>
                <ApplyBanner title="Payment Details" />

                <section
                    className="personal-details-page py-16"
                    style={{ backgroundColor: "#F5FBFF" }}
                >
                    <ProgressBar step="5" />
                    <div className="container mx-auto px-8 mt-32">
                        <div className="form-wrap py-8 bg-white">
                            <div className="text-center mb-4">
                                <img
                                    src={`${baseURL}/settings/${settings.logo}`}
                                    alt="logo"
                                    className="w-24"
                                    style={{ margin: "0 auto" }}
                                />
                                <h1 className="font-medium text-xl">
                                    {settings.institute_name}
                                </h1>
                                <h3>{settings.address}</h3>
                            </div>

                            <div className="px-8">
                                <div className="grid grid-cols-2 mt-1">
                                    <div
                                        className="border border-gray-300 px-4 py-2 h-full"
                                        style={{
                                            display: "flex",
                                            justifyContent: "left",
                                            alignItems: "center",
                                        }}
                                    >
                                        Name
                                    </div>
                                    <div
                                        className="border border-gray-300 px-4 py-2 h-full"
                                        style={{
                                            display: "flex",
                                            justifyContent: "left",
                                            alignItems: "center",
                                        }}
                                    >
                                        {`${fname} ${mname} ${lname}`}
                                    </div>
                                </div>
                                <div className="grid grid-cols-2">
                                    <div
                                        className="border border-gray-300 px-4 py-2 h-full"
                                        style={{
                                            display: "flex",
                                            justifyContent: "left",
                                            alignItems: "center",
                                        }}
                                    >
                                        Mobile: {mobile}
                                    </div>
                                    <div
                                        className="border border-gray-300 px-4 py-2 h-full"
                                        style={{
                                            display: "flex",
                                            justifyContent: "left",
                                            alignItems: "center",
                                        }}
                                    >
                                        Email: {email}
                                    </div>
                                </div>
                                <div className="grid grid-cols-2">
                                    <div
                                        className="border border-gray-300 px-4 py-2 h-full"
                                        style={{
                                            display: "flex",
                                            justifyContent: "left",
                                            alignItems: "center",
                                        }}
                                    >
                                        Course:
                                    </div>
                                    <div
                                        className="border border-gray-300 px-4 py-2 h-full"
                                        style={{
                                            display: "flex",
                                            justifyContent: "left",
                                            alignItems: "center",
                                        }}
                                    >
                                        {paymentData && paymentData.course
                                            ? course.title
                                            : null}
                                    </div>
                                </div>
                                <div className="grid grid-cols-2">
                                    <div
                                        className="border border-gray-300 px-4 py-2 h-full"
                                        style={{
                                            display: "flex",
                                            justifyContent: "left",
                                            alignItems: "center",
                                        }}
                                    >
                                        Caste:
                                    </div>
                                    <div className="border border-gray-300 px-4 py-2">
                                        {community_name}
                                        {ews}
                                    </div>
                                </div>
                                <div className="grid grid-cols-2">
                                    <div
                                        className="border border-gray-300 px-4 py-2 h-full"
                                        style={{
                                            display: "flex",
                                            justifyContent: "left",
                                            alignItems: "center",
                                        }}
                                    >
                                        Application Fee:
                                    </div>
                                    <div className="border border-gray-300 px-4 py-2">
                                        {
                                            application_fee &&
                                            parseInt(application_fee.amount) > 0
                                                ? `Rs.${application_fee.amount}`
                                                : `Free`
                                            //console.log(application_fee)
                                        }
                                    </div>
                                </div>

                                <div className="payment-btns mt-6 flex justify-center">
                                    {
                                        application_fee &&
                                        parseInt(application_fee.amount) > 0 ? (
                                            <button
                                                className="rgu-btn-light"
                                                type="button"
                                                onClick={() =>
                                                    this.withFee(application_id)
                                                }
                                            >
                                                {isSubmitting ? (
                                                    <LoadingBtn />
                                                ) : (
                                                    `Pay Now`
                                                )}
                                            </button>
                                        ) : (
                                            <button
                                                className="rgu-btn-light"
                                                type="button"
                                                onClick={() =>
                                                    this.noFee(application_id)
                                                }
                                            >
                                                Proceed
                                            </button>
                                        )
                                        //console.log(application_fee)
                                    }
                                </div>
                                <div
                                    className="mt-4 text-center text-red-500 font-bold text-lg hidden"
                                    id="payment-status"
                                >
                                    Attention: Your payment is processing.
                                    Please do not close the browser window until
                                    we redirect you.
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        apply: state.apply,
        commonFunctions: state.commonFunctions,
        commonData: state.commonData,
    }
}

export default withRouter(
    connect(mapStateToProps, {
        getPaymentDetails,
        noFeeApplication,
        createPaymentOrder,
    })(Payment)
)
