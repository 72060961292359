import React, { Component } from 'react';
import {Field} from 'formik';
import { getIn } from 'formik';
import { connect } from 'react-redux';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import ErrorMsg from '../../includes/form-inputs/ErrorMsg';

class EwsCategory extends Component {

    myTextInput = ({ name, field, placeholder, type, readonly }) => {

        const disabled = readonly ? true : false;

        return (
            <div className="w-full">
                <label>{placeholder}</label>
                <div className="text-input mt-2">
                    <input placeholder={placeholder} type={type} name={name} className={`appearance-none block w-full py-2 px-3 border border-gray-300 rounded`} {...field} readOnly={disabled} />
                </div>
            </div>
        )
    }

    myRadioInput = ({ text, field, name, checked, value, selected }) => {

        const border = checked ? `border border-blue-200` : `border border-gray-200`;

        //const radioChecked = this.props.apply.stepTwo.ews === 'Y' ? true: false;

        return (
            <div className={`px-1 sm:px-4 custom-radio-btn ${border}`}>
                <label className="container">
                    {text}
                    <input type="radio" name={name} {...field} value={value} checked={ selected === 'Y' } />
                    <span className="checkmark"></span>
                </label>
            </div>
        )
    }

    myRadioInput2 = ({ text, field, name, checked, value, selected }) => {

        const border = checked ? `border border-blue-200` : `border border-gray-200`;

        //const radioChecked = this.props.apply.stepTwo.ews === 'N' ? true: false;

        return (
            <div className={`px-1 sm:px-4 custom-radio-btn ${border}`}>
                <label className="container">
                    {text}
                    <input type="radio" name={name} {...field} value={value} checked={ selected === 'N' } />
                    <span className="checkmark"></span>
                </label>
            </div>
        )
    }

    render() {

        const { props } = this.props;

        return (
            <div className="section-one px-8 py-4 border-b-gray-300">
                <div className="grid grid-cols-1 sm:grid-cols-2 mb-4">
                    <div>
                        <label>Do you belong to EWS category?</label>
                        <div className="grid grid-cols-3 gap-4 mt-2">
                            <Field component={this.myRadioInput} name="community.ews" selected={props.values.community.ews} text="Yes" value="Y"></Field>
                            <Field component={this.myRadioInput2} name="community.ews" selected={props.values.community.ews} text="No" value="N"></Field>

                        </div>
                        {getIn(props.errors, 'community.ews') && getIn(props.touched, 'community.ews') && <ErrorMsg msg={getIn(props.errors, 'community.ews')} />}
                    </div>
                </div>

                {
                    props.values.community.ews === 'Y' ? 
                    (
                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                            <div className="form-input">
                                <Field component={this.myTextInput} name="community.ews_certificate" placeholder="Certificate No" type="text" readonly={false}></Field>
                                {getIn(props.errors, 'community.ews_certificate') && getIn(props.touched, 'community.ews_certificate') && <ErrorMsg msg={getIn(props.errors, 'community.ews_certificate')} />}
                            </div>
                            <div className="form-input">
                                <div className="w-full">
                                    <label>Date Of Issue</label>
                                    <div className="text-input mt-2">
                                        <DatePicker
                                            selected={props.values.community.ews_date_of_issue}
                                            onChange={date => props.setFieldValue("community.ews_date_of_issue", date)}
                                            peekNextMonth
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            dateFormat="dd-MM-yyyy"
                                            className="appearance-none block w-full py-2 px-3 border border-gray-300 rounded"
                                            placeholderText="Click to select a date"
                                        />
                                        {getIn(props.errors, 'community.ews_date_of_issue') && getIn(props.touched, 'community.ews_date_of_issue') && <ErrorMsg msg={getIn(props.errors, 'community.ews_date_of_issue')} />}
                                    </div>
                                </div>
                            </div>
                            <div className="form-input">
                                <Field component={this.myTextInput} name="community.ews_authority" placeholder="Issuing Authority " type="text" readonly={false}></Field>
                                {getIn(props.errors, 'community.ews_authority') && getIn(props.touched, 'community.ews_authority') && <ErrorMsg msg={getIn(props.errors, 'community.ews_authority')} />}
                            </div>
                        </div>
                    ) : null
                }
                
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        apply: state.apply,
    }
}

export default connect(mapStateToProps)(EwsCategory);