import React, { Component } from 'react';
import { connect } from 'react-redux';
import {Field, Form, Formik} from 'formik';

import Sidebar from './Sidebar';
import PageBanner from '../includes/page-banner/PageBanner';
import ErrorMsg from '../includes/form-inputs/ErrorMsg';
import { getIssueCategories, issueSubCategories, getCourses, getDepartments, courseFilter, sendInternalMessage } from '../../../actions';

class Compose extends Component {

    submitIssueFrom = (values, actions) => {
        this.props.sendInternalMessage(values, actions);
    }

    myTextInput = ({ name, field, placeholder, type }) => {
        return (
            <div className="w-full">
                <div className="text-input">
                    <input placeholder={placeholder} type={type} name={name} className={`appearance-none block w-full py-2 px-3 border border-gray-300 rounded`} {...field} />
                </div>
            </div>
        )
    }

    filterValues = (options, department)  => {
        let filteredCourses =  options.filter(function(course) {
            return course.department_id === department;
        });
        this.props.courseFilter(filteredCourses);
    }

    myTextarea = ({ name, field, placeholder }) => {
        return (
            <div className="w-full">
                <div className="text-input">
                    <textarea placeholder={placeholder} name={name} className={`appearance-none block w-full py-2 px-3 border border-gray-300 rounded`} {...field} rows="3"></textarea>
                </div>
            </div>
        )
    }

    mySelectInput = ({ field, name, options }) => {

        const selectOptions = options && options.length > 0 ? options.map(option => {
            return (
                <option value={option.id} key={option.id}>{option.category}</option>
            )
        }) : null;

        return (
            <div className="w-full">
                <div className="select-input">
                    <select name={name} className={`block w-full py-2 px-3 border border-gray-300 rounded`} {...field}>
                        <option value="">Please Select</option>
                        { selectOptions }
                    </select>
                </div>
            </div>
        )
    }

    mySelectInput2 = ({ field, name, options }) => {

        const selectOptions = options && options.length > 0 ? options.map(option => {
            return (
                <option value={option.id} key={option.id}>{option.title}</option>
            )
        }) : null;

        return (
            <div className="w-full">
                <div className="select-input">
                    <select name={name} className={`block w-full py-2 px-3 border border-gray-300 rounded`} {...field}>
                        <option value="">Please Select</option>
                        { selectOptions }
                    </select>
                </div>
            </div>
        )
    }

    validate = values => {
        let errors = {};
        if (!values.department) {
            errors.department = "Please select department";
        }else{
              this.filterCourses(this.props.commonData.courses, values.department);
        }
  
        if (!values.course) {
              errors.course = "Please select course";
        }

        if (!values.category) {
          errors.category = "Please select issue category";
        }else{
            this.filterValues(this.props.commonData.subCategories_copy, values.category);
        }

        if (!values.sub_category) {
            errors.sub_category = "Please select sub category";
        }

        if (!values.message) {
            errors.message = "Please enter your message";
        }

        return errors;
    }

    filterValues = (options, main_cat)  => {
        
        let filteredCats =  options.filter(function(category) {
            return category.main_category === main_cat;
        });

        this.props.issueSubCategories(filteredCats);
    }

    filterCourses = (options, department)  => {
        let filteredCourses =  options.filter(function(course) {
            return course.department_id === department;
        });
        this.props.courseFilter(filteredCourses);
    }

    componentDidMount(){
        this.props.getIssueCategories();
        this.props.getCourses();
        this.props.getDepartments();
    }

    render() {

        const { issueCategories, issueSubCategories, departments, courses_copy } = this.props.commonData;
        const { loginLoading, login } = this.props.user;

        if(!loginLoading && !login){
            window.location.href = "/";
        }

        return (
            <React.Fragment>
                <PageBanner title="Internal Messages" image="/images/page.jpg" />
                <div className="py-16">
                    <div className="container mx-auto px-8">
                        <div className="bg-white shadow-md border border-gray-300 rounded grid grid-cols-5">
                            <div className="col-span-5 sm:col-span-1">
                                <Sidebar />
                            </div>
                            <div className="col-span-5 sm:col-span-4 p-4">
                                <h1 className="font-bold text-xl mb-4">Compose New Message</h1>

                                <Formik
                                    initialValues={{department: '', course: '', category: '', sub_category: '', message: '', file: ''}}
                                    onSubmit={(values, actions) => {
                                        this.submitIssueFrom(values, actions);
                                    }}
                                    validate = {this.validate}
                                >{(props: FormikProps<any>) => (
                                    
                                    <Form>
                                        <div>
                                            <div className="mb-3">
                                                <label>Select Department: </label>
                                                <Field component={this.mySelectInput2} name="department" options={departments}></Field>
                                                {props.touched.department && props.errors.department && <ErrorMsg msg={props.errors.department} />}
                                            </div>
                                            <div className="mb-3">
                                                <label>Select Course: </label>
                                                <Field component={this.mySelectInput2} name="course" options={courses_copy} ></Field>
                                                {props.touched.course && props.errors.course && <ErrorMsg msg={props.errors.course} />}
                                            </div>
                                            <div className="mb-3">
                                                <label>Select Issue Category: </label>
                                                <Field component={this.mySelectInput} name="category" options={issueCategories}></Field>
                                                {props.touched.category && props.errors.category && <ErrorMsg msg={props.errors.category} />}
                                            </div>
                                            <div className="mb-3">
                                                <label>Select Issue Sub-Category: </label>
                                                <Field component={this.mySelectInput} name="sub_category" options={issueSubCategories} ></Field>
                                                {props.touched.sub_category && props.errors.sub_category && <ErrorMsg msg={props.errors.sub_category} />}
                                            </div>
                                            <div className="mb-3">
                                                <label>Message: </label>
                                                <Field component={this.myTextarea} name="message" placeholder="Enter Message" ></Field>
                                                {props.touched.message && props.errors.message && <ErrorMsg msg={props.errors.message} />}
                                            </div>
                                            <label>Attachemnts: (optional)</label>
                                            <input type="file" className="border border-gray-300 rounded mt-1 py-2 px-4 w-full" onChange={(event) => { props.setFieldValue("file", event.currentTarget.files[0]); }} />
                                            {props.touched.file && props.errors.file && <ErrorMsg msg={props.errors.file} />}
                                            <div className="text-center mt-3">
                                                <button type="submit" className="rgu-btn-dark">
                                                    Send Message
                                                </button>
                                            </div>
                                        </div>
                                    </Form>
                                )}
                                </Formik>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        user: state.user,
        commonData: state.commonData
    }
}

export default connect(mapStateToProps, { getIssueCategories, issueSubCategories, getCourses, getDepartments, courseFilter, sendInternalMessage })(Compose);