import React, { Component } from 'react';

import NoticeLinks from './notice-links/NoticeLinks';
import TabSection from './tabs-section/TabSection';
import HowToApply from './apply/HowToApply';
//import AwardsRecognitions from './awards-recognitions/AwardsRecognitions';
import Banner from './banner/Banner';

export default class HomePage extends Component {
    render() {
        return (
            <React.Fragment>
                <Banner />
                <NoticeLinks />
                <TabSection />
                <HowToApply />
                {/* <AwardsRecognitions /> */}
            </React.Fragment>
        )
    }
}
