import React, { Component } from "react"
import { Form, Formik } from "formik"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

import ApplyBanner from "../../includes/apply-banner/ApplyBanner"
import ErrorMsg from "../../includes/form-inputs/ErrorMsg"
import { getMeritMarksheet, submitMeritDocs } from "../../../../actions"
import { baseURL, staticURI } from "../../includes/jsFunctions"
import LoadingBtn from "../../includes/btn/LoadingBtn"

class BasicDetails extends Component {
    componentDidMount() {
        this.props.getMeritMarksheet(this.props.match.params.id)
    }

    submitBasicDetailsFrom = (values, actions) => {
        this.props.submitMeritDocs(values, actions)
    }

    handleFileSelect = (evt, props, fieldName, target) => {
        var files = evt.target.files // FileList object

        const photos = Array.from(evt.target.files)

        let images = []

        document.getElementById(target).innerHTML = ""

        // Loop through the FileList and render image files as thumbnails.
        for (var i = 0, f; (f = files[i]); i++) {
            // Only process image files.
            if (!f.type.match("image.*")) {
                continue
            }

            var reader = new FileReader()

            // Closure to capture the file information.
            reader.onload = (function (theFile) {
                return function (e) {
                    // Render thumbnail.
                    var span = document.createElement("span")
                    span.innerHTML = [
                        '<img class="thumb" src="',
                        e.target.result,
                        '" />',
                    ].join("")
                    document.getElementById(target).insertBefore(span, null)
                }
            })(f)

            images.push(f)

            // Read in the image file as a data URL.
            reader.readAsDataURL(f)
        }

        const promises = photos.map((file) => {
            return new Promise((resolve, reject) => {
                const reader = new FileReader()
                reader.addEventListener("load", (ev) => {
                    resolve(ev.target.result)
                })
                reader.addEventListener("error", reject)
                reader.readAsDataURL(file)
            })
        })

        props.setFieldValue(fieldName, evt.target.files[0])

        // Promise.all(promises).then(files => {

        // }, error => { console.error(error); });
    }

    render() {
        const { loginLoading, login } = this.props.user
        const { isSubmitting } = this.props.commonFunctions
        const { merit_docs } = this.props.apply

        if (!loginLoading && !login) {
            window.location.href = `${staticURI}/`
        }

        if (merit_docs.status === "N") {
            window.location.href = `${staticURI}/dashboard`
        }

        const validate = (values, props) => {
            const errors = {}

            if (!values.marksheet) {
                errors.marksheet = "Please select file"
            }

            return errors
        }

        return (
            <React.Fragment>
                <ApplyBanner title="Documents Upload " />
                <section
                    className="personal-details-page py-10"
                    style={{ backgroundColor: "#F5FBFF" }}
                >
                    <div className="container mx-auto px-8">
                        <div className="form-wrap shadow-md bg-white pb-4">
                            <Formik
                                initialValues={{
                                    marksheet: "",
                                    application_id: merit_docs.application_id,
                                }}
                                onSubmit={(values, actions) => {
                                    this.submitBasicDetailsFrom(values, actions)
                                }}
                                enableReinitialize={true}
                                validate={validate}
                                //validationSchema={validate}
                            >
                                {(props: FormikProps<any>) => (
                                    <Form>
                                        <div className="section-three p-8 border-t-gray-300">
                                            <div className="form-input">
                                                <div className="w-full">
                                                    {/* {merit_docs.appeared_exam && merit_docs.appeared_exam.exam_name ? merit_docs.appeared_exam.exam_name : null} */}
                                                    <label>
                                                        Upload Marksheet of Last
                                                        Exam Passed
                                                    </label>
                                                    <div className="text-input mt-2">
                                                        <input
                                                            type="file"
                                                            accept="image/*"
                                                            name="marksheet"
                                                            className={`appearance-none block w-full py-2 px-3 border border-gray-300 rounded`}
                                                            onChange={(event) =>
                                                                this.handleFileSelect(
                                                                    event,
                                                                    props,
                                                                    "marksheet",
                                                                    "selected-photo"
                                                                )
                                                            }
                                                        />
                                                    </div>
                                                    <label
                                                        style={{ color: "red" }}
                                                    >
                                                        *Maximum upload size is
                                                        200kb
                                                    </label>
                                                    <br />
                                                    <label
                                                        style={{ color: "red" }}
                                                    >
                                                        *Required formats are
                                                        jpg, jpeg and png.
                                                    </label>
                                                    {props.touched.marksheet &&
                                                        props.errors
                                                            .marksheet && (
                                                            <ErrorMsg
                                                                msg={
                                                                    props.errors
                                                                        .marksheet
                                                                }
                                                            />
                                                        )}
                                                </div>
                                                <div
                                                    id="selected-photo"
                                                    className="my-3"
                                                ></div>
                                                {merit_docs &&
                                                merit_docs.dob ? (
                                                    <p className="font-bold text-green-500">
                                                        Document Successfully
                                                        Uploaded
                                                    </p>
                                                ) : null}
                                            </div>
                                        </div>

                                        <div className="text-center mt-4 mb-4">
                                            <button
                                                className="rgu-btn-light"
                                                type="submit"
                                            >
                                                {isSubmitting ? (
                                                    <LoadingBtn />
                                                ) : (
                                                    `Upload`
                                                )}
                                            </button>
                                        </div>
                                    </Form>
                                )}
                            </Formik>

                            {merit_docs.merit_marksheet ? (
                                <div className="text-center">
                                    <a
                                        className="font-bold font-xl"
                                        target="_blank"
                                        href={`${baseURL}/merit/docs/${merit_docs.merit_marksheet}`}
                                    >
                                        View Uploaded File
                                    </a>
                                </div>
                            ) : null}
                        </div>
                    </div>
                </section>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        apply: state.apply,
        commonFunctions: state.commonFunctions,
    }
}

export default withRouter(
    connect(mapStateToProps, { getMeritMarksheet, submitMeritDocs })(
        BasicDetails
    )
)
