import React, { Component } from 'react';
import { connect } from 'react-redux';
import {Field, Form, Formik, FormikProps, ErrorMessage} from 'formik';

import ErrorMsg from '../../../includes/form-inputs/ErrorMsg';
import { validateOTP } from './validate';
import { verifyEmailOTP, resendEmail } from '../../../../../actions';
import ProgressBar from './ProgressBar';

class VerifyEmail extends Component {

    verifyOtp = (values, actions) => {
        this.props.verifyEmailOTP(values, actions);
    }

    myTextInput = ({ name, field, placeholder, type, border }) => {
        const borderStyle = border ? `border border-gray-300 rounded` : null;
        return (
            <div className="w-full">
                <div className="text-input">
                    <input placeholder={placeholder} type={type} name={name} className={`appearance-none block w-full py-2 px-3 ${borderStyle}`} {...field} />
                </div>
            </div>
        )
    }

    resendMail = (email,mobile) => {
        this.props.resendEmail(email, mobile);
        document.getElementById("resend-btn").disabled = true;
        setTimeout(function () {
            document.getElementById('resend-btn').disabled = false;
        }, 25000);
        return false;
    }

    render() {

        const { email, resendMail, mobile } = this.props.bannerForm;
        const { current_year } = this.props.settings;

        return (
            <React.Fragment>
                <ProgressBar width="50%" />
                <div className="form-head text-center">
                    <h1 className="uppercase font-bold text-dark-blue text-xl md:text-2xl lg:text-2xl xl:text-2xl">Admission Open { current_year }</h1>
                    <p className="text-gray-600 text-lg mt-4">
                        Please enter your otp sent to: {email}
                    </p>
                </div>
                <div className="form-body mt-12 mb-8">
                    
                    <Formik
                        initialValues={{email, otp: ''}}
                        onSubmit={(values, actions) => {
                            this.verifyOtp(values, actions);
                        }}
                    >{(props: FormikProps<any>) => (
                        <Form>
                            <div className="w-full mt-4">
                                <Field component={this.myTextInput} name="otp" validate={validateOTP} placeholder="Enter OTP" type="number" border={true}></Field>
                                <ErrorMessage name="otp" render={msg => <ErrorMsg msg={msg} />} />
                            </div>
                            <div className="my-3">
                                { resendMail ? <div className="text-green-500 w-full flex flex-row-reverse">Otp has been successfully sent.</div> : null }
                                <div className="flex flex-row-reverse">
                                    <button id="resend-btn" className="text-light-blue" type="button" onClick={ () => this.resendMail(email, mobile) }>Resend OTP</button>
                                </div>
                                <div className="flex flex-row-reverse text-red-500">
                                    * OTP is valid for 5 minutes.
                                </div>
                            </div>
                            <div className={`form-submit text-center mt-3`}>
                                <button type="submit" className="rgu-btn-light">Verify OTP</button>
                            </div>
                        </Form>
                    )}
                    </Formik>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    return{
        bannerForm: state.bannerForm,
        commonFunctions: state.commonFunctions,
        settings: state.commonData.settings
    }
}


export default connect(mapStateToProps, { verifyEmailOTP, resendEmail })(VerifyEmail);
