import * as Yup from 'yup';

const validate = Yup.object().shape({

    edu: Yup.object().shape({
        exam: Yup.string()
        .required("Please select exam")
        .nullable(),

        exam_name: Yup.string()
        .when('exam', {
            is: exam => exam !=="" || exam !== null || exam !== 'undefined',
            then: Yup.string()
                .required('Please enter examination name')
                .nullable(),
                otherwise: Yup.string().notRequired()
        }),

        board: Yup.string()
        .required("Please select board")
        .nullable(),

        roll: Yup.string()
        .required("Please enter roll number")
        .nullable(),

        reg_no: Yup.string()
        .required("Please enter registration number")
        .nullable(),

        year: Yup.string()
        .required("Please enter passing year")
        .nullable(),

        
        status: Yup.string()
        .required("Please select status")
        .nullable(),

        total_marks: Yup.string()
        .when('status', {
            is: status => parseInt(status) === 1,
            then: Yup.string()
                .required('Please enter total marks')
                .nullable(),
                otherwise: Yup.string().notRequired()
        }),

        marks_secured: Yup.string()
        .when('status', {
            is: status => parseInt(status) === 1,
            then: Yup.string()
                .required('Please enter marks secured')
                .nullable(),
                otherwise: Yup.string().notRequired()
        }),

        percentage: Yup.string()
        .when('status', {
            is: status => parseInt(status) === 1,
            then: Yup.string()
                .required('Please enter percentage')
                .nullable(),
                otherwise: Yup.string().notRequired()
        }),

        subjects: Yup.array()
        .of(
            Yup.string()
            .required("Please fill in the subjects")
        )
        .required('Please enter subjects')
        .nullable(),

    }),
    

});

export default validate;