import React, { Component } from 'react';
import { connect } from 'react-redux';

import Sidebar from './Sidebar';
import PageBanner from '../includes/page-banner/PageBanner';
import MessageItem from './MessageItem';
import { scrollToTop } from '../includes/jsFunctions';
import { getMyIssues } from '../../../actions';

class Messages extends Component {

    componentDidMount(){
        scrollToTop();
        this.props.getMyIssues();
    }

    render() {

        const { loginLoading, login, issues } = this.props.user;

        if(!loginLoading && !login){
            window.location.href = "/";
        }

        const my_issues = issues && issues.length > 0 ? issues.map( issue => { 

            const date = new Date(issue.created_at);
            const issue_date = `${date.getDate()}-${date.getMonth()+1}-${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}`

            return (
                <MessageItem image="/images/f-4.jpg" subject={`${issue.category_name.category} : ${issue.sub_category_name.category}`} message={`${issue.department_name.name} : ${issue.course_name.title}, Date: ${issue_date}`} key={issue.id} id={issue.id} />
            )
        }) : null;

        return (
            <React.Fragment>
                <PageBanner title="Internal Messages" image="/images/page.jpg" />
                <div className="py-16">
                    <div className="container mx-auto px-8">
                        <div className="bg-white shadow-md border border-gray-300 rounded grid grid-cols-5">
                            <div className="col-span-5 sm:col-span-1">
                                <Sidebar />
                            </div>
                            <div className="col-span-5 sm:col-span-4 p-4">
                                <h1 className="font-bold text-xl mb-4">Internal Messages</h1>
                                {my_issues}
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        user: state.user
    }
}

export default connect(mapStateToProps, { getMyIssues })(Messages);