import React, { Component } from 'react';
import {Field} from 'formik';
import { getIn } from 'formik';
import { connect } from 'react-redux';

import ErrorMsg from '../../includes/form-inputs/ErrorMsg';
import { setPresentStates, setPresentCities, getPresentCities } from '../../../../actions/index';

class PresentAdr extends Component {

    componentDidMount(){
        // if(parseInt(this.props.props.values.present_adr.state) > 0){
        //     this.props.getPresentCities(this.props.props.values.present_adr.state);
        // }
    }

    componentDidUpdate(prevProps) {

        if(prevProps.props.values.present_adr.country !== this.props.props.values.present_adr.country){
            if(this.props.commonData.countries && this.props.commonData.countries.length > 0  && parseInt(this.props.props.values.present_adr.country) > 0){
                //console.log(this.props.props.values.present_adr.country);
                this.filterStates(this.props.commonData.countries, this.props.props.values.present_adr.country);
            }
        }
    
        if(prevProps.props.values.present_adr.state !== this.props.props.values.present_adr.state){

            if(parseInt(this.props.props.values.present_adr.state) > 0 && parseInt(this.props.props.values.present_adr.country) > 0){
                this.props.getPresentCities(this.props.props.values.present_adr.state);
            }

            if(this.props.commonData.states && this.props.commonData.states.length > 0  && parseInt(this.props.props.values.present_adr.state) > 0){
                //console.log(this.props.props.values.present_adr.country);
                this.filterCities(this.props.commonData.states, this.props.props.values.present_adr.state);
            }
        }

    }

    myTextInput = ({ name, field, placeholder, type, readonly }) => {

        const disabled = readonly ? true : false;

        return (
            <div className="w-full">
                <label>{placeholder}</label>
                <div className="text-input mt-2">
                    <input placeholder={placeholder} type={type} name={name} className={`appearance-none block w-full py-2 px-3 border border-gray-300 rounded`} {...field} readOnly={disabled} />
                </div>
            </div>
        )
    }

    mySelectInput = ({ name, options, field, label }) => {
    
        const selectOptions = options.length > 0 ? options.map(option => {
            return (
                <option key={option.id} value={option.id}>{option.name}</option>
            )
        }) : null;
        
        return(
            <div className="select-input">
                <label>{label}</label>
                <select className={`w-full bg-white focus:outline-none py-2 px-4 border border-gray-300 rounded mt-2`} name={name} {...field}>
                    <option value="">Please Select</option>
                    {selectOptions}
                </select>
            </div>
        )
    }

    filterStates = (countries, selected) => {
        const selectedItem = countries.find(element => element.id === parseInt(selected));
        this.props.setPresentStates(selectedItem.state);
        //console.log(selectedItem);
    }

    filterCities = (states, selected) => {
        const selectedItem = states.find(element => element.id === parseInt(selected));
        this.props.setPresentCities(selectedItem.city);
        console.log(selectedItem);
    }

    render() {

        const { countries, states, cities } = this.props.commonData;
        const { props } = this.props;

        //console.log(props);
        // if(countries && countries.length > 0  && parseInt(props.values.present_adr.country) > 0){
        //     console.log(props.values.present_adr.country);
        //     //this.filterStates(this.props.countries, this.props.props.values.present_adr.country);
        // }
        

        return (
            <div className="section-two p-8 border border-t-gray-300">
                <h1 className="mb-4 text-xl md:text-2xl font-bold">Present Address</h1>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-3">
                    <div className="form-input">
                        <Field component={this.myTextInput} name="present_adr.adr_line_1" placeholder="Address Line 1" type="text" readonly={false}></Field>
                        {getIn(props.errors, 'present_adr.adr_line_1') && getIn(props.touched, 'present_adr.adr_line_1') && <ErrorMsg msg={getIn(props.errors, 'present_adr.adr_line_1')} />}
                    </div>
                    <div className="form-input">
                        <Field component={this.myTextInput} name="present_adr.adr_line_2" placeholder="Address Line 2 (optional)" type="text" readonly={false}></Field>
                    </div>
                </div>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-3">
                    <div>
                        <Field component={this.mySelectInput} name="present_adr.country" label="Country" options={countries ? countries : []}></Field>
                        {getIn(props.errors, 'present_adr.country') && getIn(props.touched, 'present_adr.country') && <ErrorMsg msg={getIn(props.errors, 'present_adr.country')} />}
                    </div>
                    <div>
                        <Field component={this.mySelectInput} name="present_adr.state" label="State" options={states ? states : []}></Field>
                        {getIn(props.errors, 'present_adr.state') && getIn(props.touched, 'present_adr.state') && <ErrorMsg msg={getIn(props.errors, 'present_adr.state')} />}
                    </div>
                </div>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-3">
                    <div>
                        <Field component={this.mySelectInput} name="present_adr.city" label="City" options={cities ? cities : []}></Field>
                        {getIn(props.errors, 'present_adr.city') && getIn(props.touched, 'present_adr.city') && <ErrorMsg msg={getIn(props.errors, 'present_adr.city')} />}
                    </div>
                    <div className="form-input">
                        <Field component={this.myTextInput} name="present_adr.pincode" placeholder="Pincode" type="number" readonly={false}></Field>
                        {getIn(props.errors, 'present_adr.pincode') && getIn(props.touched, 'present_adr.pincode') && <ErrorMsg msg={getIn(props.errors, 'present_adr.pincode')} />}
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToPorps = state => {
    return {
        commonData: state.commonData,
    }
}

export default connect(mapStateToPorps, { setPresentStates, setPresentCities, getPresentCities })(PresentAdr);