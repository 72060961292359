import React, { Component } from 'react';

import TabsNav from './tabs-nav/TabsNav';
import CampusFacilities from  './facilities/CampusFacilities';
import EligibilityCriteria from './eligibility/EligibilityCriteria';
import Faq from './faq/Faq';

class TabSection extends Component {
    render() {
        return (
            <section className="tab-section bg-dark-blue py-10">
                <div className="container mx-auto px-4">
                    <TabsNav />
                    <div className="tab-content">
                        <CampusFacilities />
                        <EligibilityCriteria />
                        <Faq />
                    </div>
                </div>
            </section>
        )
    }
}

export default TabSection;
