import React, { Component } from 'react';
import { FiChevronRight } from "react-icons/fi";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import PageBanner from '../includes/page-banner/PageBanner';
import { getCourses, getDepartments, courseFilter, fetchCourseDetails } from '../../../actions';
import { scrollToTop } from '../includes/jsFunctions';

import './course-overview.css';

class CoursesPage extends Component {

    componentDidMount(){
        this.props.getCourses();
        this.props.getDepartments();
        scrollToTop();
    }

    createMarkup = (body) => {
        return {__html: body};
    }

    searchCourses = () => {
        let department = document.querySelector("#department").value;
        let course = document.querySelector("#course").value;

        this.props.fetchCourseDetails(department, course);
    }

    filterCourses = () => {
        let department = document.querySelector("#department").value;
        this.filterValues(this.props.commonData.courses, department);
        this.searchCourses();
    }

    filterValues = (options, department)  => {
        let filteredCourses =  options.filter(function(course) {
            return course.department_id === department;
        });
        this.props.courseFilter(filteredCourses);
    }

    render() {

        const { departments, courses_copy } = this.props.commonData;
        const { isLoading } = this.props.commonFunctions;
        const { courseDetails } = this.props.appData;

        return (
            <React.Fragment>
                <PageBanner title="Courses Overview" image="/images/page.jpg" />
                <section className="overview-section py-10 bg-light-blue">
                    <div className="container mx-auto px-4">
                        <div className="breadcrumb mb-10">
                            <ul className="breadcrumb-list">
                                <li>Home</li>
                                <li><FiChevronRight /></li>
                                <li>Courses Overview</li>
                            </ul>
                        </div>
                        <div className="bg-white p-4 shadow-md mb-4">
                            <div className="w-3/4 mx-auto">
                                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                                    <div>
                                        <label>Select Department: </label>
                                        <div className="w-full">
                                            <div className="select-input">
                                                <select name="department" onChange={this.filterCourses} id="department" className={`block w-full py-2 px-3 border border-gray-300 rounded`}>
                                                    <option value="">Please Select</option>
                                                    {
                                                    
                                                    departments && departments.length > 0 ? departments.map(option => {
                                                       
                                                        return(<option value={option.id} key={option.id}>{option.title}</option>)
                                                        
                                                    }) : null

                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <label>Select Course: </label>
                                        <div className="w-full">
                                            <div className="select-input">
                                                <select name="course" onChange={this.searchCourses} id="course" className={`block w-full py-2 px-3 border border-gray-300 rounded`}>
                                                    <option value="">Please Select</option>
                                                    {

                                                        courses_copy && courses_copy.length > 0 ? courses_copy.map(option => {
                                                                                                            
                                                            return(<option value={option.id} key={option.id}>{option.title}</option>)
                                                            
                                                        }) : null

                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <React.Fragment>

                            {
                                courseDetails && courseDetails.title ? (

                                    <React.Fragment>

                                        <div className="bg-white py-4 px-8 shadow-md mb-4">
                                            <h1 className="font-bold text-2xl mb-4">{ courseDetails.title }</h1>
                                            <div className="grid grid-cols-3 gap-4">
                                                <div className="col-span-3 sm:col-span-2 md:cols-span-1">
                                                    <div className="grid grid-cols-2 md:grid-cols-3">
                                                        <div className="border-r-2 border-gray-400 col-span-1">
                                                            <h1 className="font-bold text-lg">Course Type</h1>
                                                            <p>{courseDetails.course_type.name}</p>
                                                        </div>
                                                        <div className="pl-12 col-span-1 md:col-span-2">
                                                            <h1 className="font-bold text-lg">Duration</h1>
                                                            <p>{courseDetails.duration} {courseDetails.duration_type === "1" ? `Months` : courseDetails.duration_type === "2" ? `Semesters` : `Years` }</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-span-3 sm:col-span-1 md:cols-span-2 flex flex-row-reverse">
                                                    <Link to="/dashboard" className="rgu-btn-dark course-apply-btn">Apply Now</Link>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="bg-white py-4 shadow-md">
                                            <nav className="section-nav px-8 mb-4">
                                                <ul className="flex justify-start text-lg">
                                                    <li className="active px-4 font-semibold">
                                                        <a href="#0">
                                                            Overview
                                                        </a>
                                                    </li>
                                                    <li className="px-4 font-semibold">
                                                        <a href="#0">Course Fees</a>
                                                    </li>
                                                    <li className="px-4 font-semibold">
                                                        <a href="#0">Eligibility Criteria</a>
                                                    </li>
                                                </ul>
                                            </nav>
                                            <div className="overview-section px-8 mb-10">
                                                <h1 className="mb-4 font-bold text-xl">About the Course</h1>
                                                <div dangerouslySetInnerHTML={this.createMarkup(courseDetails.overview)}>
                                                </div>
                                            </div>
                                            <div className="course-fee-section mb-10">
                                                <div className="bg-dark-blue text-white px-4 py-2 w-40 text-center">
                                                    Course Fee
                                                </div>
                                                <div className="course-fee-div px-8 mt-4">
                                                    <div className="w-full md:w-4/5 mx-auto table-responsive">
                                                        <h3 className="mb-4 mt-4 text-xl">Semester Fees</h3>
                                                        <table className="table-auto w-full">
                                                            <thead>
                                                                <tr>
                                                                    {
                                                                       courseDetails.course_fees.map(course => {
                                                                            return(<td>{course.name}</td>)
                                                                       })
                                                                    }
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    {
                                                                       courseDetails.course_fees.map(course => {
                                                                            return(<td>₹{course.amount}</td>)
                                                                       })
                                                                    }
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="eligibity-section mb-4">
                                                <div className="bg-dark-blue text-white px-4 py-2 w-40 text-center">
                                                    Eligibility Criteria
                                                </div>
                                                <div className="mt-4 px-8" dangerouslySetInnerHTML={this.createMarkup(courseDetails.eligibility)}>
                                                </div>
                                            </div>
                                        </div>

                                    </React.Fragment>

                                ) : isLoading ? (

                                    <div className="text-center">
                                        <img src="/images/spinner.gif" alt="Loading" style={{ width: '50px', margin: '0 auto' }} />
                                        <div className="mt-4">
                                            <h3 className="font-bold tex-xl">Fetching Details</h3>
                                        </div>
                                    </div>

                                ) : null
                            }

                            
                        </React.Fragment>

                    </div>
                </section>
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        commonData: state.commonData,
        commonFunctions: state.commonFunctions,
        appData: state.appData,
    }
}

export default connect( mapStateToProps, { getCourses, getDepartments, courseFilter, fetchCourseDetails })(CoursesPage);
