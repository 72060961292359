import React, { Component } from "react"
import { connect } from "react-redux"
import { Field, Form, Formik } from "formik"

import ErrorMsg from "../includes/form-inputs/ErrorMsg"
import LoadingBtn from "../includes/btn/LoadingBtn"
import { changePassword } from "../../../actions"
import ResetSuccess from "./ResetSuccess"

class NewPassword extends Component {
    submitResetFrom = (values, actions) => {
        this.props.changePassword(values, actions)
    }

    myTextInput = ({ name, field, placeholder, type, border }) => {
        const borderStyle = border ? `border border-gray-300 rounded` : null
        return (
            <div className="w-full">
                <div className="text-input">
                    <input
                        placeholder={placeholder}
                        type={type}
                        name={name}
                        className={`appearance-none block w-full py-2 px-3 ${borderStyle}`}
                        {...field}
                    />
                </div>
            </div>
        )
    }

    validate = (values) => {
        let errors = {}

        if (!values.password) {
            errors.password = "Please enter new password"
        } else if (values.password.length < 6) {
            errors.password = "Password must be of atleast 6 characters long"
        }

        if (!values.password_confirmation) {
            errors.password_confirmation = "Please enter new password"
        } else if (values.password_confirmation !== values.password) {
            errors.password_confirmation = "Passwords don't match. Try again!"
        }

        return errors
    }

    render() {
        const { resetMobile, newPassword } = this.props.user
        const { isSubmitting } = this.props.commonFunctions

        if (newPassword) {
            return <ResetSuccess />
        }

        return (
            <div className="bulletin-form">
                <div className="form-head text-center mb-8">
                    <h1 className="uppercase font-bold text-dark-blue text-xl md:text-2xl lg:text-2xl xl:text-2xl">
                        RESET PASSWORD
                    </h1>
                    <p className="text-gray-600 text-lg mt-4">
                        Please enter your new password
                    </p>
                </div>
                <div className="form-body">
                    <Formik
                        initialValues={{
                            mobile: resetMobile,
                            password: "",
                            password_confirmation: "",
                        }}
                        onSubmit={(values, actions) => {
                            this.submitResetFrom(values, actions)
                        }}
                        validate={this.validate}
                    >
                        {(props: FormikProps<any>) => (
                            <Form>
                                <div id="resend-btn"></div>
                                <div className="w-full mt-4">
                                    <Field
                                        component={this.myTextInput}
                                        name="password"
                                        placeholder="Enter Password"
                                        type="password"
                                        border={true}
                                    ></Field>
                                    <div className="mb-4">
                                        {props.touched.password &&
                                            props.errors.password && (
                                                <ErrorMsg
                                                    msg={props.errors.password}
                                                />
                                            )}
                                    </div>
                                </div>
                                <div className="w-full mt-4">
                                    <Field
                                        component={this.myTextInput}
                                        name="password_confirmation"
                                        placeholder="Confirm Password"
                                        type="password"
                                        border={true}
                                    ></Field>
                                    <div className="mb-4">
                                        {props.touched.password_confirmation &&
                                            props.errors
                                                .password_confirmation && (
                                                <ErrorMsg
                                                    msg={
                                                        props.errors
                                                            .password_confirmation
                                                    }
                                                />
                                            )}
                                    </div>
                                </div>
                                <div className={`form-submit text-center mt-8`}>
                                    <button
                                        type="submit"
                                        className="rgu-btn-light"
                                    >
                                        {isSubmitting ? (
                                            <LoadingBtn />
                                        ) : (
                                            `Submit`
                                        )}
                                    </button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        commonFunctions: state.commonFunctions,
    }
}

export default connect(mapStateToProps, { changePassword })(NewPassword)
