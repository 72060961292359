import React from 'react';

import './conversation-item.css';

const ConverationItem = ({ image, name, date, body, to }) => {
    return (
        
        <div className="conversation-item px-4 py-2 bg-gray-100 border border-gray-300 hover:shadow-md mb-3">
            <button type="button" className="flex justify-between focus:outline-none block w-full accordion">
                <div className="flex-initial self-center user-img-wrap rounded-full">
                    <img src={image} alt="user-icon" />
                </div>
                <div className="flex-1 ml-4 text-left">
                    <h3 className="text-lg font-medium">
                        {name}
                    </h3>
                    <p>
                        to {to}
                    </p>
                </div>
                <div className="flex-initial">
                    <div className="flex flex-row-reverse self-center">
                        {date}
                    </div>
                </div>
            </button>
            <div className="message-body panel mt-2">
                {body}
            </div>
        </div>
        
    )
}

export default ConverationItem;