import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {FaPlus, FaEnvelope, FaPaperPlane} from 'react-icons/fa';

class Sidebar extends Component {
    render() {
        return (
            <div className="sidebar bg-gray-200 p-4 w-full h-full">
                <div className="compose w-full bg-dark-blue px-6 py-2 text-center rounded mb-4">
                    <Link className="text-white w-full block" to="/compose">
                        <span className="inline-icon"><FaPlus /></span>Compose
                    </Link>
                </div>
                <div className="msg-sidebar-links">
                    <ul className="msg-sidebar-ul text-dark-blue">
                        <li className="active">
                            <Link to="/messages">
                                <span className="inline-icon">
                                    <FaEnvelope />
                                </span>
                                Inbox
                            </Link>
                        </li>
                        <li>
                            <Link to="#0">
                                <span className="inline-icon">
                                    <FaPaperPlane />
                                </span>
                                Sent
                            </Link>
                        </li>
                    </ul>

                </div>
            </div>
        )
    }
}

export default Sidebar;
