import React, { Component } from "react"
import { connect } from "react-redux"
import { Field, Form, Formik } from "formik"

import ErrorMsg from "../includes/form-inputs/ErrorMsg"
import LoadingBtn from "../includes/btn/LoadingBtn"
import { verifyResetOTP, resendResetOTP } from "../../../actions"

class ResetOtp extends Component {
    submitResetFrom = (values, actions) => {
        this.props.verifyResetOTP(values, actions)
    }

    myTextInput = ({ name, field, placeholder, type }) => {
        return (
            <div className="w-full">
                <div className="text-input">
                    <input
                        placeholder={placeholder}
                        type={type}
                        name={name}
                        className={`appearance-none block w-full py-2 px-3`}
                        {...field}
                    />
                </div>
            </div>
        )
    }

    validate = (values) => {
        let errors = {}

        if (!values.otp) {
            errors.otp = "Please enter your otp"
        }

        return errors
    }

    resendMail = (email, mobile) => {
        this.props.resendResetOTP(email, mobile)
        document.getElementById("resend-btn").disabled = true
        setTimeout(function () {
            document.getElementById("resend-btn").disabled = false
        }, 25000)
        return false
    }

    render() {
        const { resetMobile, resetResend } = this.props.user
        const { isSubmitting } = this.props.commonFunctions

        return (
            <div className="bulletin-form">
                <div className="form-head text-center mb-8">
                    <h1 className="uppercase font-bold text-dark-blue text-xl md:text-2xl lg:text-2xl xl:text-2xl">
                        FORGOT PASSWORD
                    </h1>
                    <p className="text-gray-600 text-lg mt-4">
                        Please enter OTP sent to the mobile number:
                        {resetMobile}
                    </p>
                </div>
                <div className="form-body">
                    <Formik
                        initialValues={{ mobile: resetMobile, otp: "" }}
                        onSubmit={(values, actions) => {
                            this.submitResetFrom(values, actions)
                        }}
                        validate={this.validate}
                    >
                        {(props: FormikProps<any>) => (
                            <Form>
                                <div className="form-input border border-gray-300 rounded">
                                    <Field
                                        component={this.myTextInput}
                                        name="otp"
                                        placeholder="Enter OTP"
                                        type="number"
                                    ></Field>
                                </div>
                                <div className="mb-4">
                                    {props.touched.otp && props.errors.otp && (
                                        <ErrorMsg msg={props.errors.otp} />
                                    )}
                                </div>
                                <div className="my-3">
                                    {resetResend ? (
                                        <div className="text-green-500 w-full flex flex-row-reverse">
                                            Otp has been successfully sent.
                                        </div>
                                    ) : null}
                                    <div className="flex flex-row-reverse">
                                        <button
                                            id="resend-btn"
                                            className="text-light-blue"
                                            type="button"
                                            onClick={() =>
                                                this.resendMail(resetMobile)
                                            }
                                        >
                                            Resend OTP
                                        </button>
                                    </div>
                                    <div className="flex flex-row-reverse text-red-500">
                                        * OTP is valid for 5 minutes.
                                    </div>
                                </div>
                                <div className={`form-submit text-center mt-8`}>
                                    <button
                                        type="submit"
                                        className="rgu-btn-light"
                                    >
                                        {isSubmitting ? (
                                            <LoadingBtn />
                                        ) : (
                                            `Submit`
                                        )}
                                    </button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        commonFunctions: state.commonFunctions,
    }
}

export default connect(mapStateToProps, { verifyResetOTP, resendResetOTP })(
    ResetOtp
)
