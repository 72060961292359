import React, { Component } from "react"
import { connect } from "react-redux"

import FacilityItem from "./FacilityItem"
import { getCampusFacilities } from "../../../../../actions"

class CampusFacilities extends Component {
    componentDidMount() {
        this.props.getCampusFacilities()
    }

    render() {
        const { campus_facilities } = this.props.commonData

        return (
            <div className="tabs-section-one active" id="facilities">
                <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-4 gap-4 mt-10">
                    {campus_facilities &&
                        campus_facilities.length > 0 &&
                        campus_facilities.map((facility) => (
                            <FacilityItem
                                key={facility.id}
                                name={facility.name}
                                image={facility.image}
                            />
                        ))}
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        commonData: state.commonData,
    }
}

export default connect(mapStateToProps, { getCampusFacilities })(
    CampusFacilities
)
