import React from "react"
import { Link } from "react-router-dom"
import { IoIosArrowDropright } from "react-icons/io"

import "./important-link-item.css"

const ImportantLinkItem = ({ title, image, file, link, type, isClicked }) => {
    return (
        <div className="important-link-item-wrap bg-white p-2 mb-2">
            {type === "url" ? (
                <a href={link} target="_blank" rel="noopener noreferrer">
                    <div className="flex">
                        <div className="flex-initial link-img self-center">
                            <img src={image} alt="apply" />
                        </div>
                        <div className="flex-1 self-center text-lg">
                            <div className="link-text text-center">{title}</div>
                        </div>
                        <div className="flex-initial self-center">
                            <div className="flex flex-row-reverse link-icon text-light-blue">
                                <IoIosArrowDropright />
                            </div>
                        </div>
                    </div>
                </a>
            ) : type === "modal" ? (
                <a href="#0" onClick={() => isClicked("bulletinModal2")}>
                    <div className="flex">
                        <div className="flex-initial link-img self-center">
                            <img src={image} alt="apply" />
                        </div>
                        <div className="flex-1 self-center text-lg">
                            <div className="link-text text-center">{title}</div>
                        </div>
                        <div className="flex-initial self-center">
                            <div className="flex flex-row-reverse link-icon text-light-blue">
                                <IoIosArrowDropright />
                            </div>
                        </div>
                    </div>
                </a>
            ) : (
                <a href={file}>
                    <div className="flex">
                        <div className="flex-initial link-img self-center">
                            <img src={image} alt="apply" />
                        </div>
                        <div className="flex-1 self-center text-lg">
                            <div className="link-text text-center">{title}</div>
                        </div>
                        <div className="flex-initial self-center">
                            <div className="flex flex-row-reverse link-icon text-light-blue">
                                <IoIosArrowDropright />
                            </div>
                        </div>
                    </div>
                </a>
            )}
        </div>
    )
}

export default ImportantLinkItem
