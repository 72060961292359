import React, { Component } from "react"
import { Form, Formik } from "formik"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

import ApplyBanner from "../../includes/apply-banner/ApplyBanner"
import ErrorMsg from "../../includes/form-inputs/ErrorMsg"
import {
    getMeritMarksheet,
    submitMeritDocs,
    getBasicDetails,
} from "../../../../actions"
import { baseURL, staticURI } from "../../includes/jsFunctions"
import LoadingBtn from "../../includes/btn/LoadingBtn"
import ProgressBar from "../../dashboard/progressbar/ProgressBar"

class UploadDocs extends Component {
    componentDidMount() {
        this.props.getBasicDetails(this.props.match.params.id)
        this.props.getMeritMarksheet(this.props.match.params.id)
    }

    submitDocsFrom = (values, actions) => {
        //console.log(values)
        this.props.submitMeritDocs(values, actions)
    }

    handleFileSelect = (evt, props, fieldName, target) => {
        var files = evt.target.files // FileList object

        const photos = Array.from(evt.target.files)

        let images = []

        document.getElementById(target).innerHTML = ""

        // Loop through the FileList and render image files as thumbnails.
        for (var i = 0, f; (f = files[i]); i++) {
            // Only process image files.
            if (!f.type.match("image.*")) {
                continue
            }

            var reader = new FileReader()

            // Closure to capture the file information.
            reader.onload = (function (theFile) {
                return function (e) {
                    // Render thumbnail.
                    var span = document.createElement("span")
                    span.innerHTML = [
                        '<img class="thumb" style="width: 200px" src="',
                        e.target.result,
                        '" />',
                    ].join("")
                    document.getElementById(target).insertBefore(span, null)
                }
            })(f)

            images.push(f)

            // Read in the image file as a data URL.
            reader.readAsDataURL(f)
        }

        const promises = photos.map((file) => {
            return new Promise((resolve, reject) => {
                const reader = new FileReader()
                reader.addEventListener("load", (ev) => {
                    resolve(ev.target.result)
                })
                reader.addEventListener("error", reject)
                reader.readAsDataURL(file)
            })
        })

        props.setFieldValue(fieldName, evt.target.files[0])

        // Promise.all(promises).then(files => {

        // }, error => { console.error(error); });
    }

    render() {
        const { loginLoading, login } = this.props.user
        const { isSubmitting } = this.props.commonFunctions
        const { merit_docs, status, stepTwo } = this.props.apply
        const { settings } = this.props.commonData

        if (!loginLoading && !login) {
            window.location.href = `${staticURI}/`
        }

        if (merit_docs.status === "Y" && stepTwo.enabled_edit === 0) {
            window.location.href = `${staticURI}/dashboard`
        }

        if (status) {
            window.location.href = `${staticURI}/apply/preview/${stepTwo.application_id}`
        }

        const validate = (values, props) => {
            const errors = {}

            if (
                !values.twelfth &&
                settings.field_settings &&
                settings.field_settings.twelfth_marksheet_doc === 1
            ) {
                errors.twelfth = "Please select file"
            }

            if (
                !values.tenth &&
                settings.field_settings &&
                settings.field_settings.tenth_marksheet_doc === 1
            ) {
                errors.tenth = "Please select file"
            }

            if (
                merit_docs.degree_exam &&
                merit_docs.degree_exam.completed == 1
            ) {
                if (
                    !values.marksheet &&
                    settings.field_settings &&
                    settings.field_settings.degree_marksheet_doc === 1
                ) {
                    errors.marksheet = "Please select file"
                }
            }

            if (
                community_data.community &&
                (community_data.community > 1 || stepTwo.ews === "Y")
            ) {
                if (
                    !values.caste &&
                    settings.field_settings &&
                    settings.field_settings.caste_doc === 1
                ) {
                    errors.caste = "Please select file"
                }
            }

            if (stepTwo && stepTwo.kashmiri_migrant === "Y") {
                if (
                    !values.kashmiri &&
                    settings.field_settings &&
                    settings.field_settings.kashmiri_doc === 1
                ) {
                    errors.kashmiri = "Please select file"
                }
            }

            if (stepTwo && stepTwo.ncc === "Y") {
                if (
                    !values.ncc_certificate &&
                    settings.field_settings &&
                    settings.field_settings.ncc_doc === 1
                ) {
                    errors.ncc_certificate = "Please select file"
                }
            }

            if (stepTwo && stepTwo.nss === "Y" && nss_data.hours < 3) {
                if (
                    !values.nss_certificate &&
                    settings.field_settings &&
                    settings.field_settings.nss_doc === 1
                ) {
                    errors.nss_certificate = "Please select file"
                }
            }

            if (stepTwo && stepTwo.serviceman === "Y") {
                if (
                    !values.serviceman &&
                    settings.field_settings &&
                    settings.field_settings.ex_serviceman_doc === 1
                ) {
                    errors.serviceman = "Please select file"
                }
            }

            if (stepTwo && stepTwo.supernumerary === "Y") {
                if (
                    !values.supernumerary &&
                    settings.field_settings &&
                    settings.field_settings.supernumerary_doc === 1
                ) {
                    errors.supernumerary = "Please select file"
                }
            }

            return errors
        }

        const community_data =
            stepTwo && stepTwo.community_details
                ? stepTwo.community_details
                : {}
        //const ews_data = stepTwo && stepTwo.ews_details ? stepTwo.ews_details : {};
        const serviceman_data =
            stepTwo && stepTwo.serviceman_details
                ? stepTwo.serviceman_details
                : {}
        //const disability_data = stepTwo && stepTwo.disability_details ? stepTwo.disability_details : {};
        const supernumerary_data =
            stepTwo && stepTwo.supernumerary_details
                ? stepTwo.supernumerary_details
                : {}
        //const sports_data = stepTwo && stepTwo.sports_details ? stepTwo.sports_details : {};
        const kashmiri_data =
            stepTwo && stepTwo.kashmiri_details ? stepTwo.kashmiri_details : {}
        const ncc_data =
            stepTwo && stepTwo.ncc_details ? stepTwo.ncc_details : {}
        const nss_data =
            stepTwo && stepTwo.nss_details ? stepTwo.nss_details : {}

        const community_file =
            community_data && community_data.certificate_file
                ? community_data.certificate_file
                : ""
        const kashmiri_file =
            kashmiri_data && kashmiri_data.certificate_file
                ? kashmiri_data.certificate_file
                : ""
        const serviceman_file =
            serviceman_data && serviceman_data.certificate_file
                ? serviceman_data.certificate_file
                : ""
        const ncc_file =
            ncc_data && ncc_data.ncc_certificate_file
                ? ncc_data.ncc_certificate_file
                : ""
        const nss_file =
            nss_data && nss_data.nss_certificate_file
                ? nss_data.nss_certificate_file
                : ""
        const supernumerary_file =
            supernumerary_data && supernumerary_data.certificate_file
                ? supernumerary_data.certificate_file
                : ""

        return (
            <React.Fragment>
                <ApplyBanner title="Documents Upload " />
                <section
                    className="personal-details-page py-10"
                    style={{ backgroundColor: "#F5FBFF" }}
                >
                    <ProgressBar step="4" />
                    <div className="container mx-auto px-8">
                        <div className="form-wrap shadow-md bg-white pb-4 rounded-md">
                            <Formik
                                initialValues={{
                                    tenth: merit_docs.tenth_marksheet,
                                    twelfth: merit_docs.twelfth_marksheet,
                                    marksheet: merit_docs.merit_marksheet,
                                    caste: community_file,
                                    kashmiri: kashmiri_file,
                                    serviceman: serviceman_file,
                                    nss_certificate: nss_file,
                                    ncc_certificate: ncc_file,
                                    application_id: merit_docs.application_id,
                                    supernumerary: supernumerary_file,
                                }}
                                onSubmit={(values, actions) => {
                                    this.submitDocsFrom(values, actions)
                                }}
                                enableReinitialize={true}
                                validate={validate}
                                //validationSchema={validate}
                            >
                                {(props: FormikProps<any>) => (
                                    <Form>
                                        <div className="section-three p-8 border-t-gray-300">
                                            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-3">
                                                {settings.field_settings &&
                                                    settings.field_settings
                                                        .tenth_marksheet_doc ===
                                                        1 && (
                                                        <div className="form-input">
                                                            <div className="w-full">
                                                                {/* {merit_docs.appeared_exam && merit_docs.appeared_exam.exam_name ? merit_docs.appeared_exam.exam_name : null} */}
                                                                <label>
                                                                    Upload
                                                                    Marksheet of
                                                                    10th
                                                                </label>
                                                                <div className="text-input mt-2">
                                                                    <input
                                                                        type="file"
                                                                        accept="image/*"
                                                                        name="tenth"
                                                                        className={`appearance-none block w-full py-2 px-3 border border-gray-300 rounded`}
                                                                        onChange={(
                                                                            event
                                                                        ) =>
                                                                            this.handleFileSelect(
                                                                                event,
                                                                                props,
                                                                                "tenth",
                                                                                "selected-10th-photo"
                                                                            )
                                                                        }
                                                                    />
                                                                </div>
                                                                <label
                                                                    style={{
                                                                        color: "red",
                                                                    }}
                                                                >
                                                                    *Maximum
                                                                    upload size
                                                                    is 200kb
                                                                </label>
                                                                <br />
                                                                <label
                                                                    style={{
                                                                        color: "red",
                                                                    }}
                                                                >
                                                                    *Required
                                                                    formats are
                                                                    jpg, jpeg
                                                                    and png.
                                                                </label>
                                                                {props.touched
                                                                    .tenth &&
                                                                    props.errors
                                                                        .tenth && (
                                                                        <ErrorMsg
                                                                            msg={
                                                                                props
                                                                                    .errors
                                                                                    .tenth
                                                                            }
                                                                        />
                                                                    )}
                                                            </div>
                                                            <div
                                                                id="selected-10th-photo"
                                                                className="my-3"
                                                            ></div>
                                                            {merit_docs.tenth_marksheet ? (
                                                                <div className="text-center">
                                                                    <a
                                                                        className="font-bold font-xl"
                                                                        target="_blank"
                                                                        href={`${baseURL}/merit/docs/${merit_docs.tenth_marksheet}`}
                                                                    >
                                                                        View
                                                                        Uploaded
                                                                        File
                                                                    </a>
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    )}

                                                {settings.field_settings &&
                                                    settings.field_settings
                                                        .twelfth_marksheet_doc ===
                                                        1 && (
                                                        <div className="form-input">
                                                            <div className="w-full">
                                                                {/* {merit_docs.appeared_exam && merit_docs.appeared_exam.exam_name ? merit_docs.appeared_exam.exam_name : null} */}
                                                                <label>
                                                                    Upload
                                                                    Marksheet of
                                                                    12th
                                                                </label>
                                                                <div className="text-input mt-2">
                                                                    <input
                                                                        type="file"
                                                                        accept="image/*"
                                                                        name="twelfth"
                                                                        className={`appearance-none block w-full py-2 px-3 border border-gray-300 rounded`}
                                                                        onChange={(
                                                                            event
                                                                        ) =>
                                                                            this.handleFileSelect(
                                                                                event,
                                                                                props,
                                                                                "twelfth",
                                                                                "selected-12th-photo"
                                                                            )
                                                                        }
                                                                    />
                                                                </div>
                                                                <label
                                                                    style={{
                                                                        color: "red",
                                                                    }}
                                                                >
                                                                    *Maximum
                                                                    upload size
                                                                    is 200kb
                                                                </label>
                                                                <br />
                                                                <label
                                                                    style={{
                                                                        color: "red",
                                                                    }}
                                                                >
                                                                    *Required
                                                                    formats are
                                                                    jpg, jpeg
                                                                    and png.
                                                                </label>
                                                                {props.touched
                                                                    .twelfth &&
                                                                    props.errors
                                                                        .twelfth && (
                                                                        <ErrorMsg
                                                                            msg={
                                                                                props
                                                                                    .errors
                                                                                    .twelfth
                                                                            }
                                                                        />
                                                                    )}
                                                            </div>
                                                            <div
                                                                id="selected-12th-photo"
                                                                className="my-3"
                                                            ></div>
                                                            {merit_docs.twelfth_marksheet ? (
                                                                <div className="text-center">
                                                                    <a
                                                                        className="font-bold font-xl"
                                                                        target="_blank"
                                                                        href={`${baseURL}/merit/docs/${merit_docs.twelfth_marksheet}`}
                                                                    >
                                                                        View
                                                                        Uploaded
                                                                        File
                                                                    </a>
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    )}

                                                {merit_docs.degree_exam &&
                                                merit_docs.degree_exam
                                                    .completed == 1 &&
                                                settings.field_settings &&
                                                settings.field_settings
                                                    .degree_marksheet_doc ===
                                                    1 ? (
                                                    <div className="form-input">
                                                        <div className="w-full">
                                                            {/* {merit_docs.appeared_exam && merit_docs.appeared_exam.exam_name ? merit_docs.appeared_exam.exam_name : null} */}
                                                            <label>
                                                                Upload Marksheet
                                                                of Degree
                                                            </label>
                                                            <div className="text-input mt-2">
                                                                <input
                                                                    type="file"
                                                                    accept="image/*"
                                                                    name="marksheet"
                                                                    className={`appearance-none block w-full py-2 px-3 border border-gray-300 rounded`}
                                                                    onChange={(
                                                                        event
                                                                    ) =>
                                                                        this.handleFileSelect(
                                                                            event,
                                                                            props,
                                                                            "marksheet",
                                                                            "selected-degree-photo"
                                                                        )
                                                                    }
                                                                />
                                                            </div>
                                                            <label
                                                                style={{
                                                                    color: "red",
                                                                }}
                                                            >
                                                                *Maximum upload
                                                                size is 200kb
                                                            </label>
                                                            <br />
                                                            <label
                                                                style={{
                                                                    color: "red",
                                                                }}
                                                            >
                                                                *Required
                                                                formats are jpg,
                                                                jpeg and png.
                                                            </label>
                                                            {props.touched
                                                                .marksheet &&
                                                                props.errors
                                                                    .marksheet && (
                                                                    <ErrorMsg
                                                                        msg={
                                                                            props
                                                                                .errors
                                                                                .marksheet
                                                                        }
                                                                    />
                                                                )}
                                                        </div>
                                                        <div
                                                            id="selected-degree-photo"
                                                            className="my-3"
                                                        ></div>
                                                        {merit_docs.merit_marksheet ? (
                                                            <div className="text-center">
                                                                <a
                                                                    className="font-bold font-xl"
                                                                    target="_blank"
                                                                    href={`${baseURL}/merit/docs/${merit_docs.merit_marksheet}`}
                                                                >
                                                                    View
                                                                    Uploaded
                                                                    File
                                                                </a>
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                ) : null}

                                                {community_data.community &&
                                                (community_data.community > 1 ||
                                                    stepTwo.ews === "Y") &&
                                                settings.field_settings &&
                                                settings.field_settings
                                                    .caste_doc === 1 ? (
                                                    <div className="form-input">
                                                        <div className="w-full">
                                                            {/* {merit_docs.appeared_exam && merit_docs.appeared_exam.exam_name ? merit_docs.appeared_exam.exam_name : null} */}
                                                            <label>
                                                                Upload
                                                                Caste/Category/EWS
                                                                Certificate
                                                            </label>
                                                            <div className="text-input mt-2">
                                                                <input
                                                                    type="file"
                                                                    accept="image/*"
                                                                    name="caste"
                                                                    className={`appearance-none block w-full py-2 px-3 border border-gray-300 rounded`}
                                                                    onChange={(
                                                                        event
                                                                    ) =>
                                                                        this.handleFileSelect(
                                                                            event,
                                                                            props,
                                                                            "caste",
                                                                            "selected-caste-photo"
                                                                        )
                                                                    }
                                                                />
                                                            </div>
                                                            <label
                                                                style={{
                                                                    color: "red",
                                                                }}
                                                            >
                                                                *Maximum upload
                                                                size is 200kb
                                                            </label>
                                                            <br />
                                                            <label
                                                                style={{
                                                                    color: "red",
                                                                }}
                                                            >
                                                                *Required
                                                                formats are jpg,
                                                                jpeg and png.
                                                            </label>
                                                            {props.touched
                                                                .caste &&
                                                                props.errors
                                                                    .caste && (
                                                                    <ErrorMsg
                                                                        msg={
                                                                            props
                                                                                .errors
                                                                                .caste
                                                                        }
                                                                    />
                                                                )}
                                                        </div>
                                                        <div
                                                            id="selected-caste-photo"
                                                            className="my-3"
                                                        ></div>
                                                        {community_data.certificate_file ? (
                                                            <div className="text-center">
                                                                <a
                                                                    className="font-bold font-xl"
                                                                    target="_blank"
                                                                    href={`${baseURL}/merit/docs/${community_data.certificate_file}`}
                                                                >
                                                                    View
                                                                    Uploaded
                                                                    File
                                                                </a>
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                ) : null}

                                                {stepTwo &&
                                                stepTwo.kashmiri_migrant ===
                                                    "Y" &&
                                                settings.field_settings &&
                                                settings.field_settings
                                                    .kashmiri_doc === 1 ? (
                                                    <div className="form-input">
                                                        <div className="w-full">
                                                            {/* {merit_docs.appeared_exam && merit_docs.appeared_exam.exam_name ? merit_docs.appeared_exam.exam_name : null} */}
                                                            <label>
                                                                Upload Kashmiri
                                                                Migrant
                                                                Certificate
                                                            </label>
                                                            <div className="text-input mt-2">
                                                                <input
                                                                    type="file"
                                                                    accept="image/*"
                                                                    name="kashmiri"
                                                                    className={`appearance-none block w-full py-2 px-3 border border-gray-300 rounded`}
                                                                    onChange={(
                                                                        event
                                                                    ) =>
                                                                        this.handleFileSelect(
                                                                            event,
                                                                            props,
                                                                            "kashmiri",
                                                                            "selected-kashmiri-photo"
                                                                        )
                                                                    }
                                                                />
                                                            </div>
                                                            <label
                                                                style={{
                                                                    color: "red",
                                                                }}
                                                            >
                                                                *Maximum upload
                                                                size is 200kb
                                                            </label>
                                                            <br />
                                                            <label
                                                                style={{
                                                                    color: "red",
                                                                }}
                                                            >
                                                                *Required
                                                                formats are jpg,
                                                                jpeg and png.
                                                            </label>
                                                            {props.touched
                                                                .kashmiri &&
                                                                props.errors
                                                                    .kashmiri && (
                                                                    <ErrorMsg
                                                                        msg={
                                                                            props
                                                                                .errors
                                                                                .kashmiri
                                                                        }
                                                                    />
                                                                )}
                                                        </div>
                                                        <div
                                                            id="selected-kashmiri-photo"
                                                            className="my-3"
                                                        ></div>
                                                        {kashmiri_data.certificate_file ? (
                                                            <div className="text-center">
                                                                <a
                                                                    className="font-bold font-xl"
                                                                    target="_blank"
                                                                    href={`${baseURL}/merit/docs/${kashmiri_data.certificate_file}`}
                                                                >
                                                                    View
                                                                    Uploaded
                                                                    File
                                                                </a>
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                ) : null}

                                                {stepTwo &&
                                                stepTwo.ncc === "Y" &&
                                                settings.field_settings &&
                                                settings.field_settings
                                                    .ncc_doc === 1 ? (
                                                    <div className="form-input">
                                                        <div className="w-full">
                                                            {/* {merit_docs.appeared_exam && merit_docs.appeared_exam.exam_name ? merit_docs.appeared_exam.exam_name : null} */}
                                                            <label>
                                                                Upload NCC
                                                                Certificate
                                                            </label>
                                                            <div className="text-input mt-2">
                                                                <input
                                                                    type="file"
                                                                    accept="image/*"
                                                                    name="ncc_certificate"
                                                                    className={`appearance-none block w-full py-2 px-3 border border-gray-300 rounded`}
                                                                    onChange={(
                                                                        event
                                                                    ) =>
                                                                        this.handleFileSelect(
                                                                            event,
                                                                            props,
                                                                            "ncc_certificate",
                                                                            "selected-ncc-photo"
                                                                        )
                                                                    }
                                                                />
                                                            </div>
                                                            <label
                                                                style={{
                                                                    color: "red",
                                                                }}
                                                            >
                                                                *Maximum upload
                                                                size is 200kb
                                                            </label>
                                                            <br />
                                                            <label
                                                                style={{
                                                                    color: "red",
                                                                }}
                                                            >
                                                                *Required
                                                                formats are jpg,
                                                                jpeg and png.
                                                            </label>
                                                            {props.touched
                                                                .ncc_certificate &&
                                                                props.errors
                                                                    .ncc_certificate && (
                                                                    <ErrorMsg
                                                                        msg={
                                                                            props
                                                                                .errors
                                                                                .ncc_certificate
                                                                        }
                                                                    />
                                                                )}
                                                        </div>
                                                        <div
                                                            id="selected-ncc-photo"
                                                            className="my-3"
                                                        ></div>
                                                        {ncc_data.ncc_certificate_file ? (
                                                            <div className="text-center">
                                                                <a
                                                                    className="font-bold font-xl"
                                                                    target="_blank"
                                                                    href={`${baseURL}/merit/docs/${ncc_data.ncc_certificate_file}`}
                                                                >
                                                                    View
                                                                    Uploaded
                                                                    File
                                                                </a>
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                ) : null}

                                                {stepTwo &&
                                                stepTwo.nss === "Y" &&
                                                nss_data.hours < 3 &&
                                                settings.field_settings &&
                                                settings.field_settings
                                                    .nss_doc === 1 ? (
                                                    <div className="form-input">
                                                        <div className="w-full">
                                                            {/* {merit_docs.appeared_exam && merit_docs.appeared_exam.exam_name ? merit_docs.appeared_exam.exam_name : null} */}
                                                            <label>
                                                                Upload NSS
                                                                Certificate
                                                            </label>
                                                            <div className="text-input mt-2">
                                                                <input
                                                                    type="file"
                                                                    accept="image/*"
                                                                    name="nss_certificate"
                                                                    className={`appearance-none block w-full py-2 px-3 border border-gray-300 rounded`}
                                                                    onChange={(
                                                                        event
                                                                    ) =>
                                                                        this.handleFileSelect(
                                                                            event,
                                                                            props,
                                                                            "nss_certificate",
                                                                            "selected-nss-photo"
                                                                        )
                                                                    }
                                                                />
                                                            </div>
                                                            <label
                                                                style={{
                                                                    color: "red",
                                                                }}
                                                            >
                                                                *Maximum upload
                                                                size is 200kb
                                                            </label>
                                                            <br />
                                                            <label
                                                                style={{
                                                                    color: "red",
                                                                }}
                                                            >
                                                                *Required
                                                                formats are jpg,
                                                                jpeg and png.
                                                            </label>
                                                            {props.touched
                                                                .nss_certificate &&
                                                                props.errors
                                                                    .nss_certificate && (
                                                                    <ErrorMsg
                                                                        msg={
                                                                            props
                                                                                .errors
                                                                                .nss_certificate
                                                                        }
                                                                    />
                                                                )}
                                                        </div>
                                                        <div
                                                            id="selected-nss-photo"
                                                            className="my-3"
                                                        ></div>
                                                        {nss_data.nss_certificate_file ? (
                                                            <div className="text-center">
                                                                <a
                                                                    className="font-bold font-xl"
                                                                    target="_blank"
                                                                    href={`${baseURL}/merit/docs/${nss_data.nss_certificate_file}`}
                                                                >
                                                                    View
                                                                    Uploaded
                                                                    File
                                                                </a>
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                ) : null}

                                                {stepTwo &&
                                                stepTwo.serviceman === "Y" &&
                                                settings.field_settings &&
                                                settings.field_settings
                                                    .ex_serviceman_doc === 1 ? (
                                                    <div className="form-input">
                                                        <div className="w-full">
                                                            {/* {merit_docs.appeared_exam && merit_docs.appeared_exam.exam_name ? merit_docs.appeared_exam.exam_name : null} */}
                                                            <label>
                                                                Upload
                                                                Ex-Serviceman
                                                                Certificate
                                                            </label>
                                                            <div className="text-input mt-2">
                                                                <input
                                                                    type="file"
                                                                    accept="image/*"
                                                                    name="serviceman"
                                                                    className={`appearance-none block w-full py-2 px-3 border border-gray-300 rounded`}
                                                                    onChange={(
                                                                        event
                                                                    ) =>
                                                                        this.handleFileSelect(
                                                                            event,
                                                                            props,
                                                                            "serviceman",
                                                                            "selected-serviceman-photo"
                                                                        )
                                                                    }
                                                                />
                                                            </div>
                                                            <label
                                                                style={{
                                                                    color: "red",
                                                                }}
                                                            >
                                                                *Maximum upload
                                                                size is 200kb
                                                            </label>
                                                            <br />
                                                            <label
                                                                style={{
                                                                    color: "red",
                                                                }}
                                                            >
                                                                *Required
                                                                formats are jpg,
                                                                jpeg and png.
                                                            </label>
                                                            {props.touched
                                                                .serviceman &&
                                                                props.errors
                                                                    .serviceman && (
                                                                    <ErrorMsg
                                                                        msg={
                                                                            props
                                                                                .errors
                                                                                .serviceman
                                                                        }
                                                                    />
                                                                )}
                                                        </div>
                                                        <div
                                                            id="selected-serviceman-photo"
                                                            className="my-3"
                                                        ></div>
                                                        {serviceman_data.certificate_file ? (
                                                            <div className="text-center">
                                                                <a
                                                                    className="font-bold font-xl"
                                                                    target="_blank"
                                                                    href={`${baseURL}/merit/docs/${serviceman_data.certificate_file}`}
                                                                >
                                                                    View
                                                                    Uploaded
                                                                    File
                                                                </a>
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                ) : null}

                                                {stepTwo &&
                                                stepTwo.supernumerary === "Y" &&
                                                settings.field_settings &&
                                                settings.field_settings
                                                    .supernumerary_doc === 1 ? (
                                                    <div className="form-input">
                                                        <div className="w-full">
                                                            {/* {merit_docs.appeared_exam && merit_docs.appeared_exam.exam_name ? merit_docs.appeared_exam.exam_name : null} */}
                                                            <label>
                                                                Upload
                                                                Supernumerary
                                                                Certificate
                                                            </label>
                                                            <div className="text-input mt-2">
                                                                <input
                                                                    type="file"
                                                                    accept="image/*"
                                                                    name="supernumerary"
                                                                    className={`appearance-none block w-full py-2 px-3 border border-gray-300 rounded`}
                                                                    onChange={(
                                                                        event
                                                                    ) =>
                                                                        this.handleFileSelect(
                                                                            event,
                                                                            props,
                                                                            "supernumerary",
                                                                            "selected-supernumerary-photo"
                                                                        )
                                                                    }
                                                                />
                                                            </div>
                                                            <label
                                                                style={{
                                                                    color: "red",
                                                                }}
                                                            >
                                                                *Maximum upload
                                                                size is 200kb
                                                            </label>
                                                            <br />
                                                            <label
                                                                style={{
                                                                    color: "red",
                                                                }}
                                                            >
                                                                *Required
                                                                formats are jpg,
                                                                jpeg and png.
                                                            </label>
                                                            {props.touched
                                                                .supernumerary &&
                                                                props.errors
                                                                    .supernumerary && (
                                                                    <ErrorMsg
                                                                        msg={
                                                                            props
                                                                                .errors
                                                                                .supernumerary
                                                                        }
                                                                    />
                                                                )}
                                                        </div>
                                                        <div
                                                            id="selected-supernumerary-photo"
                                                            className="my-3"
                                                        ></div>
                                                        {supernumerary_data.certificate_file ? (
                                                            <div className="text-center">
                                                                <a
                                                                    className="font-bold font-xl"
                                                                    target="_blank"
                                                                    href={`${baseURL}/merit/docs/${supernumerary_data.certificate_file}`}
                                                                >
                                                                    View
                                                                    Uploaded
                                                                    File
                                                                </a>
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                ) : null}
                                            </div>
                                        </div>

                                        <div className="text-center mt-4 mb-4">
                                            <a
                                                href={`${staticURI}/apply/education-details/${merit_docs.application_id}`}
                                                className="mr-2 rgu-btn-light"
                                            >
                                                Go Back
                                            </a>

                                            {isSubmitting ? (
                                                <button
                                                    className="rgu-btn-light"
                                                    type="button"
                                                    disabled
                                                >
                                                    <LoadingBtn />
                                                </button>
                                            ) : (
                                                <button
                                                    className="rgu-btn-light"
                                                    type="submit"
                                                >
                                                    Save &amp; Next
                                                </button>
                                            )}
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        apply: state.apply,
        commonFunctions: state.commonFunctions,
        commonData: state.commonData,
    }
}

export default withRouter(
    connect(mapStateToProps, {
        getMeritMarksheet,
        submitMeritDocs,
        getBasicDetails,
    })(UploadDocs)
)
