import React, { Component } from 'react';
import { connect } from 'react-redux';

import SectionHead from '../../includes/SectionHead';
import Notices from './notices/Notices';
import ImportantLinks from './links/ImportantLinks';
import { fetchNotices } from '../../../../actions';

class NoticeLinks extends Component {

    componentDidMount(){
        this.props.fetchNotices(5);
    }

    render() {
        return (
            <section className="notice-links-section">
                <div className="grid grid-cols-1 md:grid-cols-2">
                    <div className="notices-div px-4 py-16 bg-light-blue">
                        <SectionHead title="Notices" />
                        <Notices />
                    </div>
                    <div className="important-links-div px-4 py-16">
                        <SectionHead title="Important Links" />
                        <ImportantLinks />
                    </div>
                </div>
            </section>
        )
    }
}

const mapStateToProps = state => {
    return {
        appData: state.appData
    }
}

export default connect(mapStateToProps, { fetchNotices })(NoticeLinks);