import React, { Component } from 'react';
import {Field} from 'formik';
import { getIn } from 'formik';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import ErrorMsg from '../../includes/form-inputs/ErrorMsg';

class Ncc extends Component {

    myTextInput = ({ name, field, placeholder, type, readonly }) => {

        const disabled = readonly ? true : false;

        return (
            <div className="w-full">
                <label>{placeholder}</label>
                <div className="text-input mt-2">
                    <input placeholder={placeholder} type={type} name={name} className={`appearance-none block w-full py-2 px-3 border border-gray-300 rounded`} {...field} readOnly={disabled} />
                </div>
            </div>
        )
    }

    myRadioInput = ({ text, field, name, checked, value, selected }) => {

        const border = checked ? `border border-blue-200` : `border border-gray-200`;

        return (
            <div className={`px-1 sm:px-4 custom-radio-btn ${border}`}>
                <label className="container">
                    {text}
                    <input type="radio" name={name} {...field} value={value} checked={value === selected} />
                    <span className="checkmark"></span>
                </label>
            </div>
        )
    }

    mySelectInput = ({ name, options, field, label }) => {
    
        const selectOptions = options.map(option => {
            return (
                <option value={option.id}>{option.name}</option>
            )
        });
        
        return(
            <div className="select-input">
                <label>{label}</label>
                <select className={`w-full bg-white focus:outline-none py-2 px-4 border border-gray-300 rounded mt-2`} name={name} {...field}>
                    <option value="">Please Select</option>
                    {selectOptions}
                </select>
            </div>
        )
    }

    render() {

        const { props } = this.props;

        const selectData = [{ id: 1, name: 'B'}, { id: 2, name: 'C'}];

        return (
            <div className="section-three px-8 py-4 border-b-gray-300">
                <div className="mb-4">
                    <div>
                        <label>Are you a B/C NCC Certificate Holder? </label>
                        <div className="grid grid-cols-3 gap-4 mt-2">
                            <Field component={this.myRadioInput} name="ncc.ncc" text="Yes" value="Y" selected={props.values.ncc.ncc}></Field>
                            <Field component={this.myRadioInput} name="ncc.ncc" text="No" value="N" selected={props.values.ncc.ncc}></Field>
                        </div>
                        {getIn(props.errors, 'ncc.ncc') && getIn(props.touched, 'ncc.ncc') && <ErrorMsg msg={getIn(props.errors, 'ncc.ncc')} />}
                    </div>
                </div>

                {
                    props.values.ncc.ncc === 'Y' ? 
                    (
                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                            <div className="form-input">
                                <Field component={this.mySelectInput} name="ncc.category" label="Certificate Category" options={selectData}></Field>
                                {getIn(props.errors, 'ncc.category') && getIn(props.touched, 'ncc.category') && <ErrorMsg msg={getIn(props.errors, 'ncc.category')} />}
                            </div>
                            <div className="form-input">
                                <Field component={this.myTextInput} name="ncc.certificate" placeholder="Certificate No" type="text" readonly={false}></Field>
                                {getIn(props.errors, 'ncc.certificate') && getIn(props.touched, 'ncc.certificate') && <ErrorMsg msg={getIn(props.errors, 'ncc.certificate')} />}
                            </div>
                            <div className="form-input">
                                <div className="w-full">
                                    <label>Date Of Issue</label>
                                    <div className="text-input mt-2">
                                        <DatePicker
                                            selected={props.values.ncc.date_of_issue}
                                            onChange={date => props.setFieldValue("ncc.date_of_issue", date)}
                                            peekNextMonth
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            dateFormat="dd-MM-yyyy"
                                            className="appearance-none block w-full py-2 px-3 border border-gray-300 rounded"
                                            placeholderText="Click to select a date"
                                        />
                                        {getIn(props.errors, 'ncc.date_of_issue') && getIn(props.touched, 'ncc.date_of_issue') && <ErrorMsg msg={getIn(props.errors, 'ncc.date_of_issue')} />}
                                    </div>
                                </div>
                            </div>
                            <div className="form-input">
                                <Field component={this.myTextInput} name="ncc.authority" placeholder="Issuing Authority " type="text" readonly={false}></Field>
                                {getIn(props.errors, 'ncc.authority') && getIn(props.touched, 'ncc.authority') && <ErrorMsg msg={getIn(props.errors, 'ncc.authority')} />}
                            </div>
                            
                        </div>
                    ) : null
                }
                
            </div>
        )
    }
}

export default Ncc;