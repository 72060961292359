import React, { Component } from 'react';
import { connect } from 'react-redux';

import VerifyOtp from './VerifyOtp';
import SendOtp from './SendOtp';

class OtpForm extends Component {
    
    render() {

        const { otp } = this.props.bannerForm;

        return (
            otp ? <VerifyOtp /> : <SendOtp />
        )
    }
}

const mapStateToProps = state => {
    return{
        bannerForm: state.bannerForm
    }
}

export default connect( mapStateToProps )(OtpForm);