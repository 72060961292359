import React from 'react'

const SectionHead = ({ title }) => {
    return (
        <div className="section-head text-center">
            <h1 className="font-bold text-2xl mb-4 text-dark-blue">{title}</h1>
        </div>
    )
}

export default SectionHead;