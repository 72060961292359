import React, { Component } from 'react';
import { connect } from 'react-redux';

import OtpForm from './OtpForm';
import RegisterForm from './RegisterFrom';
import EmailForm from './EmailForm';

class BannerForm extends Component {

    render() {

        const { verified, emailVerify } = this.props.bannerForm;

        return (
            <div className="w-4/5 md:w-3/5 lg: w-3/5 xl:w-3/5 form-wrap bg-white px-8 py-8 relative">
                {
                    verified && emailVerify ? <RegisterForm /> : verified && !emailVerify ? <EmailForm /> : <OtpForm /> 
                }
            </div>
        )
    }
}

const mapStateToProps = state => {
    return{
        bannerForm: state.bannerForm
    }
}

export default connect( mapStateToProps )(BannerForm);
