import React from 'react';

import './progressbar.css';

const ProgressBar = ({step}) => {
    return (
        <section className="multi_step_form">  
            <form id="msform"> 

                <ul id="progressbar">
                    <li className={ parseInt(step) >=1 ? `active` : null }>Sign Up</li>
                    <li className={ parseInt(step) >=2 ? `active` : null }>Personal Details</li>
                    <li className={ parseInt(step) >=3 ? `active` : null }>Basic Information</li>
                    <li className={ parseInt(step) >=4 ? `active` : null }>Educational Details</li>
                    <li className={ parseInt(step) >=5 ? `active` : null }>Documents Upload</li>
                    <li className={ parseInt(step) >=5 ? `active` : null }>Declaration</li>
                    <li className={ parseInt(step) >=6 ? `active` : null }>Payment</li>
                </ul>
                
            </form>  
        </section> 
    )
}

export default ProgressBar;