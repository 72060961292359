import React, { Component } from 'react';
import { connect } from 'react-redux';

import VerifyEmail from './VerifyEmail';
import SendEmail from './SendEmail';

class EmailForm extends Component {

    render() {

        const { emailOTP } = this.props.bannerForm;

        return (
            emailOTP ? <VerifyEmail /> : <SendEmail />
        )
    }
}

const mapStateToProps = state => {
    return{
        bannerForm: state.bannerForm,
        commonFunctions: state.commonFunctions,
    }
}

export default connect(mapStateToProps)(EmailForm);