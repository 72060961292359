import React, { Component } from "react"
import { Field } from "formik"
import { getIn } from "formik"
import { connect } from "react-redux"

import ErrorMsg from "../../includes/form-inputs/ErrorMsg"

class BAMajorSubjects extends Component {
    myRadioInput = ({ text, field, name, checked, value, selected }) => {
        const border = checked
            ? `border border-blue-200`
            : `border border-gray-200`

        return (
            <div className={`px-1 sm:px-4 custom-radio-btn ${border}`}>
                <label className="container">
                    {text}
                    <input
                        type="radio"
                        name={name}
                        {...field}
                        value={value}
                        checked={value === selected}
                    />
                    <span className="checkmark"></span>
                </label>
            </div>
        )
    }

    validateField = (value) => {
        let error

        if (
            !value &&
            this.props.commonData.ba_major_codes.includes(
                this.props.props.values.course.course_code
            )
        ) {
            error = "Please select major subject"
        }
        return error
    }

    render() {
        const { props } = this.props

        return (
            <div className="px-8 py-4 border-b-gray-300">
                <div className="">
                    <label>Choose Major Subject :</label>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mt-2">
                    <Field
                        component={this.myRadioInput}
                        name="course.ba_major"
                        text="Anthropology"
                        value="ANT"
                        selected={props.values.course.ba_major}
                        validate={this.validateField}
                    ></Field>
                    <Field
                        component={this.myRadioInput}
                        name="course.ba_major"
                        text="Psychology"
                        value="PSY"
                        selected={props.values.course.ba_major}
                        validate={this.validateField}
                    ></Field>
                    <Field
                        component={this.myRadioInput}
                        name="course.ba_major"
                        text="Sociology"
                        value="SOC"
                        selected={props.values.course.ba_major}
                        validate={this.validateField}
                    ></Field>
                    <Field
                        component={this.myRadioInput}
                        name="course.ba_major"
                        text="Social Work"
                        value="SW"
                        selected={props.values.course.ba_major}
                        validate={this.validateField}
                    ></Field>
                </div>
                {getIn(props.errors, "course.ba_major") &&
                    getIn(props.touched, "course.ba_major") && (
                        <ErrorMsg
                            msg={getIn(props.errors, "course.ba_major")}
                        />
                    )}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        commonData: state.commonData,
    }
}

export default connect(mapStateToProps)(BAMajorSubjects)
