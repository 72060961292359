import React, { Component } from 'react';
import { FiChevronRight } from "react-icons/fi";
import { connect } from 'react-redux';

import PageBanner from '../includes/page-banner/PageBanner';
import PaperItem from './PaperItem';
import { fetchQuestionPapers, getCourses, getDepartments, courseFilter, findQuestionPapers } from '../../../actions';

import './papers.css';

class QuestionPapers extends Component {

    componentDidMount(){
        this.props.fetchQuestionPapers();
        this.props.getCourses();
        this.props.getDepartments();
    }

    searchCourses = () => {
        let department = document.querySelector("#department").value;
        let course = document.querySelector("#course").value;

        this.props.findQuestionPapers(department, course);
    }

    filterCourses = () => {
        let department = document.querySelector("#department").value;
        this.filterValues(this.props.commonData.courses, department);
        this.searchCourses();
    }

    filterValues = (options, department)  => {
        let filteredCourses =  options.filter(function(course) {
            return course.department_id === department;
        });
        this.props.courseFilter(filteredCourses);
    }

    render() {

        const { questionPapers } = this.props.appData;
        const { departments, courses_copy } = this.props.commonData;
        const { isLoading } = this.props.commonFunctions;

        const papers_items = questionPapers && questionPapers.length > 0 ? questionPapers.map( item => { 
            return (
                <PaperItem name={item.title} year="2019" department={item.department.name} image="/icon/education.png" file={item.filename} key={item.id} />
            )
        }) : null;

        return (
            <React.Fragment>
                <PageBanner title="Sample Question Papers" image="/images/page.jpg" />
                <section className="overview-section py-10 bg-light-blue">
                    <div className="container mx-auto px-4">
                        <div className="breadcrumb mb-10">
                            <ul className="breadcrumb-list">
                                <li>Home</li>
                                <li><FiChevronRight /></li>
                                <li>Question Papers</li>
                            </ul>
                        </div>
                        <div className="bg-white p-4 shadow-md mb-4">
                            <div className="w-3/4 mx-auto">
                                <div className="grid grid-cols-2 gap-4">
                                    <div>
                                        <label>Select Department: </label>
                                        <div className="w-full">
                                            <div className="select-input">
                                                <select name="department" onChange={this.filterCourses} id="department" className={`block w-full py-2 px-3 border border-gray-300 rounded`}>
                                                    <option value="">Please Select</option>
                                                    {
                                                    
                                                    departments && departments.length > 0 ? departments.map(option => {
                                                        
                                                        return(<option value={option.id} key={option.id}>{option.title}</option>)
                                                        
                                                    }) : null

                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <label>Select Course: </label>
                                        <div className="w-full">
                                            <div className="select-input">
                                                <select name="course" onChange={this.searchCourses} id="course" className={`block w-full py-2 px-3 border border-gray-300 rounded`}>
                                                    <option value="">Please Select</option>
                                                    {

                                                        courses_copy && courses_copy.length > 0 ? courses_copy.map(option => {
                                                                                                            
                                                            return(<option value={option.id} key={option.id}>{option.title}</option>)
                                                            
                                                        }) : null

                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {
                            questionPapers && questionPapers.length > 0 ? 

                            papers_items : isLoading ? (

                                <div className="text-center">
                                    <img src="/images/spinner.gif" alt="Loading" style={{ width: '50px', margin: '0 auto' }} />
                                    <div className="mt-4">
                                        <h3 className="font-bold tex-xl">Fetching Question Papers</h3>
                                    </div>
                                </div>

                            ) : null
                        }

                    </div>
                </section>
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        commonData: state.commonData,
        commonFunctions: state.commonFunctions,
        appData: state.appData,
    }
}

export default connect( mapStateToProps, { fetchQuestionPapers, getCourses, getDepartments, courseFilter, findQuestionPapers  } )(QuestionPapers);
