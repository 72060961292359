export const validateMobile = (value) => {
    let error;
    if (!value) {
        error = 'Please enter mobile number';
    }else if(value.length !== 10){
        error = 'Invalid mobile number';
    }
    return error;
}

export const validateOTP = (value) => {
    let error;
    if (!value) {
        error = 'Please enter OTP';
    }
    return error;
}

export const validateFname = (value) => {
    let error;
    if (!value) {
        error = 'Please enter your first name';
    }
    return error;
}

export const validateLname = (value) => {
    let error;
    if (!value) {
        error = 'Please enter last name';
    }
    return error;
}

export const validatePassword = (value) => {
    let error;
    if (!value) {
        error = 'Please enter a password';
    }else if(value.length < 6){
        error = 'Password must be atleast 6 characters long';
    }
    return error;
}

export const validateEmail = (value) => {
    let error;

    if (!value) {
        error = 'Please enter your email';
      } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
        error = 'Invalid email address';
      }
    return error;
}