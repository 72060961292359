import React from 'react';
import { Link } from 'react-router-dom';

import './message-item.css';

const MessageItem = ({ image, subject, message, id }) => {
    return (
        <Link to={`/conversation/${id}`}>
            <div className="message-item px-4 py-2 bg-gray-100 border border-gray-300 hover:shadow-md mb-3">
                <div className="flex">
                    <div className="flex-initial self-center">
                        <img src={image} alt="user-icon" className="rounded-full" />
                    </div>
                    <div className="flex-1 ml-4 self-center">
                        <h3 className="text-lg font-medium">
                            {subject}
                        </h3>
                        <p className="">
                            {message}
                        </p>
                    </div>
                </div>
            </div>
        </Link>
    )
}

export default MessageItem;