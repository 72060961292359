import React from 'react';

import './page-banner.css';

const PageBanner = ({ title, image, position }) => {
    return (
        <section className={`page-banner`} style={{ backgroundImage: `url(${image})` }}>
            <div className="image-overlay">
                <div className={`banner-content ${position}`}>
                    <div className="content-inner w-3/4 text-center mx-auto">
                        <div className="text-lg lg:text-2xl md:text-2xl sm:text-xl">
                            {title}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default PageBanner;
