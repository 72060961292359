import React, { Component } from "react"
import { FiChevronRight, FiMail } from "react-icons/fi"
import { FaWhatsapp, FaPhone } from "react-icons/fa"

import { scrollToTop } from "../includes/jsFunctions"
import PageBanner from "../includes/page-banner/PageBanner"
import { connect } from "react-redux"

class ContactUs extends Component {
    componentDidMount() {
        scrollToTop()
    }

    render() {
        const { phone, email, whatsapp } = this.props.settings

        return (
            <React.Fragment>
                <PageBanner title="Contact Us" image="/images/page.jpg" />
                <section className="overview-section py-10 bg-light-blue">
                    <div className="container mx-auto px-4">
                        <div className="breadcrumb mb-10">
                            <ul className="breadcrumb-list">
                                <li>Home</li>
                                <li>
                                    <FiChevronRight />
                                </li>
                                <li>Contact Us</li>
                            </ul>
                        </div>

                        <h1 className="text-center font-bold text-lg md:text-xl">
                            CONTACT US
                        </h1>

                        <div className="grid grid-cols-2 mt-3 mb-4">
                            <a
                                href={`tel:+91${phone}`}
                                className="bg-white p-4 shadow-md mb-4 mx-2 text-center flex justify-center"
                            >
                                <span className="mt-1">
                                    <FaPhone />
                                </span>
                                &emsp;{phone}
                            </a>
                            <a
                                href={`https://wa.me/91${whatsapp}`}
                                className="bg-white p-4 shadow-md mb-4 mx-2 text-center flex justify-center"
                            >
                                <span className="mt-1">
                                    <FaWhatsapp />
                                </span>
                                &emsp;{whatsapp}
                            </a>
                        </div>

                        {email && (
                            <>
                                <hr />
                                <h1 className="text-center font-bold text-lg md:text-xl mt-4">
                                    EMAIL US
                                </h1>
                                <div className="flex justify-center mt-3">
                                    <a
                                        href={`mailto:${email}`}
                                        className="bg-white p-4 shadow-md mb-4 mx-2 text-center flex justify-center"
                                    >
                                        <span className="mt-1">
                                            <FiMail />
                                        </span>
                                        &emsp;{email}
                                    </a>
                                </div>
                            </>
                        )}
                    </div>
                </section>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        settings: state.commonData.settings,
    }
}

export default connect(mapStateToProps, {})(ContactUs)
