import React from 'react'

const ResetSuccess = () => {
    return (
        <div className="success-reset-password text-center">
            <img src="/icon/success.gif" style={{ margin: '0 auto', width: '50%' }} />
            <div className="mt-3">
                <h1 className="uppercase font-bold text-dark-blue text-xl md:text-2xl lg:text-2xl xl:text-2xl">Password Successfully Changed</h1>
                <p className="text-gray-600 text-lg mt-4">
                    Login with your new password
                </p>
            </div>
        </div>
    )
}

export default ResetSuccess;
