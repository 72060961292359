import React from 'react';

import './tabs-nav.css';
import { openTab } from '../../../includes/jsFunctions';

const TabsNav = () => {
    return (
        <div className="flex justify-center tabs-nav">
            <div className="tabs-one active" id="tab-one">
                <button className="tabs-nav-btn appearance-none" onClick={() => openTab('facilities', 'tab-one', 'tabs-section-one', 'tabs-one')}>
                    Campus Facilities
                </button>
                <div className="triangle"></div>
            </div>
            <div className="tabs-one" id="tab-two">
                <button className="tabs-nav-btn appearance-none" onClick={() => openTab('eligibility', 'tab-two', 'tabs-section-one', 'tabs-one')}>
                    Eligibility Criteria
                </button>
                <div className="triangle"></div>
            </div>
            <div className="tabs-one" id="tab-three">
                <button className="tabs-nav-btn appearance-none" onClick={() => openTab('faqs', 'tab-three', 'tabs-section-one', 'tabs-one')}>
                    FAQ's
                </button>
                <div className="triangle"></div>
            </div>
        </div>
    )
}

export default TabsNav;
