import React, { Component } from "react"
import { Form, Formik } from "formik"
import validate from "./validationYup"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

import {
    submitEducationQualification,
    getEducationDetails,
    editEducationQualification,
    submitEduPageDetails,
    boardNames,
} from "../../../../actions"
import ApplyBanner from "../../includes/apply-banner/ApplyBanner"
import Qualification from "./Qualification"
import AddedQualifications from "./AddedQualifications"
import validateEdu from "./validationYupTwo"
import Student from "./Student"
import Gate from "./Gate"
import Employment from "./Employment"
import NetSletJrf from "./NetSletJrf"
import ProgressBar from "../../dashboard/progressbar/ProgressBar"
import Discipline from "./Discipline"
import Preference from "./Preference"
import LoadingBtn from "../../includes/btn/LoadingBtn"
import { preference_codes, staticURI } from "../../includes/jsFunctions"
import MajorSubjects from "./MajorSubjects"
import BAMajorSubjects from "./BAMajorSubjects"

class EduDetails extends Component {
    componentDidMount() {
        this.props.getEducationDetails(this.props.match.params.id)
        this.props.boardNames()
    }

    submitEduDetailsFrom = (values, actions) => {
        this.props.submitEducationQualification(values, actions)
    }

    editEduDetailsFrom = (values, actions, id) => {
        this.props.editEducationQualification(values, actions, id)
    }

    submitEduPageFrom = (values, actions) => {
        this.props.submitEduPageDetails(values, actions)
    }

    render() {
        const { loginLoading, login } = this.props.user
        const { isSubmitting } = this.props.commonFunctions
        const { stepThree, editId, qualifications, status } = this.props.apply
        const {
            net_slet_jrf_codes,
            gate_codes,
            bed_codes,
            major_codes,
            ba_major_codes,
        } = this.props.commonData

        if (!loginLoading && !login) {
            window.location.href = `${staticURI}/`
        }

        if (stepThree.status === "Y" && stepThree.enabled_edit == 0) {
            window.location.href = `${staticURI}/dashboard`
        }

        if (status) {
            window.location.href = `${staticURI}/apply/documents-upload/${stepThree.application_id}`
        }

        const rgu_std_data =
            stepThree && stepThree.rgu_std_details
                ? stepThree.rgu_std_details
                : {}
        const employment_data =
            stepThree && stepThree.employment_details
                ? stepThree.employment_details
                : {}
        const net_data =
            stepThree && stepThree.net_slet_jrf ? stepThree.net_slet_jrf : []

        let initialValues = {
            edu: {
                exam: "",
                exam_name: "",
                board: "",
                roll: "",
                reg_no: "",
                year: "",
                total_marks: "",
                marks_secured: "",
                percentage: "",
                subjects: [],
                status: "",
            },
            application_id: stepThree.application_id,
        }

        if (editId) {
            let selectedItem = qualifications.find(
                (element) => element.id === parseInt(editId)
            )

            let subjects_array = []

            selectedItem.subjects.map((subject) => {
                subjects_array.push(subject.subject)
            })

            initialValues = {
                edu: {
                    exam: selectedItem.exam,
                    exam_name: selectedItem.exam_name,
                    board: selectedItem.board,
                    roll: selectedItem.roll_no,
                    reg_no: selectedItem.registration_no,
                    year: selectedItem.passing_year,
                    total_marks: selectedItem.marks,
                    marks_secured: selectedItem.marks_secured,
                    percentage: selectedItem.percentage,
                    subjects: subjects_array,
                    status: selectedItem.completed,
                },
                application_id: stepThree.application_id,
            }
        }

        let noc_date =
            employment_data && employment_data.noc_issue_date
                ? new Date(employment_data.noc_issue_date)
                : ""

        let net_slet_jrf_array = []

        if (net_data.length > 0) {
            net_data.map((net_row) => {
                net_slet_jrf_array.push({
                    qualification: net_row.qualification,
                    certificate: net_row.certficate_no,
                    date_of_issue: net_row.date ? new Date(net_row.date) : "",
                    authority: net_row.authority,
                })
            })
        } else {
            net_slet_jrf_array = [
                {
                    qualification: "",
                    certificate: "",
                    date_of_issue: "",
                    authority: "",
                },
            ]
        }

        return (
            <React.Fragment>
                <ApplyBanner title="Educational Details" />

                <section
                    className="personal-details-page py-16"
                    style={{ backgroundColor: "#F5FBFF" }}
                >
                    <ProgressBar step="3" />
                    <div className="container mx-auto px-8 mt-32">
                        <div className="form-wrap shadow-md bg-white pb-4 rounded-md">
                            <Formik
                                initialValues={initialValues}
                                onSubmit={(values, actions) => {
                                    if (editId) {
                                        this.editEduDetailsFrom(
                                            values,
                                            actions,
                                            editId
                                        )
                                    } else {
                                        this.submitEduDetailsFrom(
                                            values,
                                            actions
                                        )
                                    }
                                }}
                                enableReinitialize={true}
                                //validate = {validate}
                                validationSchema={validate}
                            >
                                {(props: FormikProps<any>) => (
                                    <Form>
                                        <Qualification props={props} />

                                        <div className="text-center mt-4 mb-4">
                                            {isSubmitting ? (
                                                <button
                                                    className="rgu-btn-light"
                                                    type="button"
                                                    disabled
                                                >
                                                    <LoadingBtn />
                                                </button>
                                            ) : (
                                                <button
                                                    className="rgu-btn-light"
                                                    type="submit"
                                                >
                                                    Save &amp; Next
                                                </button>
                                            )}
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>

                        <AddedQualifications />

                        <div className="form-wrap shadow-md bg-white pb-4 rounded-md">
                            <Formik
                                initialValues={{
                                    course: {
                                        course_id: stepThree.course_id
                                            ? stepThree.course_id
                                            : "",
                                        course_code: stepThree.course
                                            ? stepThree.course.code
                                            : "",
                                        //course_code: '1303',
                                        course_category: stepThree.course
                                            ? stepThree.course.category
                                            : "",
                                        one: stepThree.preference_one
                                            ? stepThree.preference_one
                                            : "",
                                        two: stepThree.preference_two
                                            ? stepThree.preference_two
                                            : "",
                                        net: stepThree.net_qualified
                                            ? stepThree.net_qualified
                                            : "",
                                        net_qualifications: net_slet_jrf_array,
                                        bed_discipline: stepThree.discipline
                                            ? stepThree.discipline
                                            : "",
                                        gate: {
                                            qualified: stepThree.gate
                                                ? stepThree.gate
                                                : "",
                                            year: stepThree.gate_year
                                                ? stepThree.gate_year
                                                : "",
                                            score: stepThree.gate_score
                                                ? stepThree.gate_score
                                                : "",
                                        },
                                        exam_center: stepThree.exam_center_id
                                            ? stepThree.exam_center_id
                                            : "",
                                        major: stepThree.major
                                            ? stepThree.major
                                            : "",
                                        ba_major: stepThree.ba_major
                                            ? stepThree.ba_major
                                            : "",
                                    },
                                    course_code: stepThree.course
                                        ? stepThree.course.code
                                        : "",

                                    student: {
                                        isStudent: stepThree.rgu_student,
                                        college: rgu_std_data.college,
                                        course: rgu_std_data.course,
                                        reg_no: rgu_std_data.regd_no,
                                        year: rgu_std_data.year,
                                    },
                                    employment: {
                                        employed: stepThree.employed,
                                        employer: employment_data.employer,
                                        designation:
                                            employment_data.designation,
                                        status: employment_data.status,
                                        noc: employment_data.noc,
                                        noc_certificate:
                                            employment_data.noc_certificate_no
                                                ? employment_data.noc_certificate_no
                                                : "",
                                        noc_date_of_issue: noc_date,
                                        noc_authority:
                                            employment_data.noc_issue_authority
                                                ? employment_data.noc_issue_authority
                                                : "",
                                    },
                                    application_id: stepThree.application_id,
                                }}
                                onSubmit={(values, actions) => {
                                    this.submitEduPageFrom(values, actions)
                                }}
                                enableReinitialize={true}
                                //validate = {validate}
                                validationSchema={validateEdu}
                            >
                                {(props: FormikProps<any>) => (
                                    <Form>
                                        {console.log(props.errors)}

                                        <Student props={props} />

                                        {net_slet_jrf_codes != undefined &&
                                        net_slet_jrf_codes.includes(
                                            props.values.course.course_code
                                        ) ? (
                                            <NetSletJrf props={props} />
                                        ) : null}

                                        {gate_codes.includes(
                                            props.values.course.course_code
                                        ) ? (
                                            <Gate props={props} />
                                        ) : null}

                                        <Employment props={props} />

                                        {bed_codes.includes(
                                            props.values.course.course_code
                                        ) ? (
                                            <Discipline props={props} />
                                        ) : null}

                                        {preference_codes.includes(
                                            props.values.course.course_code
                                        ) ? (
                                            <Preference props={props} />
                                        ) : null}

                                        {major_codes.includes(
                                            props.values.course.course_code
                                        ) ? (
                                            <MajorSubjects props={props} />
                                        ) : null}

                                        {ba_major_codes.includes(
                                            props.values.course.course_code
                                        ) ? (
                                            <BAMajorSubjects props={props} />
                                        ) : null}

                                        <div className="text-red-500 my-3 text-center">
                                            {props.errors.general}
                                        </div>

                                        <div className="text-center mt-4 mb-4">
                                            <a
                                                href={`${staticURI}/apply/basic-details/${stepThree.application_id}`}
                                                className="mr-2 rgu-btn-light"
                                            >
                                                Go Back
                                            </a>

                                            {isSubmitting ? (
                                                <button
                                                    className="rgu-btn-light"
                                                    type="button"
                                                    disabled
                                                >
                                                    <LoadingBtn />
                                                </button>
                                            ) : (
                                                <button
                                                    className="rgu-btn-light"
                                                    type="submit"
                                                >
                                                    Save &amp; Next
                                                </button>
                                            )}
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        apply: state.apply,
        commonFunctions: state.commonFunctions,
        commonData: state.commonData,
    }
}

export default withRouter(
    connect(mapStateToProps, {
        submitEducationQualification,
        getEducationDetails,
        editEducationQualification,
        submitEduPageDetails,
        boardNames,
    })(EduDetails)
)
