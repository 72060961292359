import React, { Component } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import * as DOMPurify from "dompurify"

import PageBanner from "../includes/page-banner/PageBanner"
import { FiChevronRight } from "react-icons/fi"
import { getCmsPage } from "../../../actions"

class CmsPage extends Component {
    componentDidMount() {
        this.props.getCmsPage(this.props.match.params.slug)
    }

    render() {
        const { cms_page_details } = this.props.commonData

        const clean = DOMPurify.sanitize(cms_page_details.content)

        return (
            <React.Fragment>
                <PageBanner
                    title={cms_page_details.title}
                    image="/images/page.jpg"
                />
                <section className="overview-section py-10 bg-light-blue">
                    <div className="container mx-auto px-4">
                        <div className="breadcrumb mb-10">
                            <ul className="breadcrumb-list">
                                <li>Home</li>
                                <li>
                                    <FiChevronRight />
                                </li>
                                <li>{cms_page_details.title}</li>
                            </ul>
                        </div>
                        <div className="bg-white p-4 shadow-md mb-4">
                            <h1 className="text-center font-bold text-lg md:text-xl uppercase">
                                {cms_page_details.title}
                            </h1>

                            <div
                                className="mt-3"
                                dangerouslySetInnerHTML={{ __html: clean }}
                            ></div>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        commonData: state.commonData,
    }
}

export default withRouter(
    connect(mapStateToProps, {
        getCmsPage,
    })(CmsPage)
)
