import * as Yup from 'yup';

const validate = Yup.object().shape({
    // if(!values.father){
    //     errors.father = "Father's name is required";
    // }

    father: Yup.string()
    .min(2, 'Too Short!')
    .required("Father's name is required")
    .nullable(),

    mother: Yup.string()
    .min(2, 'Too Short!')
    .required("Mother's name is required")
    .nullable(),

    gender: Yup.string()
    .required("Please select your gender")
    .nullable(),

    dob: Yup.string()
    .required("Select your Date Of Birth")
    .nullable(),

    religion: Yup.string()
    .required("Select your religion")
    .nullable(),

    aadhaar_no: Yup.string()
    .min(12, 'Aadhaar Number must be of 12 digits')
    .max(12, 'Aadhaar Number must be of 12 digits')
    .required("Enter your Aadhaar Number")
    .nullable(),

    other_religion: Yup.string()
    .when('religion', {
        is: religion => parseInt(religion) === 7,
        then: Yup.string()
            .required('Please enter religion name')
            .nullable(),
            otherwise: Yup.string().notRequired()
    }),

    blood_group: Yup.string()
    .required("Select your blood group")
    .nullable(),

    image: Yup.string()
    .required("Select your image")
    .nullable(),

    signature: Yup.string()
    .required("Select your signature")
    .nullable(),

    aadhaar_front: Yup.string()
    .required("Upload Aadhar front")
    .nullable(),

    aadhaar_back: Yup.string()
    .required("Upload Aadhar back")
    .nullable(),

    present_adr: Yup.object().shape({
        adr_line_1: Yup.string()
        .min(2, 'Too Short!')
        .required("Address Line 1 is required"),

        country: Yup.string()
        .required("Please select your country"),

        state: Yup.string()
        .required("Please select your state"),

        city: Yup.string()
        .required("Please select your city"),

        pincode: Yup.string()
        .required("Please enter your pincode"),
    }),

    permanent_adr: Yup.object().shape({
        adr_line_1: Yup.string()
        .min(2, 'Too Short!')
        .required("Address Line 1 is required"),

        country: Yup.string()
        .required("Please select your country"),

        state: Yup.string()
        .required("Please select your state"),

        city: Yup.string()
        .required("Please select your city"),

        pincode: Yup.string()
        .required("Please enter your pincode"),
    })

});

export default validate;