import React, { Component } from "react"
import { Field } from "formik"
import { getIn } from "formik"
import { connect } from "react-redux"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

import ErrorMsg from "../../includes/form-inputs/ErrorMsg"
import { setEWS, setOBC } from "../../../../actions"

class Community extends Component {
    myTextInput = ({ name, field, placeholder, type, readonly }) => {
        const disabled = readonly ? true : false

        return (
            <div className="w-full">
                <label>{placeholder}</label>
                <div className="text-input mt-2">
                    <input
                        placeholder={placeholder}
                        type={type}
                        name={name}
                        className={`appearance-none block w-full py-2 px-3 border border-gray-300 rounded`}
                        {...field}
                        readOnly={disabled}
                    />
                </div>
            </div>
        )
    }

    mySelectInput = ({ name, options, field, label, form }) => {
        const selectOptions = options.map((option) => {
            return (
                <option value={option.id} key={option.id}>
                    {option.name}
                </option>
            )
        })

        return (
            <div className="select-input">
                <label>{label}</label>
                <select
                    className={`w-full bg-white focus:outline-none py-2 px-4 border border-gray-300 rounded mt-2`}
                    name={name}
                    {...field}
                    onChange={(e) => {
                        field.onChange(e)
                        //this.handleCommunity(e, options)

                        const selected = options.find(
                            (c) => c.id == e.target.value
                        )

                        if (selected) {
                            if (selected.is_ews) {
                                form.setFieldValue("community.show_ews", "Y")
                                form.setFieldValue("community.show_obc", "N")
                            } else if (selected.is_obc) {
                                form.setFieldValue("community.show_obc", "Y")
                                form.setFieldValue("community.show_ews", "N")
                            } else {
                                form.setFieldValue("community.show_ews", "N")
                                form.setFieldValue("community.show_obc", "N")
                            }
                        } else {
                            form.setFieldValue("community.show_ews", "N")
                            form.setFieldValue("community.show_obc", "N")
                        }
                    }}
                >
                    <option value="">Please Select</option>
                    {selectOptions}
                </select>
            </div>
        )
    }

    render() {
        const { props } = this.props

        const { settings } = this.props.commonData

        const selectCommunity = settings.castes ? settings.castes : []

        return (
            <div className="section-one px-8 py-4 border-b-gray-300">
                <div className="grid grid-cols-1 sm:grid-cols-2 mb-4">
                    <div>
                        <Field
                            component={this.mySelectInput}
                            name="community.name"
                            label="Community"
                            options={selectCommunity}
                            onChange={(e) => {
                                props.handleChange((e) =>
                                    this.handleCommunity(
                                        props,
                                        e,
                                        selectCommunity
                                    )
                                )
                            }}
                        ></Field>
                        {getIn(props.errors, "community.name") &&
                            getIn(props.touched, "community.name") && (
                                <ErrorMsg
                                    msg={getIn(props.errors, "community.name")}
                                />
                            )}
                    </div>
                </div>
                {props.values.community.name &&
                props.values.community.show_ews === "N" ? (
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                        <div className="form-input">
                            <Field
                                component={this.myTextInput}
                                name="community.caste"
                                placeholder="Caste/Tribe"
                                type="text"
                                readonly={false}
                            ></Field>
                            {getIn(props.errors, "community.caste") &&
                                getIn(props.touched, "community.caste") && (
                                    <ErrorMsg
                                        msg={getIn(
                                            props.errors,
                                            "community.caste"
                                        )}
                                    />
                                )}
                        </div>
                        <div className="form-input">
                            <Field
                                component={this.myTextInput}
                                name="community.certificate"
                                placeholder="Certificate No"
                                type="text"
                                readonly={false}
                            ></Field>
                            {getIn(props.errors, "community.certificate") &&
                                getIn(
                                    props.touched,
                                    "community.certificate"
                                ) && (
                                    <ErrorMsg
                                        msg={getIn(
                                            props.errors,
                                            "community.certificate"
                                        )}
                                    />
                                )}
                        </div>
                        <div className="form-input">
                            <div className="w-full">
                                <label>Date Of Issue</label>
                                <div className="text-input mt-2">
                                    <DatePicker
                                        selected={
                                            props.values.community.date_of_issue
                                        }
                                        onChange={(date) =>
                                            props.setFieldValue(
                                                "community.date_of_issue",
                                                date
                                            )
                                        }
                                        peekNextMonth
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                        dateFormat="dd-MM-yyyy"
                                        className="appearance-none block w-full py-2 px-3 border border-gray-300 rounded"
                                        placeholderText="Click to select a date"
                                    />
                                    {getIn(
                                        props.errors,
                                        "community.date_of_issue"
                                    ) &&
                                        getIn(
                                            props.touched,
                                            "community.date_of_issue"
                                        ) && (
                                            <ErrorMsg
                                                msg={getIn(
                                                    props.errors,
                                                    "community.date_of_issue"
                                                )}
                                            />
                                        )}
                                </div>
                            </div>
                        </div>
                        <div className="form-input">
                            <Field
                                component={this.myTextInput}
                                name="community.authority"
                                placeholder="Issuing Authority "
                                type="text"
                                readonly={false}
                            ></Field>
                            {getIn(props.errors, "community.authority") &&
                                getIn(props.touched, "community.authority") && (
                                    <ErrorMsg
                                        msg={getIn(
                                            props.errors,
                                            "community.authority"
                                        )}
                                    />
                                )}
                        </div>

                        {props.values.community.show_obc === "Y" ? (
                            <React.Fragment>
                                <div className="form-input">
                                    <Field
                                        component={this.myTextInput}
                                        name="community.ncl_certificate_no"
                                        placeholder="NCL Certificate No"
                                        type="text"
                                        readonly={false}
                                    ></Field>
                                    {getIn(
                                        props.errors,
                                        "community.ncl_certificate_no"
                                    ) &&
                                        getIn(
                                            props.touched,
                                            "community.ncl_certificate_no"
                                        ) && (
                                            <ErrorMsg
                                                msg={getIn(
                                                    props.errors,
                                                    "community.ncl_certificate_no"
                                                )}
                                            />
                                        )}
                                </div>
                                <div className="form-input">
                                    <div className="w-full">
                                        <label>NCL Date Of Issue</label>
                                        <div className="text-input mt-2">
                                            <DatePicker
                                                selected={
                                                    props.values.community
                                                        .ncl_date_of_issue
                                                }
                                                onChange={(date) =>
                                                    props.setFieldValue(
                                                        "community.ncl_date_of_issue",
                                                        date
                                                    )
                                                }
                                                peekNextMonth
                                                showMonthDropdown
                                                showYearDropdown
                                                dropdownMode="select"
                                                dateFormat="dd-MM-yyyy"
                                                className="appearance-none block w-full py-2 px-3 border border-gray-300 rounded"
                                                placeholderText="Click to select a date"
                                            />
                                            {getIn(
                                                props.errors,
                                                "community.ncl_date_of_issue"
                                            ) &&
                                                getIn(
                                                    props.touched,
                                                    "community.ncl_date_of_issue"
                                                ) && (
                                                    <ErrorMsg
                                                        msg={getIn(
                                                            props.errors,
                                                            "community.ncl_date_of_issue"
                                                        )}
                                                    />
                                                )}
                                        </div>
                                    </div>
                                </div>
                                <div className="form-input">
                                    <Field
                                        component={this.myTextInput}
                                        name="community.ncl_authority"
                                        placeholder="Issuing Authority of NCL "
                                        type="text"
                                        readonly={false}
                                    ></Field>
                                    {getIn(
                                        props.errors,
                                        "community.ncl_authority"
                                    ) &&
                                        getIn(
                                            props.touched,
                                            "community.ncl_authority"
                                        ) && (
                                            <ErrorMsg
                                                msg={getIn(
                                                    props.errors,
                                                    "community.ncl_authority"
                                                )}
                                            />
                                        )}
                                </div>
                            </React.Fragment>
                        ) : null}
                    </div>
                ) : null}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        commonData: state.commonData,
    }
}

export default connect(mapStateToProps, { setEWS, setOBC })(Community)
