import React from "react"
import { FaStar } from "react-icons/fa"

const BannerText = ({ year, institute }) => {
    return (
        <div className="bg-yellow py-12 px-8">
            <div className="heading font-bold text-6xl line-height">
                {/* Rajiv Gandhi <br/> University */}
                {institute}
            </div>
            <div className="mt-4">
                <div className="mb-2 font-bold text-3xl">
                    Online Admission {year}
                </div>
            </div>
            {/* <div className="mt-4">
                <div className="star-div mb-2 font-bold text-2xl">
                    <span>
                        <FaStar />
                    </span>
                    Recognized By:
                </div>
                <div className="text-lg">
                    UGC, AICTE, INC, PCI, IAP and DST Govt. of India
                </div>
            </div> */}
        </div>
    )
}

export default BannerText
