import React, { Component } from 'react';
import {Field} from 'formik';
import { getIn } from 'formik';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import ErrorMsg from '../../includes/form-inputs/ErrorMsg';

class Disability extends Component {

    myTextInput = ({ name, field, placeholder, type, readonly }) => {

        const disabled = readonly ? true : false;

        return (
            <div className="w-full">
                <label>{placeholder}</label>
                <div className="text-input mt-2">
                    <input placeholder={placeholder} type={type} name={name} className={`appearance-none block w-full py-2 px-3 border border-gray-300 rounded`} {...field} readOnly={disabled} />
                </div>
            </div>
        )
    }

    myRadioInput = ({ text, field, name, checked, value, selected }) => {

        const border = checked ? `border border-blue-200` : `border border-gray-200`;

        return (
            <div className={`px-1 sm:px-4 custom-radio-btn ${border}`}>
                <label className="container">
                    {text}
                    <input type="radio" name={name} {...field} value={value} checked={value === selected} />
                    <span className="checkmark"></span>
                </label>
            </div>
        )
    }

    render() {

        const { props } = this.props;

        return (
            <div className="section-three px-8 py-4 border-b-gray-300">
                <div className="mb-4">
                    <div>
                        <label>Are you a Differently-abled person? </label>
                        <div className="grid grid-cols-3 gap-4 mt-2">
                            <Field component={this.myRadioInput} name="disability.disable" text="Yes" value="Y" selected={props.values.disability.disable}></Field>
                            <Field component={this.myRadioInput} name="disability.disable" text="No" value="N" selected={props.values.disability.disable}></Field>
                        </div>
                        {getIn(props.errors, 'disability.disable') && getIn(props.touched, 'disability.disable') && <ErrorMsg msg={getIn(props.errors, 'disability.disable')} />}
                    </div>
                </div>

                {
                    props.values.disability.disable === 'Y' ? 
                    (
                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                            <div className="form-input">
                                <Field component={this.myTextInput} name="disability.certificate" placeholder="Certificate No" type="text" readonly={false}></Field>
                                {getIn(props.errors, 'disability.certificate') && getIn(props.touched, 'disability.certificate') && <ErrorMsg msg={getIn(props.errors, 'disability.certificate')} />}
                            </div>
                            <div className="form-input">
                                <div className="w-full">
                                    <label>Date Of Issue</label>
                                    <div className="text-input mt-2">
                                        <DatePicker
                                            selected={props.values.disability.date_of_issue}
                                            onChange={date => props.setFieldValue("disability.date_of_issue", date)}
                                            peekNextMonth
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            dateFormat="dd-MM-yyyy"
                                            className="appearance-none block w-full py-2 px-3 border border-gray-300 rounded"
                                            placeholderText="Click to select a date"
                                        />
                                        {getIn(props.errors, 'disability.date_of_issue') && getIn(props.touched, 'disability.date_of_issue') && <ErrorMsg msg={getIn(props.errors, 'disability.date_of_issue')} />}
                                    </div>
                                </div>
                            </div>
                            <div className="form-input">
                                <Field component={this.myTextInput} name="disability.percentage" placeholder="Percentage of Disability" type="number" readonly={false}></Field>
                                {getIn(props.errors, 'disability.percentage') && getIn(props.touched, 'disability.percentage') && <ErrorMsg msg={getIn(props.errors, 'disability.percentage')} />}
                            </div>
                            <div className="form-input">
                                <Field component={this.myTextInput} name="disability.description" placeholder="Description of Disability" type="text" readonly={false}></Field>
                                {getIn(props.errors, 'disability.description') && getIn(props.touched, 'disability.description') && <ErrorMsg msg={getIn(props.errors, 'disability.description')} />}
                            </div>
                        </div>
                    ) : null
                }
                
            </div>
        )
    }
}

export default Disability;