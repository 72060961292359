import React from 'react';

import './loading-btn.css';
import Spinner from './spinner.gif';

const LoadingBtn = ({ color }) => {
    return (
        <div className={`text-${color} flex`}>
            <span className="inline-icon spinner">
                <img src={Spinner} alt="loading" /> 
            </span>
            <span>
                Please Wait
            </span> 
        </div>
    )
}

export default LoadingBtn;
