import React, { Component } from 'react';
import { FaAngleLeft } from 'react-icons/fa';
import {Link} from 'react-router-dom';

import Sidebar from './Sidebar';
import PageBanner from '../includes/page-banner/PageBanner';
import ConversationItem from './ConversationItem';
import Reply from './Reply';
import { scrollToTop } from '../includes/jsFunctions';

class Conversation extends Component {

    componentDidMount(){
        scrollToTop();
        var acc = document.getElementsByClassName("accordion");
        var i;

        for (i = 0; i < acc.length; i++) {
            acc[i].addEventListener("click", function() {
                this.classList.toggle("active");
                var panel = this.nextElementSibling;
                if (panel.style.maxHeight) {
                panel.style.maxHeight = null;
                } else {
                panel.style.maxHeight = panel.scrollHeight + "px";
                } 
            });
        }
    }

    render() {
        return (
            <React.Fragment>
                <PageBanner title="Internal Messages" image="/images/page.jpg" />
                <div className="py-16">
                    <div className="container mx-auto px-8">
                        <div className="bg-white shadow-md border border-gray-300 rounded grid grid-cols-5">
                            <div className="col-span-1">
                                <Sidebar />
                            </div>
                            <div className="col-span-4 p-4">
                                <div>
                                    <span className="inline-icon">
                                        <Link to="/messages">
                                            <FaAngleLeft />
                                        </Link>
                                    </span>
                                    <h1 className="inline-block font-bold text-xl mb-4">Payment Issue</h1>
                                </div>
                                <ConversationItem image="/icons/user.png" name="John Doe" date="15 May 2019, 10:30 AM" to="Felix Hughes - RGU" body="Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat." />
                                <ConversationItem image="/icons/user.png" name="Felix Hughes - RGU" date="15 May 2019, 11:03 AM" to="John Doe" body="Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat." />
                                <ConversationItem image="/icons/user.png" name="John Doe" date="15 May 2019, 12:10 PM" to="Felix Hughes - RGU" body="Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat." />
                                <Reply />
                            </div>
                            
                        </div>
                        
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default Conversation;
