import * as Yup from "yup"
import {
    net_slet_jrf_codes,
    gate_codes,
    preference_codes,
    bed_codes,
    major_codes,
} from "../../includes/jsFunctions"

const validateEdu = Yup.object().shape({
    // exam_center: Yup.string()
    // .required("Please select exam center")
    // .nullable(),

    course: Yup.object().shape({
        course_id: Yup.string().required("Please wait").nullable(),

        course_code: Yup.string().required("Please wait").nullable(),

        course_category: Yup.string().required("Please wait").nullable(),

        exam_center: Yup.string().when("course_category", {
            is: (course_category) => course_category === "E",
            then: Yup.string().required("Please select exam center").nullable(),
            otherwise: Yup.string().notRequired(),
        }),

        one: Yup.string().when("course_code", {
            is: (course_code) => preference_codes.includes(course_code),
            then: Yup.string().required("Please select preference one"),
            otherwise: Yup.string().notRequired(),
        }),

        two: Yup.string().when("course_code", {
            is: (course_code) => preference_codes.includes(course_code),
            then: Yup.string().required("Please select preference two"),
            otherwise: Yup.string().notRequired(),
        }),

        net: Yup.string().when("course_code", {
            is: (course_code) => net_slet_jrf_codes.includes(course_code),
            then: Yup.string()
                .required(
                    "Please select if you are qualified for NET /SLET /JRF?"
                )
                .nullable(),
            otherwise: Yup.string().notRequired(),
        }),

        net_qualifications: Yup.array().when("net", {
            is: (net) => net === "Y",
            then: Yup.array()
                .of(
                    Yup.object().shape({
                        qualification: Yup.string().required("Required"),
                        certificate: Yup.string().required("Required"),
                        date_of_issue: Yup.string().required("Required"),
                        authority: Yup.string().required("Required"),
                    })
                )
                .required("Please enter qualifications"),
            otherwise: Yup.array().notRequired(),
        }),

        gate: Yup.object().shape({
            qualified: Yup.string().when("course_code", {
                is: (course_code) => gate_codes.includes(course_code),
                then: Yup.string()
                    .required("Please select if you have qualified Gate?")
                    .nullable(),
                otherwise: Yup.string().notRequired(),
            }),

            year: Yup.string().when("qualified", {
                is: (qualified) => qualified === "Y",
                then: Yup.string().required("Please enter year").nullable(),
                otherwise: Yup.string().notRequired(),
            }),

            score: Yup.string().when("qualified", {
                is: (qualified) => qualified === "Y",
                then: Yup.string()
                    .required("Please enter GATE score")
                    .nullable(),
                otherwise: Yup.string().notRequired(),
            }),
        }),

        bed_discipline: Yup.string().when("course_code", {
            is: (course_code) => bed_codes.includes(course_code),
            then: Yup.string()
                .required("Please select your B.Ed Discipline")
                .nullable(),
            otherwise: Yup.string().notRequired(),
        }),

        // major: Yup.string().when("course_code", {
        //     is: (course_code) => major_codes.includes(course_code),
        //     then: Yup.string()
        //         .required("Please select major subject")
        //         .nullable(),
        //     otherwise: Yup.string().notRequired(),
        // }),
    }),

    student: Yup.object().shape({
        isStudent: Yup.string()
            .required(
                "Please select whether you completed / Are you going to complete your Graduation from Rajiv Gandhi University?"
            )
            .nullable(),

        college: Yup.string().when("isStudent", {
            is: (isStudent) => isStudent === "Y",
            then: Yup.string().required(
                "Please enter name of the College / Department"
            ),
            otherwise: Yup.string().notRequired(),
        }),

        course: Yup.string().when("isStudent", {
            is: (isStudent) => isStudent === "Y",
            then: Yup.string().required(
                "Please enter name of Course undertaken"
            ),
            otherwise: Yup.string().notRequired(),
        }),

        reg_no: Yup.string().when("isStudent", {
            is: (isStudent) => isStudent === "Y",
            then: Yup.string().required("Please enter registration number"),
            otherwise: Yup.string().notRequired(),
        }),

        year: Yup.string().when("isStudent", {
            is: (isStudent) => isStudent === "Y",
            then: Yup.string()
                .required(
                    "Please enter year of completion / expected year of completion"
                )
                .nullable(),
            otherwise: Yup.string().notRequired(),
        }),
    }),

    // gate: Yup.object().shape({
    //     qualified: Yup.string()
    //     .required("Please select if you are qualified for GATE?")
    //     .nullable(),

    //     year: Yup.string()
    //     .when('qualified', {
    //         is: qualified => qualified === 'Y',
    //         then: Yup.string()
    //           .required('Please enter year')
    //           .nullable(),
    //           otherwise: Yup.string().notRequired()
    //     }),

    //     score: Yup.string()
    //     .when('qualified', {
    //         is: qualified => qualified === 'Y',
    //         then: Yup.string()
    //             .required('Please enter GATE score')
    //             .nullable(),
    //             otherwise: Yup.string().notRequired()
    //     }),

    // }),

    employment: Yup.object().shape({
        employed: Yup.string()
            .required("Please select if you are employed")
            .nullable(),

        employer: Yup.string().when("employed", {
            is: (employed) => employed === "Y",
            then: Yup.string()
                .required("Please enter employer name")
                .nullable(),
            otherwise: Yup.string().notRequired(),
        }),

        designation: Yup.string().when("employed", {
            is: (employed) => employed === "Y",
            then: Yup.string()
                .required("Please enter your designation")
                .nullable(),
            otherwise: Yup.string().notRequired(),
        }),

        status: Yup.string().when("employed", {
            is: (employed) => employed === "Y",
            then: Yup.string().required("Please enter year").nullable(),
            otherwise: Yup.string().notRequired(),
        }),

        noc: Yup.string().when("employed", {
            is: (employed) => employed === "Y",
            then: Yup.string()
                .required("Please select if NOC obtained")
                .nullable(),
            otherwise: Yup.string().notRequired(),
        }),

        noc_certificate: Yup.string().when("noc", {
            is: (noc) => noc === "Y",
            then: Yup.string()
                .required("Please enter certificate number")
                .nullable(),
            otherwise: Yup.string().notRequired(),
        }),

        noc_date_of_issue: Yup.string().when("noc", {
            is: (noc) => noc === "Y",
            then: Yup.string()
                .required("Please select date of issue")
                .nullable(),
            otherwise: Yup.string().notRequired(),
        }),

        noc_authority: Yup.string().when("noc", {
            is: (noc) => noc === "Y",
            then: Yup.string()
                .required("Please enter issuing authority")
                .nullable(),
            otherwise: Yup.string().notRequired(),
        }),
    }),

    // net: Yup.string()
    // .required("Please select if you are qualified for NET /SLET /JRF?")
    // .nullable(),

    // net_qualifications: Yup.array()
    // .when('net', {
    //     is: net => net === 'Y',
    //     then: Yup.array()
    //     .of(
    //         Yup.object().shape({
    //             qualification: Yup.string()
    //                 .required('Required'),
    //             certificate: Yup.string()
    //                 .required('Required'),
    //             date_of_issue: Yup.string()
    //                 .required('Required'),
    //             authority: Yup.string()
    //                 .required('Required'),
    //         })
    //     )
    //     .required('Please enter qualifications'),
    //     otherwise: Yup.array().notRequired()
    // }),

    // bed_discipline: Yup.string()
    // .required("Please select your B.Ed Discipline")
    // .nullable(),

    // preference: Yup.object().shape({
    //     one: Yup.lazy( value => {
    //         let code = Yup.reach('course', 'course_code');
    //         if(preference_codes.includes(code)){
    //             console.log('YES');
    //         }
    //         return Yup.mixed().notRequired;
    //     }),
    //     two: Yup.string()
    //         .required('Please select preference two'),
    // })
})

export default validateEdu
