import React, { Component } from "react";
import Modal from "react-modal";
import { connect } from "react-redux";
import {BsXCircle} from 'react-icons/bs'

import { closeModal } from "../../../../actions";
import "./modal.css";

class PageModal extends Component {
  hideModal = () => {
    this.props.closeModal();
  };
  render() {
    return (
      <React.Fragment>
        <Modal
          isOpen={this.props.modal.modalOpen}
          overlayClassName="Overlay"
          className="pageModal"
          contentLabel="Follow Modal"
          ariaHideApp={false}
        >
          <div className="modal-box">
            <div className="modal-box-body">
              <button className="closeModalBtn" onClick={this.hideModal}>
                <BsXCircle />
              </button>
              {this.props.children}
            </div>
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    modal: state.modal
  };
};

export default connect(mapStateToProps, { closeModal })(PageModal);
