import React, { Component } from 'react';
import {Field} from 'formik';
import { getIn } from 'formik';

import ErrorMsg from '../../includes/form-inputs/ErrorMsg';

class Discipline extends Component {

    myRadioInput = ({ text, field, name, checked, value, selected }) => {

        const border = checked ? `border border-blue-200` : `border border-gray-200`;

        return (
            <div className={`px-1 sm:px-4 custom-radio-btn ${border}`}>
                <label className="container">
                    {text}
                    <input type="radio" name={name} {...field} value={value} checked={value === selected} />
                    <span className="checkmark"></span>
                </label>
            </div>
        )
    }

    render() {

        const { props } = this.props;

        return (
            <div className="section-three px-8 py-4 border-b-gray-300">

                <div className="mb-4">
                    <div>
                        <label>Discilpine</label>
                        <div className="grid grid-cols-3 gap-4 mt-2">
                            <Field component={this.myRadioInput} name="course.bed_discipline" text="Arts / Commerce" value="AC" selected={props.values.course.bed_discipline}></Field>
                            <Field component={this.myRadioInput} name="course.bed_discipline" text="Science" value="S" selected={props.values.course.bed_discipline}></Field>
                        </div>
                        {getIn(props.errors, 'course.bed_discipline') && getIn(props.touched, 'course.bed_discipline') && <ErrorMsg msg={getIn(props.errors, 'course.bed_discipline')} />}
                    </div>
                </div>
                
            </div>
        )
    }
}

export default Discipline;