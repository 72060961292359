import React, { Component } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { Form, Formik } from "formik"

import ApplyBanner from "../../includes/apply-banner/ApplyBanner"
import { getPersonalDetails, finalApplySubmit } from "../../../../actions"
import ErrorMsg from "../../includes/form-inputs/ErrorMsg"
import { staticURI } from "../../includes/jsFunctions"

class Declaration extends Component {
    componentDidMount() {
        this.props.getPersonalDetails(this.props.match.params.id)
    }

    submitFrom = (values, actions) => {
        this.props.finalApplySubmit(values, actions)
    }

    checkboxFunction = (props) => {
        var checkBox = document.getElementById("accept")
        if (checkBox.checked == true) {
            props.setFieldValue("accept", "Y")
        } else {
            props.setFieldValue("accept", "N")
        }
    }

    validate = (values) => {
        const errors = {}

        if (values.accept === "N") {
            errors.accept = "Please accept the declaration to continue"
        }

        return errors
    }

    render() {
        const { loginLoading, login } = this.props.user
        const { stepOne, status } = this.props.apply

        if (!loginLoading && !login) {
            window.location.href = `${staticURI}/`
        }

        if ((stepOne.status === "Y" && stepOne.enabled_edit === 0) || status) {
            window.location.href = `${staticURI}/dashboard`
        }

        return (
            <React.Fragment>
                <ApplyBanner title="Application Declaration" />
                <section className="personal-details-page py-16">
                    <div className="container mx-auto px-8 ">
                        <div className="form-wrap border border-gray-300 p-8  shadow-md rounded-md">
                            <div className="text-center mb-3">
                                <h1 className="font-bold text-2xl">
                                    Declaration
                                </h1>
                            </div>
                            <div className="mb-3 pb-2">
                                <ul
                                    style={{
                                        listStyleType: "disc",
                                        marginBottom: "2rem",
                                    }}
                                >
                                    <li>
                                        I do hereby declare that the above
                                        stated information is true to the best
                                        of my knowledge. If any information
                                        proves to be wrong, I shall be liable to
                                        action as per the rules of the
                                        university. I have gone through the
                                        rules of admission etc. and shall be
                                        abiding by the same in the event of my
                                        admission.
                                    </li>
                                </ul>
                                <span className="font-bold">Note:</span> Kindly
                                check your details before submitting the
                                declaration. After Confirmation of your
                                declaration, you will not be able to change any
                                detail.
                            </div>
                            <div>
                                <Formik
                                    initialValues={{
                                        accept: "N",
                                        application_id: stepOne.application_id,
                                    }}
                                    onSubmit={(values, actions) => {
                                        this.submitFrom(values, actions)
                                    }}
                                    validate={this.validate}
                                    enableReinitialize={true}
                                >
                                    {(props: FormikProps<any>) => (
                                        <Form>
                                            <div>
                                                <div className="custom-checkbox">
                                                    <label className="container">
                                                        Accept Declaration
                                                        <input
                                                            id="accept"
                                                            type="checkbox"
                                                            onClick={() =>
                                                                this.checkboxFunction(
                                                                    props
                                                                )
                                                            }
                                                        />
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </div>
                                                {props.touched.accept &&
                                                    props.errors.accept && (
                                                        <ErrorMsg
                                                            msg={
                                                                props.errors
                                                                    .accept
                                                            }
                                                        />
                                                    )}
                                            </div>

                                            <div className="text-center mt-4 mb-4">
                                                <a
                                                    href={`${staticURI}/apply/documents-upload/${stepOne.application_id}`}
                                                    className="mr-2 rgu-btn-light"
                                                >
                                                    Go Back
                                                </a>
                                                <button
                                                    className="rgu-btn-light"
                                                    type="submit"
                                                >
                                                    Continue
                                                </button>
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        apply: state.apply,
        commonData: state.commonData,
    }
}

export default withRouter(
    connect(mapStateToProps, { getPersonalDetails, finalApplySubmit })(
        Declaration
    )
)
