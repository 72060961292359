import React, { Component } from "react"
import { connect } from "react-redux"
import { Field, Form, Formik } from "formik"

import ErrorMsg from "../includes/form-inputs/ErrorMsg"
import { userLogin, forgotPassword } from "../../../actions"
import LoadingBtn from "../includes/btn/LoadingBtn"
import { staticURI } from "../includes/jsFunctions"

class LoginForm extends Component {
    submitLoginFrom = (values, actions) => {
        this.props.userLogin(values, actions)
    }

    myTextInput = ({ name, field, placeholder, type }) => {
        return (
            <div className="w-full">
                <div className="text-input">
                    <input
                        placeholder={placeholder}
                        type={type}
                        name={name}
                        className={`appearance-none block w-full py-2 px-3`}
                        {...field}
                    />
                </div>
            </div>
        )
    }

    validate = (values) => {
        let errors = {}
        if (!values.email) {
            errors.email = "Please enter your email"
        } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
        ) {
            errors.email = "Invalid email address format"
        }

        if (!values.password) {
            errors.password = "Please enter your password"
        } else if (values.password.length < 6) {
            errors.password = "Password must be atleast 6 characters long"
        }

        return errors
    }

    resetPassword = () => {
        this.props.forgotPassword()
    }

    render() {
        const { login } = this.props.user
        const { isSubmitting } = this.props.commonFunctions

        if (login) {
            window.location.href = `${staticURI}/dashboard`
        }

        return (
            <div className="bulletin-form">
                <div className="form-head text-center mb-8">
                    <h1 className="uppercase font-bold text-dark-blue text-xl md:text-2xl lg:text-2xl xl:text-2xl">
                        LOGIN
                    </h1>
                    <p className="text-gray-600 text-lg mt-4">
                        Please fill in the your details
                    </p>
                </div>
                <div className="form-body">
                    <Formik
                        initialValues={{ password: "", email: "" }}
                        onSubmit={(values, actions) => {
                            this.submitLoginFrom(values, actions)
                        }}
                        validate={this.validate}
                    >
                        {(props: FormikProps<any>) => (
                            <Form>
                                <div className="form-input border border-gray-300 rounded">
                                    <Field
                                        component={this.myTextInput}
                                        name="email"
                                        placeholder="Enter your email"
                                        type="email"
                                    ></Field>
                                </div>
                                <div className="mb-4">
                                    {props.touched.email &&
                                        props.errors.email && (
                                            <ErrorMsg
                                                msg={props.errors.email}
                                            />
                                        )}
                                </div>
                                <div className="form-input border border-gray-300 rounded">
                                    <Field
                                        component={this.myTextInput}
                                        name="password"
                                        placeholder="Enter your password"
                                        type="password"
                                    ></Field>
                                </div>
                                <div className="mb-4">
                                    {props.touched.password &&
                                        props.errors.password && (
                                            <ErrorMsg
                                                msg={props.errors.password}
                                            />
                                        )}
                                </div>

                                <button
                                    type="button"
                                    className="text-light-blue text-normal outline-none"
                                    onClick={this.resetPassword}
                                >
                                    Forgot password?
                                </button>

                                <div className={`form-submit text-center mt-8`}>
                                    <button
                                        type="submit"
                                        className="rgu-btn-light"
                                    >
                                        {isSubmitting ? (
                                            <LoadingBtn />
                                        ) : (
                                            `Submit`
                                        )}
                                    </button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        commonFunctions: state.commonFunctions,
    }
}

export default connect(mapStateToProps, { userLogin, forgotPassword })(
    LoginForm
)
