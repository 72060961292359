import React from 'react';
import  { FaCheckCircle, FaChevronRight } from "react-icons/fa";

import './notice-item.css';
import { baseURL2 } from '../../../includes/jsFunctions';

const NoticeItem = ({ title, course, file }) => {
    return (
        <div className="notice-item-wrap bg-white p-2 mb-2 shadow-md">
            <a href={`${baseURL2}${file}`} target="_blank" rel="noopener noreferrer">
                <div className="flex">
                    <div className="flex-initial self-center icon-circle text-light-blue">
                        <FaCheckCircle />
                    </div>
                    <div className="flex-initial self-center">
                        {
                            course ? (<span className="font-bold text-lg">{course}:</span>) : null 
                        } 
                        {title}
                    </div>
                    <div className="flex-1 self-center">
                        <div className="flex flex-row-reverse text-light-blue">
                            <FaChevronRight />
                        </div>
                    </div>
                </div>
            </a>
        </div>
    )
}

export default NoticeItem;
