import React, { Component } from "react"
import { Field } from "formik"
import { getIn } from "formik"
import { connect } from "react-redux"

import ErrorMsg from "../../includes/form-inputs/ErrorMsg"
import { ba_major_codes, major_codes } from "../../includes/jsFunctions"

class Student extends Component {
    myTextInput = ({ name, field, placeholder, type, readonly }) => {
        const disabled = readonly ? true : false

        return (
            <div className="w-full">
                <label>{placeholder}</label>
                <div className="text-input mt-2">
                    <input
                        placeholder={placeholder}
                        type={type}
                        name={name}
                        className={`appearance-none block w-full py-2 px-3 border border-gray-300 rounded`}
                        {...field}
                        readOnly={disabled}
                    />
                </div>
            </div>
        )
    }

    myRadioInput = ({ text, field, name, checked, value, selected }) => {
        const border = checked
            ? `border border-blue-200`
            : `border border-gray-200`

        return (
            <div className={`px-1 sm:px-4 custom-radio-btn ${border}`}>
                <label className="container">
                    {text}
                    <input
                        type="radio"
                        name={name}
                        {...field}
                        value={value}
                        checked={value === selected}
                    />
                    <span className="checkmark"></span>
                </label>
            </div>
        )
    }

    mySelectInput = ({ name, options, field, label }) => {
        const selectOptions = options.map((option) => {
            return <option value={option.id}>{option.name}</option>
        })

        return (
            <div className="select-input">
                <label>{label}</label>
                <select
                    className={`w-full bg-white focus:outline-none py-2 px-4 border border-gray-300 rounded mt-2`}
                    name={name}
                    {...field}
                >
                    <option value="">Please Select</option>
                    {selectOptions}
                </select>
            </div>
        )
    }

    courseSelectInput = ({ name, options, field, label }) => {
        const selectOptions = options.map((option) => {
            return <option value={option.id}>{option.title}</option>
        })

        return (
            <div className="select-input">
                <label>{label}</label>
                <select
                    className={`w-full bg-white focus:outline-none py-2 px-4 border border-gray-300 rounded mt-2`}
                    name={name}
                    {...field}
                >
                    <option value="">Please Select</option>
                    {selectOptions}
                </select>
            </div>
        )
    }

    render() {
        const { props } = this.props

        let { examCenters } = this.props.commonData

        const { courses } = this.props.commonData

        const course_code = props.values.course_code

        //18 (RGU), 21(JNC)

        if (
            ba_major_codes.includes(course_code) ||
            major_codes.includes(course_code)
        ) {
            examCenters = examCenters.filter(
                (examCenter) => examCenter.id === 18 || examCenter.id === 21
            )
        }

        return (
            <div className="section-three px-8 py-4 border-b-gray-300">
                {props.values.course.course_category === "E" ? (
                    <div className="mb-4">
                        <div>
                            <label>
                                RGUCET Examination Centre{" "}
                                <span className="text-red-500">
                                    (Preference selected for Examination Centre
                                    is subject to change depending on the
                                    seating capacity of the Centre)
                                </span>
                            </label>
                            <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-2">
                                <div>
                                    <Field
                                        component={this.mySelectInput}
                                        name="course.exam_center"
                                        label=""
                                        options={examCenters}
                                    ></Field>
                                </div>
                            </div>
                            {getIn(props.errors, "course.exam_center") &&
                                getIn(props.touched, "course.exam_center") && (
                                    <ErrorMsg
                                        msg={getIn(
                                            props.errors,
                                            "course.exam_center"
                                        )}
                                    />
                                )}
                        </div>
                    </div>
                ) : null}

                <div className="mb-4">
                    <div>
                        <label>
                            Have you completed / Are you going to complete your
                            Graduation from Rajiv Gandhi University?
                        </label>
                        <div className="grid grid-cols-3 gap-4 mt-2">
                            <Field
                                component={this.myRadioInput}
                                name="student.isStudent"
                                text="Yes"
                                value="Y"
                                selected={props.values.student.isStudent}
                            ></Field>
                            <Field
                                component={this.myRadioInput}
                                name="student.isStudent"
                                text="No"
                                value="N"
                                selected={props.values.student.isStudent}
                            ></Field>
                        </div>
                        {getIn(props.errors, "student.isStudent") &&
                            getIn(props.touched, "student.isStudent") && (
                                <ErrorMsg
                                    msg={getIn(
                                        props.errors,
                                        "student.isStudent"
                                    )}
                                />
                            )}
                    </div>
                </div>

                {props.values.student.isStudent === "Y" ? (
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                        <div className="form-input">
                            <Field
                                component={this.myTextInput}
                                name="student.college"
                                placeholder="Name of the College / Department"
                                type="text"
                                readonly={false}
                            ></Field>
                            {getIn(props.errors, "student.college") &&
                                getIn(props.touched, "student.college") && (
                                    <ErrorMsg
                                        msg={getIn(
                                            props.errors,
                                            "student.college"
                                        )}
                                    />
                                )}
                        </div>
                        <div className="form-input">
                            {/* <Field component={this.courseSelectInput} name="student.course" label="Name of Course undertaken" options={courses}></Field> */}
                            <Field
                                component={this.myTextInput}
                                name="student.course"
                                placeholder="Name of Course undertaken"
                                type="text"
                                readonly={false}
                            ></Field>
                            {getIn(props.errors, "student.course") &&
                                getIn(props.touched, "student.course") && (
                                    <ErrorMsg
                                        msg={getIn(
                                            props.errors,
                                            "student.course"
                                        )}
                                    />
                                )}
                        </div>
                        <div className="form-input">
                            <Field
                                component={this.myTextInput}
                                name="student.reg_no"
                                placeholder="Registration No"
                                type="text"
                                readonly={false}
                            ></Field>
                            {getIn(props.errors, "student.reg_no") &&
                                getIn(props.touched, "student.reg_no") && (
                                    <ErrorMsg
                                        msg={getIn(
                                            props.errors,
                                            "student.reg_no"
                                        )}
                                    />
                                )}
                        </div>
                        <div className="form-input">
                            <Field
                                component={this.myTextInput}
                                name="student.year"
                                placeholder="Year of Completion / Expected year of Completion"
                                type="number"
                                readonly={false}
                            ></Field>
                            {getIn(props.errors, "student.year") &&
                                getIn(props.touched, "student.year") && (
                                    <ErrorMsg
                                        msg={getIn(
                                            props.errors,
                                            "student.year"
                                        )}
                                    />
                                )}
                        </div>
                    </div>
                ) : null}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        commonData: state.commonData,
    }
}

export default connect(mapStateToProps)(Student)
