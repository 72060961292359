import React, { Component } from "react"
import { connect } from "react-redux"
import { Field, Form, Formik, FormikProps, ErrorMessage } from "formik"

import ErrorMsg from "../../../includes/form-inputs/ErrorMsg"
import { validateEmail, validatePassword } from "./validate"
import { regsiterStepTwo } from "../../../../../actions"
import ProgressBar from "./ProgressBar"
import { staticURI } from "../../../includes/jsFunctions"

class RegisterFrom extends Component {
    regusterUser = (values, actions) => {
        console.log(values)
        this.props.regsiterStepTwo(values, actions)
    }

    myTextInput = ({ name, field, placeholder, type, border }) => {
        const borderStyle = border ? `border border-gray-300 rounded` : null
        return (
            <div className="w-full">
                <div className="text-input">
                    <input
                        placeholder={placeholder}
                        type={type}
                        name={name}
                        className={`appearance-none block w-full py-2 px-3 ${borderStyle}`}
                        {...field}
                    />
                </div>
            </div>
        )
    }

    render() {
        const { mobile, email, registered } = this.props.bannerForm

        if (registered) {
            window.location.href = `${staticURI}/dashboard`
        }

        return (
            <React.Fragment>
                <ProgressBar width="100%" />
                <div className="form-head text-center">
                    <h1 className="uppercase font-bold text-dark-blue text-xl md:text-2xl lg:text-2xl xl:text-2xl">
                        Genral Information
                    </h1>
                    <p className="text-gray-600 text-lg mt-4">
                        Please enter your general information
                    </p>
                </div>
                <div className="form-body mt-4 mb-4">
                    <Formik
                        initialValues={{
                            email,
                            password: "",
                            password_confirmation: "",
                            mobile,
                        }}
                        onSubmit={(values, actions) => {
                            this.regusterUser(values, actions)
                        }}
                    >
                        {(props: FormikProps<any>) => (
                            <Form>
                                <div className="w-full mt-4">
                                    <Field
                                        component={this.myTextInput}
                                        name="password"
                                        validate={validatePassword}
                                        placeholder="Enter Password"
                                        type="password"
                                        border={true}
                                    ></Field>
                                    <ErrorMessage
                                        name="password"
                                        render={(msg) => <ErrorMsg msg={msg} />}
                                    />
                                </div>
                                <div className="w-full mt-4">
                                    <Field
                                        component={this.myTextInput}
                                        name="password_confirmation"
                                        placeholder="Confirm Password"
                                        type="password"
                                        border={true}
                                    ></Field>
                                    <ErrorMessage
                                        name="password_confirmation"
                                        render={(msg) => <ErrorMsg msg={msg} />}
                                    />
                                </div>

                                <div className={`form-submit text-center mt-4`}>
                                    <button className="rgu-btn-light">
                                        Submit
                                    </button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        bannerForm: state.bannerForm,
    }
}

export default connect(mapStateToProps, { regsiterStepTwo })(RegisterFrom)
