import React, { Component } from "react"
import { connect } from "react-redux"
import { FaFacebook } from "react-icons/fa"
import { BsTwitterX } from "react-icons/bs"
import { FaInstagram } from "react-icons/fa"
import { FaLinkedin } from "react-icons/fa"

import { showModal } from "../../../actions"
import PageModal from "../includes/modal/PageModal"
import BulletinForm from "../navbar/BulletinForm"
import "./footer.css"
import { baseURL } from "../includes/jsFunctions"

class Footer extends Component {
    showModal = (name) => {
        this.props.showModal(name)
    }

    render() {
        const {
            institute_name,
            logo,
            cms_pages,
            facebook_url,
            instagram_url,
            linkedin_url,
            twitter_url,
            copyright_text,
        } = this.props.commonData.settings

        return (
            <React.Fragment>
                <footer className="footer-section bg-dark-blue">
                    <div className="container mx-auto py-10 px-4">
                        <div className="footer-logo">
                            <img
                                src={`${baseURL}/settings/${logo}`}
                                alt={institute_name}
                            />
                        </div>
                        <ul className="flex flex-wrap justify-center mb-4 footer-links text-white">
                            {cms_pages &&
                                cms_pages.length > 0 &&
                                cms_pages.map((item) => (
                                    <li key={item.slug}>
                                        <a href={`/quick-link/${item.slug}`}>
                                            {item.title}
                                        </a>
                                    </li>
                                ))}
                        </ul>

                        <ul className="flex flex-wrap justify-center mb-4 text-2xl footer-links text-white">
                            {facebook_url && (
                                <li>
                                    <a
                                        href={facebook_url}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <FaFacebook />
                                    </a>
                                </li>
                            )}

                            {instagram_url && (
                                <li>
                                    <a
                                        href={instagram_url}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <BsTwitterX />
                                    </a>
                                </li>
                            )}

                            {linkedin_url && (
                                <li>
                                    <a
                                        href={linkedin_url}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <FaInstagram />
                                    </a>
                                </li>
                            )}

                            {twitter_url && (
                                <li>
                                    <a
                                        href={twitter_url}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <FaLinkedin />
                                    </a>
                                </li>
                            )}
                        </ul>

                        <div className="text-center text-white">
                            &copy; {copyright_text}
                        </div>
                    </div>
                </footer>

                {this.props.modal.modalOpen &&
                this.props.modal.modalName === "bulletinModal3" ? (
                    <PageModal>
                        <BulletinForm />
                    </PageModal>
                ) : null}
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        modal: state.modal,
        commonData: state.commonData,
    }
}

export default connect(mapStateToProps, { showModal })(Footer)
