import React, { Component } from 'react';
import {Field} from 'formik';
import { getIn } from 'formik';
import { connect } from 'react-redux';

import ErrorMsg from '../../includes/form-inputs/ErrorMsg';
import { setPermanentStates, setPermanentCities, getPermanentCities } from '../../../../actions/index';

class PermanentAdr extends Component {


    componentDidMount(){
        if(this.props.commonData.states && this.props.commonData.states.length > 0  && parseInt(this.props.props.values.permanent_adr.state) > 0){
            //console.log(this.props.props.values.permanent_adr.country);
            this.filterCities(this.props.commonData.states, this.props.props.values.permanent_adr.state);
        }
    }

    componentDidUpdate(prevProps) {
        if(prevProps.props.values.permanent_adr.country !== this.props.props.values.permanent_adr.country){
            if(this.props.commonData.countries && this.props.commonData.countries.length > 0  && parseInt(this.props.props.values.permanent_adr.country) > 0){
                //console.log(this.props.props.values.permanent_adr.country);
                this.filterStates(this.props.commonData.countries, this.props.props.values.permanent_adr.country);
            }
        }

        if(prevProps.props.values.permanent_adr.state !== this.props.props.values.permanent_adr.state){

            if(parseInt(this.props.props.values.permanent_adr.state) > 0 && parseInt(this.props.props.values.permanent_adr.country) > 0){
                this.props.getPermanentCities(this.props.props.values.permanent_adr.state);
            }

            if(this.props.commonData.states_copy && this.props.commonData.states_copy.length > 0  && parseInt(this.props.props.values.permanent_adr.state) > 0){
                //console.log(this.props.props.values.permanent_adr.country);
                this.filterCities(this.props.commonData.states_copy, this.props.props.values.permanent_adr.state);
            }
        }

    }

    myTextInput = ({ name, field, placeholder, type, readonly }) => {

        const disabled = readonly ? true : false;

        return (
            <div className="w-full">
                <label>{placeholder}</label>
                <div className="text-input mt-2">
                    <input placeholder={placeholder} type={type} name={name} className={`appearance-none block w-full py-2 px-3 border border-gray-300 rounded`} {...field} readOnly={disabled} />
                </div>
            </div>
        )
    }

    checkboxFunction = props => {
        // Get the checkbox
        var checkBox = document.getElementById("same-address");
      
        // If the checkbox is checked, display the output text
        if (checkBox.checked == true){
          
            let adr_line_1  = props.values.present_adr.adr_line_1;
            let adr_line_2  = props.values.present_adr.adr_line_2;
            let country     = props.values.present_adr.country;
            let state       = props.values.present_adr.state;
            let city        = props.values.present_adr.city;
            let pincode     = props.values.present_adr.pincode;

            props.setFieldValue("permanent_adr.adr_line_1", adr_line_1);
            props.setFieldValue("permanent_adr.adr_line_2", adr_line_2);
            props.setFieldValue("permanent_adr.country", country);
            props.setFieldValue("permanent_adr.state", state);
            props.setFieldValue("permanent_adr.city", city);
            props.setFieldValue("permanent_adr.pincode", pincode);


        } else {

            props.setFieldValue("permanent_adr.adr_line_1", '');
            props.setFieldValue("permanent_adr.adr_line_2", '');
            props.setFieldValue("permanent_adr.country", '');
            props.setFieldValue("permanent_adr.state", '');
            props.setFieldValue("permanent_adr.city", '');
            props.setFieldValue("permanent_adr.pincode", '');
          
        }
      }

    mySelectInput = ({ name, options, field, label }) => {
    
        const selectOptions = options.map(option => {
            return (
                <option key={option.name} value={option.id}>{option.name}</option>
            )
        });
        
        return(
            <div className="select-input">
                <label>{label}</label>
                <select className={`w-full bg-white focus:outline-none py-2 px-4 border border-gray-300 rounded mt-2`} name={name} {...field}>
                    <option value="">Please Select</option>
                    {selectOptions}
                </select>
            </div>
        )
    }

    filterStates = (countries, selected) => {
        const selectedItem = countries.find(element => element.id === parseInt(selected));
        this.props.setPermanentStates(selectedItem.state);
        //console.log(selectedItem);
    }

    filterCities = (states, selected) => {
        const selectedItem = states.find(element => element.id === parseInt(selected));
        this.props.setPermanentCities(selectedItem.city);
        console.log(selectedItem);
    }

    render() {

        const { countries, states_copy, cities_copy } = this.props.commonData;
        const { props } = this.props;

        return (
            <div className="section-two p-8 border border-t-gray-300">
                <div className="mb-4">
                    <h1 className="mb-4 text-xl md:text-2xl font-bold">Permanent Address</h1>
                    <div className="custom-checkbox">
                        <label className="container">
                            Same as present address
                            <input id="same-address" type="checkbox" onClick={() => this.checkboxFunction(props)} />
                            <span className="checkmark"></span>
                        </label>
                    </div>
                </div>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-3">
                    <div className="form-input">
                        <Field component={this.myTextInput} name="permanent_adr.adr_line_1" placeholder="Address Line 1" type="text" readonly={false}></Field>
                        {getIn(props.errors, 'permanent_adr.adr_line_1') && getIn(props.touched, 'permanent_adr.adr_line_1') && <ErrorMsg msg={getIn(props.errors, 'permanent_adr.adr_line_1')} />}
                    </div>
                    <div className="form-input">
                        <Field component={this.myTextInput} name="permanent_adr.adr_line_2" placeholder="Address Line 2 (optional)" type="text" readonly={false}></Field>
                    </div>
                </div>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-3">
                    <div>
                        <Field component={this.mySelectInput} name="permanent_adr.country" label="Country" options={countries}></Field>
                        {getIn(props.errors, 'permanent_adr.country') && getIn(props.touched, 'permanent_adr.country') && <ErrorMsg msg={getIn(props.errors, 'permanent_adr.country')} />}
                    </div>
                    <div>
                        <Field component={this.mySelectInput} name="permanent_adr.state" label="State" options={states_copy}></Field>
                        {getIn(props.errors, 'permanent_adr.state') && getIn(props.touched, 'permanent_adr.state') && <ErrorMsg msg={getIn(props.errors, 'permanent_adr.state')} />}
                    </div>
                </div>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-3">
                    <div>
                        <Field component={this.mySelectInput} name="permanent_adr.city" label="City" options={cities_copy}></Field>
                        {getIn(props.errors, 'permanent_adr.city') && getIn(props.touched, 'permanent_adr.city') && <ErrorMsg msg={getIn(props.errors, 'permanent_adr.city')} />}
                    </div>
                    <div className="form-input">
                        <Field component={this.myTextInput} name="permanent_adr.pincode" placeholder="Pincode" type="number" readonly={false}></Field>
                        {getIn(props.errors, 'permanent_adr.pincode') && getIn(props.touched, 'permanent_adr.pincode') && <ErrorMsg msg={getIn(props.errors, 'permanent_adr.pincode')} />}
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToPorps = state => {
    return {
        commonData: state.commonData,
    }
}

export default connect(mapStateToPorps, { setPermanentStates, setPermanentCities, getPermanentCities })(PermanentAdr);