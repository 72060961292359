import React, { Component } from "react"
import { Field, FieldArray } from "formik"
import { getIn } from "formik"
import { connect } from "react-redux"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

import ErrorMsg from "../../includes/form-inputs/ErrorMsg"

class NetSletJrf extends Component {
    myTextInput = ({ name, field, placeholder, type, readonly }) => {
        const disabled = readonly ? true : false

        return (
            <div className="w-full">
                <label>{placeholder}</label>
                <div className="text-input mt-2">
                    <input
                        placeholder={placeholder}
                        type={type}
                        name={name}
                        className={`appearance-none block w-full py-2 px-3 border border-gray-300 rounded`}
                        {...field}
                        readOnly={disabled}
                    />
                </div>
            </div>
        )
    }

    myRadioInput = ({ text, field, name, checked, value, selected }) => {
        const border = checked
            ? `border border-blue-200`
            : `border border-gray-200`

        return (
            <div className={`px-1 sm:px-4 custom-radio-btn ${border}`}>
                <label className="container">
                    {text}
                    <input
                        type="radio"
                        name={name}
                        {...field}
                        value={value}
                        checked={value === selected}
                    />
                    <span className="checkmark"></span>
                </label>
            </div>
        )
    }

    mySelectInput = ({ name, options, field, label }) => {
        const selectOptions = options.map((option) => {
            return <option value={option.id}>{option.name}</option>
        })

        return (
            <div className="select-input">
                <label>{label}</label>
                <select
                    className={`w-full bg-white focus:outline-none py-2 px-4 border border-gray-300 rounded mt-2`}
                    name={name}
                    {...field}
                >
                    <option value="">Please Select</option>
                    {selectOptions}
                </select>
            </div>
        )
    }

    validateField = (value) => {
        let error

        if (
            !value &&
            this.props.commonData.net_slet_jrf_codes.includes(
                this.props.props.values.course.course_code
            )
        ) {
            error = "Please select if you are qualified for NET /SLET /JRF?"
        }
        return error
    }

    render() {
        const { props } = this.props

        const selectData = [
            { id: 1, name: "NET" },
            { id: 2, name: "SLET" },
            { id: 3, name: "JRF" },
        ]

        return (
            <div className="section-three px-8 py-4 border-b-gray-300">
                <div className="mb-4">
                    <div>
                        <label>Are you qualified for NET /SLET /JRF?</label>
                        <div className="grid grid-cols-3 gap-4 mt-2">
                            <Field
                                component={this.myRadioInput}
                                name="course.net"
                                text="Yes"
                                value="Y"
                                selected={props.values.course.net}
                                validate={this.validateField}
                            ></Field>
                            <Field
                                component={this.myRadioInput}
                                name="course.net"
                                text="No"
                                value="N"
                                selected={props.values.course.net}
                                validate={this.validateField}
                            ></Field>
                        </div>
                        {getIn(props.errors, "course.net") &&
                            getIn(props.touched, "course.net") && (
                                <ErrorMsg
                                    msg={getIn(props.errors, "course.net")}
                                />
                            )}
                    </div>
                </div>

                {props.values.course.net === "Y" ? (
                    <React.Fragment>
                        <FieldArray
                            name="course.net_qualifications"
                            render={(arrayHelpers) => (
                                <div>
                                    {props.values.course.net_qualifications &&
                                    props.values.course.net_qualifications
                                        .length > 0
                                        ? props.values.course.net_qualifications.map(
                                              (subject, index) => (
                                                  <div
                                                      key={index}
                                                      className="grid grid-cols-2 sm:grid-cols-5 gap-4 mb-2"
                                                  >
                                                      <div className="form-input">
                                                          <Field
                                                              component={
                                                                  this
                                                                      .mySelectInput
                                                              }
                                                              name={`course.net_qualifications.${index}.qualification`}
                                                              label="Qualification"
                                                              options={
                                                                  selectData
                                                              }
                                                          ></Field>
                                                          {getIn(
                                                              props.errors,
                                                              `course.net_qualifications[${index}].qualification`
                                                          ) &&
                                                              getIn(
                                                                  props.touched,
                                                                  `course.net_qualifications[${index}].qualification`
                                                              ) && (
                                                                  <ErrorMsg
                                                                      msg={getIn(
                                                                          props.errors,
                                                                          `course.net_qualifications[${index}].qualification`
                                                                      )}
                                                                  />
                                                              )}
                                                      </div>
                                                      <div className="form-input">
                                                          <Field
                                                              component={
                                                                  this
                                                                      .myTextInput
                                                              }
                                                              name={`course.net_qualifications.${index}.certificate`}
                                                              placeholder="Certificate No"
                                                              type="text"
                                                              readonly={false}
                                                          ></Field>
                                                          {getIn(
                                                              props.errors,
                                                              `course.net_qualifications[${index}].certificate`
                                                          ) &&
                                                              getIn(
                                                                  props.touched,
                                                                  `course.net_qualifications[${index}].certificate`
                                                              ) && (
                                                                  <ErrorMsg
                                                                      msg={getIn(
                                                                          props.errors,
                                                                          `course.net_qualifications[${index}].certificate`
                                                                      )}
                                                                  />
                                                              )}
                                                      </div>
                                                      <div className="form-input">
                                                          <div className="w-full">
                                                              <label>
                                                                  Date Of Issue
                                                              </label>
                                                              <div className="text-input mt-2">
                                                                  <DatePicker
                                                                      selected={
                                                                          props
                                                                              .values
                                                                              .course
                                                                              .net_qualifications[
                                                                              index
                                                                          ]
                                                                              .date_of_issue
                                                                      }
                                                                      onChange={(
                                                                          date
                                                                      ) =>
                                                                          props.setFieldValue(
                                                                              `course.net_qualifications.${index}.date_of_issue`,
                                                                              date
                                                                          )
                                                                      }
                                                                      peekNextMonth
                                                                      showMonthDropdown
                                                                      showYearDropdown
                                                                      dropdownMode="select"
                                                                      dateFormat="dd-MM-yyyy"
                                                                      className="appearance-none block w-full py-2 px-3 border border-gray-300 rounded"
                                                                      placeholderText="Click to select a date"
                                                                  />
                                                              </div>
                                                          </div>
                                                          {getIn(
                                                              props.errors,
                                                              `course.net_qualifications[${index}].date_of_issue`
                                                          ) &&
                                                              getIn(
                                                                  props.touched,
                                                                  `course.net_qualifications[${index}].date_of_issue`
                                                              ) && (
                                                                  <ErrorMsg
                                                                      msg={getIn(
                                                                          props.errors,
                                                                          `course.net_qualifications[${index}].date_of_issue`
                                                                      )}
                                                                  />
                                                              )}
                                                      </div>
                                                      <div className="form-input">
                                                          <Field
                                                              component={
                                                                  this
                                                                      .myTextInput
                                                              }
                                                              name={`course.net_qualifications.${index}.authority`}
                                                              placeholder="Issuing Authority"
                                                              type="text"
                                                              readonly={false}
                                                          ></Field>
                                                          {getIn(
                                                              props.errors,
                                                              `course.net_qualifications[${index}].authority`
                                                          ) &&
                                                              getIn(
                                                                  props.touched,
                                                                  `course.net_qualifications[${index}].authority`
                                                              ) && (
                                                                  <ErrorMsg
                                                                      msg={getIn(
                                                                          props.errors,
                                                                          `course.net_qualifications[${index}].authority`
                                                                      )}
                                                                  />
                                                              )}
                                                      </div>

                                                      {index < 1 ? (
                                                          <button
                                                              type="button"
                                                              className="flex-initial ml-2 self-center"
                                                              onClick={() =>
                                                                  arrayHelpers.push(
                                                                      {
                                                                          qualification:
                                                                              "",
                                                                          certificate:
                                                                              "",
                                                                          date_of_issue:
                                                                              "",
                                                                          authority:
                                                                              "",
                                                                      }
                                                                  )
                                                              }
                                                          >
                                                              <img
                                                                  src="/icon/add-button.png"
                                                                  alt="remove"
                                                                  style={{
                                                                      width: "30px",
                                                                  }}
                                                              />
                                                          </button>
                                                      ) : (
                                                          <button
                                                              type="button"
                                                              className="flex-initial ml-2 self-center"
                                                              onClick={() =>
                                                                  arrayHelpers.remove(
                                                                      index
                                                                  )
                                                              } // remove a friend from the list
                                                          >
                                                              <img
                                                                  src="/icon/delete-button.png"
                                                                  alt="remove"
                                                                  style={{
                                                                      width: "40px",
                                                                      marginTop:
                                                                          "1rem",
                                                                  }}
                                                              />
                                                          </button>
                                                      )}
                                                  </div>
                                              )
                                          )
                                        : null}
                                </div>
                            )}
                        />
                        {/* {getIn(props.errors, 'net_qualifications') && getIn(props.touched, 'net_qualifications') && <ErrorMsg msg={getIn(props.errors, 'net_qualifications')} />} */}
                    </React.Fragment>
                ) : null}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        commonData: state.commonData,
    }
}

export default connect(mapStateToProps)(NetSletJrf)
