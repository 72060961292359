import React from "react"
import { Link, withRouter } from "react-router-dom"
import { connect } from "react-redux"

import "./navbar.css"
import { showModal } from "../../../actions"
import PageModal from "../includes/modal/PageModal"
import BulletinForm from "./BulletinForm"
import LoginModal from "./LoginModal"
import {
    getOnlyStates,
    checkUser,
    getCourses,
    fetchSettings,
} from "../../../actions"
import { baseURL } from "../includes/jsFunctions"

class Navbar extends React.Component {
    showModal = (name) => {
        this.props.showModal(name)
    }

    componentDidMount() {
        window.onscroll = function () {
            myFunction()
        }

        var navbar = document.querySelector(".main-nav")
        var sticky = navbar.offsetTop

        function myFunction() {
            if (window.pageYOffset > sticky) {
                navbar.classList.add("fixed-bg")
            } else {
                navbar.classList.remove("fixed-bg")
            }
        }

        this.props.getOnlyStates()
        this.props.checkUser()
        this.props.getCourses()
        this.props.fetchSettings()
    }

    render() {
        const navStyle =
            this.props.location.pathname === "/"
                ? `md:absolute lg:absolute xl:absolute`
                : `md:relative lg:relative xl:relative white-bg`

        const { login, loginLoading, user } = this.props.user

        const { institute_name, logo, manual } = this.props.commonData.settings

        return (
            <React.Fragment>
                <header
                    className={`main-nav w-full top-0 lg:px-16 flex flex-wrap items-center lg:py-0 py-2 z-30 ${navStyle}`}
                >
                    <div className="flex-1 flex justify-between items-center py-1">
                        <div className="flex-initial self-center mr-2 md:mr-4">
                            <Link to="/">
                                <img
                                    src={`${baseURL}/settings/${logo}`}
                                    className="w-16"
                                    alt={institute_name}
                                />
                            </Link>
                        </div>
                        <div className="flex-1 self-center leading-tight logo-text">
                            <h3 className="font-bold">{institute_name}</h3>
                            {/* <h3 className="font-bold">
                                Rajiv Gandhi University
                            </h3>
                            <p>Arunachal Pradesh, India</p> */}
                        </div>
                    </div>

                    <label
                        htmlFor="menu-toggle"
                        className="pointer-cursor lg:hidden block"
                    >
                        <svg
                            className="fill-current"
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                        >
                            <title>menu</title>
                            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"></path>
                        </svg>
                    </label>
                    <input
                        className="hidden"
                        type="checkbox"
                        id="menu-toggle"
                    />

                    <div
                        className="hidden lg:flex lg:items-center lg:w-auto w-full"
                        id="menu"
                    >
                        <nav>
                            <ul className="lg:flex items-center justify-between text-base text-white pt-4 lg:pt-0">
                                <li className="border-b-2 hover:border-indigo-400 border-transparent">
                                    <a
                                        className="lg:p-4 py-3 px-0 block"
                                        href="/course-details"
                                    >
                                        Programmes
                                    </a>
                                </li>
                                <li className="border-b-2 hover:border-indigo-400 border-transparent">
                                    <a
                                        className="lg:p-4 py-3 px-0 block"
                                        href={`${baseURL}/settings/${manual}`}
                                        rel="noreferrer noopener"
                                        target="_blank"
                                    >
                                        How To Apply
                                    </a>
                                </li>
                                <li className="border-b-2 hover:border-indigo-400 border-transparent">
                                    <a
                                        onClick={() =>
                                            this.showModal("bulletinModal")
                                        }
                                        className="lg:p-4 py-3 px-0 block"
                                        href="#0"
                                    >
                                        Admission Bulletin
                                    </a>
                                </li>
                                {/* {
                                    !loginLoading && login ? (
                                        <li className="border-b-2 hover:border-indigo-400 border-transparent"><Link className="lg:p-4 py-3 px-0 block" to="/messages">Messages</Link></li>
                                    ) : null
                                } */}

                                <li className="border-b-2 hover:border-indigo-400 border-transparent">
                                    <a
                                        className="lg:p-4 py-3 px-0 block"
                                        href="/contact-us"
                                    >
                                        Contact Us
                                    </a>
                                </li>

                                {!loginLoading && !login ? (
                                    <li className="border-b-2 hover:border-indigo-400 border-transparent">
                                        <a
                                            onClick={() =>
                                                this.showModal("loginModal")
                                            }
                                            className="lg:p-4 py-3 px-0 block lg:mb-0 mb-2"
                                            href="#0"
                                        >
                                            Already Applied? Login Here
                                        </a>
                                    </li>
                                ) : !loginLoading && login ? (
                                    <li className="border-b-2 hover:border-indigo-400 border-transparent dropdown">
                                        <button className="dropbtn lg:p-4 py-3 px-0 block lg:mb-0 mb-2">
                                            Hello, {user.fname}
                                        </button>
                                        <div className="dropdown-content">
                                            <Link
                                                to="/dashboard"
                                                className="lg:p-4 py-3 px-0 block lg:mb-0 mb-2"
                                            >
                                                Dashboard{" "}
                                            </Link>
                                            <Link
                                                to="/logout"
                                                className="lg:p-4 py-3 px-0 block lg:mb-0 mb-2"
                                            >
                                                Logout{" "}
                                            </Link>
                                        </div>
                                    </li>
                                ) : null}
                            </ul>
                        </nav>
                    </div>
                </header>

                {this.props.modal.modalOpen &&
                this.props.modal.modalName === "bulletinModal" ? (
                    <PageModal>
                        <BulletinForm />
                    </PageModal>
                ) : null}

                {this.props.modal.modalOpen &&
                this.props.modal.modalName === "loginModal" ? (
                    <PageModal>
                        <LoginModal />
                    </PageModal>
                ) : null}
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        modal: state.modal,
        user: state.user,
        commonData: state.commonData,
    }
}

export default withRouter(
    connect(mapStateToProps, {
        showModal,
        getOnlyStates,
        checkUser,
        getCourses,
        fetchSettings,
    })(Navbar)
)
