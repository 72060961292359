import React from 'react';

import { baseURL2 } from '../includes/jsFunctions';

const PaperItem = ({ name, department, year, file, image }) => {
    return (
        <div className="bg-white py-4 px-8 shadow-md mb-4 paper-item">
            <div className="flex mb-4">
                <span className="mr-2">
                    <img src={image} alt="course-icon" />
                </span>
                <h1 className="font-bold text-2xl">{name}</h1>
            </div>
            <div className="grid grid-cols-3 gap-4">
                <div className="col-span-2">
                    <div>Sample question papers for {department}</div>
                    <div>Year - {year}</div>
                </div>
                <div className="col-span-1 flex flex-row-reverse">
                    <a href={`${baseURL2}${file}`} target="_blank" rel="noopener noreffer" className="rgu-btn-dark">Download Now</a>
                </div>
            </div>
        </div>
    )
}

export default PaperItem;
