import React, { Component } from 'react';
import {Field} from 'formik';
import { getIn } from 'formik';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import ErrorMsg from '../../includes/form-inputs/ErrorMsg';

class Employment extends Component {

    myTextInput = ({ name, field, placeholder, type, readonly }) => {

        const disabled = readonly ? true : false;

        return (
            <div className="w-full">
                <label>{placeholder}</label>
                <div className="text-input mt-2">
                    <input placeholder={placeholder} type={type} name={name} className={`appearance-none block w-full py-2 px-3 border border-gray-300 rounded`} {...field} readOnly={disabled} />
                </div>
            </div>
        )
    }

    myRadioInput = ({ text, field, name, checked, value, selected }) => {

        const border = checked ? `border border-blue-200` : `border border-gray-200`;

        return (
            <div className={`px-1 sm:px-4 custom-radio-btn ${border}`}>
                <label className="container">
                    {text}
                    <input type="radio" name={name} {...field} value={value} checked={value === selected} />
                    <span className="checkmark"></span>
                </label>
            </div>
        )
    }

    mySelectInput = ({ name, options, field, label }) => {
    
        const selectOptions = options.map(option => {
            return (
                <option value={option.id}>{option.name}</option>
            )
        });
        
        return(
            <div className="select-input">
                <label>{label}</label>
                <select className={`w-full bg-white focus:outline-none py-2 px-4 border border-gray-300 rounded mt-2`} name={name} {...field}>
                    <option value="">Please Select</option>
                    {selectOptions}
                </select>
            </div>
        )
    }

    render() {

        const { props } = this.props;

        const selectData = [
            { id: 1, name: 'Regular'},
            { id: 2, name: 'Contractual'}, 
            { id: 3, name: 'Contingency'}
        ];

        return (
            <div className="section-three px-8 py-4 border-b-gray-300">

                <div className="mb-4">
                    <div>
                        <label>Are you employed?</label>
                        <div className="grid grid-cols-3 gap-4 mt-2">
                            <Field component={this.myRadioInput} name="employment.employed" text="Yes" value="Y" selected={props.values.employment.employed}></Field>
                            <Field component={this.myRadioInput} name="employment.employed" text="No" value="N" selected={props.values.employment.employed}></Field>
                        </div>
                        {getIn(props.errors, 'employment.employed') && getIn(props.touched, 'employment.employed') && <ErrorMsg msg={getIn(props.errors, 'employment.employed')} />}
                    </div>
                </div>

                {
                    props.values.employment.employed === 'Y' ? 
                    (
                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                            <div className="form-input">
                                <Field component={this.myTextInput} name="employment.employer" placeholder="Employer" type="text" readonly={false}></Field>
                                {getIn(props.errors, 'employment.employer') && getIn(props.touched, 'employment.employer') && <ErrorMsg msg={getIn(props.errors, 'employment.employer')} />}
                            </div>
                            <div className="form-input">
                                <Field component={this.myTextInput} name="employment.designation" placeholder="Designation" type="text" readonly={false}></Field>
                                {getIn(props.errors, 'employment.designation') && getIn(props.touched, 'employment.designation') && <ErrorMsg msg={getIn(props.errors, 'employment.designation')} />}
                            </div>

                            <div className="form-input">
                                <Field component={this.mySelectInput} name="employment.status" label="Status of Employment" options={selectData}></Field>
                                {getIn(props.errors, 'employment.status') && getIn(props.touched, 'employment.status') && <ErrorMsg msg={getIn(props.errors, 'employment.status')} />}
                            </div>

                            <div>
                                <label>NOC Obtained</label>
                                <div className="grid grid-cols-3 gap-4 mt-2">
                                    <Field component={this.myRadioInput} name="employment.noc" text="Yes" value="Y" selected={props.values.employment.noc}></Field>
                                    <Field component={this.myRadioInput} name="employment.noc" text="No" value="N" selected={props.values.employment.noc}></Field>
                                </div>
                                {getIn(props.errors, 'employment.noc') && getIn(props.touched, 'employment.noc') && <ErrorMsg msg={getIn(props.errors, 'employment.noc')} />}
                            </div>

                            {
                                props.values.employment.noc === 'Y' ? 
                                (
                             
                                    <React.Fragment>
                                        <div className="form-input">
                                            <Field component={this.myTextInput} name="employment.noc_certificate" placeholder="Certificate No" type="text" readonly={false}></Field>
                                            {getIn(props.errors, 'employment.noc_certificate') && getIn(props.touched, 'employment.noc_certificate') && <ErrorMsg msg={getIn(props.errors, 'employment.noc_certificate')} />}
                                        </div>
                                        <div className="form-input">
                                            <div className="w-full">
                                                <label>Date Of Issue</label>
                                                <div className="text-input mt-2">
                                                    <DatePicker
                                                        selected={props.values.employment.noc_date_of_issue}
                                                        onChange={date => props.setFieldValue("employment.noc_date_of_issue", date)}
                                                        peekNextMonth
                                                        showMonthDropdown
                                                        showYearDropdown
                                                        dropdownMode="select"
                                                        dateFormat="dd-MM-yyyy"
                                                        className="appearance-none block w-full py-2 px-3 border border-gray-300 rounded"
                                                        placeholderText="Click to select a date"
                                                    />
                                                    {getIn(props.errors, 'employment.noc_date_of_issue') && getIn(props.touched, 'employment.noc_date_of_issue') && <ErrorMsg msg={getIn(props.errors, 'employment.noc_date_of_issue')} />}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-input">
                                            <Field component={this.myTextInput} name="employment.noc_authority" placeholder="Issuing Authority" type="text" readonly={false}></Field>
                                            {getIn(props.errors, 'employment.noc_authority') && getIn(props.touched, 'employment.noc_authority') && <ErrorMsg msg={getIn(props.errors, 'employment.noc_authority')} />}
                                        </div>
                                    </React.Fragment>
                                    
                                ): null
                            }
                            
                        </div>
                    ) : null
                }
                
            </div>
        )
    }
}

export default Employment;